/**
 * Created by alexanderkulyk on 3/16/19.
 */

import { createSelector } from 'reselect';

const userStateSelector = state => state.user;

export const userSelector = createSelector(
    userStateSelector,
    user => user
);
