/**
 * Created by alexanderkulyk on 3/26/19.
 */
import { createAction } from 'redux-actions';

export const SET_QUESTION_POPUP = 'question-popup:set';

export const SHOW_QUESTION_POPUP = 'question-popup:show';
export const HIDE_QUESTION_POPUP = 'question-popup:hide';
export const HIDE_QUESTION_POPUP_REDUCER = 'question-popup:hide:reducer';

export const showQuestionPopup = createAction(
    SHOW_QUESTION_POPUP, (message, callbacks) => ({ message, callbacks })
);

export const hideQuestionPopup = createAction(HIDE_QUESTION_POPUP, answer => answer);

export const setQuestionPopup = createAction(
    SET_QUESTION_POPUP, (status, message, callbacks) => ({ status, message, callbacks })
);