import React,{ Component } from "react";
//import queryString from 'query-string';
import "./Terms.css";

export default class About extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-container">
                <div className="grey-block">
                    <h1 className="page-title title-with-bottom-padding">About Us</h1>
                    FlashAlert is a unique, disruptive service making financial news
                    available at very low latency. Please visit our <a href='/products'>Products page</a> to
                    learn more about our services.
                </div>
            </div>
        );
    }
}