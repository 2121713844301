/**
 * Created by alexanderkulyk on 3/29/19.
 */
import React, { Component } from "react";
import { FormGroup, ControlLabel, HelpBlock, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Input.css";

export default class Input extends Component {

    renderFormControl() {
        const {
            name="",
            value="",
            type="text",
            placeholder="",
            onChange=()=>{},
            componentClass="input",
            rows,
            cols,
            bsSize,
            autoFocus=false,
            maxLength,
            valid,
            onBlur=()=>{},
            disabled,
            inputRef=node => node
        } = this.props;
        return (
            <FormControl type={type}  value={value} valid={valid} placeholder={placeholder}
                         name={name} onChange={onChange} componentClass={componentClass} onBlur={onBlur}
                         rows={rows} cols={cols} bsSize={bsSize} autoFocus={autoFocus} maxLength={maxLength}
                         disabled={disabled} ref={inputRef}/>
        )
    }

    render() {
        const {
            name="",
            label="",
            validationState=null,
            helpText,
            tooltip,
            className=''
        } = this.props;

        return (
            <FormGroup className={`form-fa-input ${className}`} controlId={name} validationState={validationState}>
                <ControlLabel>{label}</ControlLabel>
                {
                    tooltip ?
                        <OverlayTrigger placement="top" overlay={
                            <Tooltip placement="top" className="in" id="tooltip-top">
                                {tooltip}
                            </Tooltip>
                        }>
                            {this.renderFormControl()}
                        </OverlayTrigger>
                        : this.renderFormControl()
                }

                <HelpBlock>{helpText}</HelpBlock>
            </FormGroup>
        );
    }
}