import React,{ Component } from "react";
//import queryString from 'query-string';
import "./Terms.css";

export default class Privacy extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-container">
                <div className="grey-block">
                    <h1 className="page-title title-with-bottom-padding">Privacy Policy (Effective 01/01/2018)</h1>
                    You signify your consent to our privacy policy by using FlashAlert website, FlashAlert applications and FlashAlert services ("FlashAlert"). We do not sell or market your personal information or personalized data. If you provide us with your e-mail address, or have done so in the past, we may upon occasion send you e-mails, including updates about functionality offered by FlashAlert.me. FlashAlert.me may share aggregated non-personal information with 3-rd parties. This information does not identify you individually. In case if FlashAlert.me goes through a business transaction, such as a merger, sale of assets, or an acquisition by another company, FlashAlert.me will likely share its website users’ information, including your personally identifiable information as a part of the assets transferred as a part of the merger, acquisition or a sale deal. FlashAlert.me logs user traffic on its website. Should you have any comments please send an e-mail to info at FlashAlert.me
                    <h1 className="page-title title-with-padding">Cookie Policy (Effective 01/01/2018)</h1>
                    FlashAlert.me uses cookies. By using FlashAlert.me, you consent to our use of cookies in accordance with the terms of this policy. How we use cookies A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website. We use several cookies on FlashAlert.me In addition, we might also use Google Analytics and AddThis cookies on our website. Cookies do not contain any information that personally identifies you. Removing / deleting cookies might affect usability of FlashAlert.me.

                </div>
            </div>
        );
    }
}