/**
 * Created by alexanderkulyk on 3/26/19.
 */

import initialState from './state';

import {
    SET_PAYMENT_POPUP,
    SHOW_PAYMENT_POPUP,
    HIDE_PAYMENT_POPUP
} from './actions';

import {
    setPaymentPopup,
    showPaymentPopup,
    hidePaymentPopup
} from './reducers';


export default function reducer(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case SET_PAYMENT_POPUP:
            return setPaymentPopup(state, payload);
        case SHOW_PAYMENT_POPUP:
            return showPaymentPopup(state, payload);
        case HIDE_PAYMENT_POPUP:
            return hidePaymentPopup(state);
        default:
            return state;
    }
}