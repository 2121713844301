import React,{ Component } from "react";
import queryString from 'query-string';
import "./News.css";

export default class News extends Component {

    constructor(props) {
        super(props);

        this.state = {
            urlRemote: ""
        };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this.setState({urlRemote : values.url});
    }


    render() {
        return (
            <div className="Home">
                <div className="landerFA">
                    <h1>Link to the Source Website</h1>
                    <a target="_blank" rel="noopener noreferrer" href={this.state.urlRemote}>{this.state.urlRemote}</a>

                </div>
            </div>
        );
    }
}