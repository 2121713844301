/**
 * Created by alexanderkulyk on 4/11/19.
 */
import Input from './containers/Form/Input';
import React, { Component } from "react";
import LoaderButton from "./components/LoaderButton";
import { ENV } from './config';

import { getCookie, setCookie } from './utils/common';

const credentials = {
    admin: 'Flashalert1234',
    dev: 'alerts123'
};

const isSecureForm = () => {
    return false;
    if (ENV === 'PROD' || window.location.hostname === 'localhost') return false;

    const login = getCookie('login');
    const password = getCookie('password');
    const isValid = login && password && credentials[login] && credentials[login] === password;
    return !isValid && <SecureForm />;
};

class SecureForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: ''
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        const { login, password } = this.state;
        if (credentials[login] && credentials[login] === password) {
            setCookie('login', login, 1);
            setCookie('password', password, 1);
            document.location.reload(true);
        } else {
            alert('Access denied!');
        }
    };

    render() {
        const { login, password } = this.state;
        return (
            <div className="page-container">
                <div className="page-grey-block">
                    <h1 className="page-title page-title-by-center">Secure Access</h1>
                    <form onSubmit={this.handleSubmit} className="page-form">
                        <Input
                            name="login"
                            type="text"
                            label="Login"
                            value={login}
                            onChange={({ target }) => { this.setState({ login: target.value }) }}
                            bsSize="large"
                            autoFocus={true}
                        />
                        <Input
                            name="password"
                            type="password"
                            label="Password"
                            value={password}
                            onChange={({ target }) => { this.setState({ password: target.value }) }}
                            bsSize="large"
                        />
                        <div className="alerts-button-container page-form-button-container">
                            <LoaderButton
                                block
                                bsSize="large"
                                type="submit"
                                text="Confirm"
                            />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default isSecureForm;