/**
 * Created by alexanderkulyk on 4/22/19.
 */
import React, { Component } from "react";
import LoaderButton from "../../components/LoaderButton";

export default class AlexaRedirectButton extends Component {
    handleSubmit = e => {
        e.preventDefault();
        const { cognitoAuthURL } = this.props;
        window.location.href = cognitoAuthURL;
    };

    render() {
        const { cognitoAuthURL, style={} } = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <LoaderButton
                    style={style}
                    block
                    bsSize="large"
                    disabled={false}
                    type="submit"
                    text="Sign In To Alexa"
                    className="sign-form-fa-submit"
                />
            </form>
        )
    }
}