/**
 * Created by alexanderkulyk on 3/26/19.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideQuestionPopup } from '../../store/actions';
import { questionPopupSelector } from '../../selectors/questionPopup';
import QuestionPopup from './QuestionPopup';

class QuestionPopupContainer extends Component {
    render() {
        return (
            <QuestionPopup { ...this.props } />
        )
    }
}

const mapDispatchToProps = {
    hideQuestionPopup
};

const mapStateToProps = state => ({ questionPopup: questionPopupSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPopupContainer);