/**
 * Created by alexanderkulyk on 3/26/19.
 */
import { ENV, DEV_FEATURES, PROD_FEATURES } from '../config';

export const debouncedAction = (type, debounceKey, func = () => ({})) => (...payload) => {
    return ({
        meta: { debounce: debounceKey, cancel: true },
        payload: func(...payload),
        type,
    });
};

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = cname => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const eraseCookie = name => {   
    document.cookie = name + '=; Max-Age=-99999999;';  
}

export const isFeatureActive = feature => {
    const currentEnv = ENV === 'PROD' ? PROD_FEATURES : DEV_FEATURES;
    return currentEnv[feature];
};