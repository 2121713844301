/**
 * Created by alexanderkulyk on 4/30/19.
 */
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';
import MaterialUICheckbox from '@material/react-checkbox';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Toggle from 'react-toggle'

import './FlashAlertStreaming.css';

export const isASCII = str => /^[\x00-\x7F]*$/.test(str);

const Checkbox = props => {
    return (
        <div className="checkbox-container">
            <MaterialUICheckbox { ...props }/>
            <label className="checkbox-label">{props.children}</label>
        </div>
    )
};

const MultiValueLabel = props => {
    return (
        <components.MultiValueLabel {...props}>
            {props.data.value}
        </components.MultiValueLabel>
    );
};

export default class FlashAlertStreaming extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            checkboxes: {
                SEC: true,
                SA: true,
                PR: true,
                FL: true,
                BZ: true,
                CR: true,
                RTG: true
            },
            stocks: [],
            isClosed: true,
            autoScroll: true,
            autoScrollToggle: true
        };

        this._timeout = null;

        const { auth_token, username } = props;
        this.socket = new WebSocket(
            `wss://r6xsbchce1.execute-api.us-east-1.amazonaws.com/v1?auth_token=${auth_token}&username=${username}`
        );
        this.socket.onclose = event => {
            if (event.wasClean) {
                console.log('socket connection closed');
            } else {
                console.log('socket connection was killed')
            }
            console.log('code: ' + event.code + ' reason: ' + event.reason);
        };

        this.socket.onmessage = async event => {
            const message = JSON.parse(event.data);
            const { messages } = this.state;
            const symbol = message.symbol;
            if (message.source === "SEC") {
                if (message.type === '4') {
                    message.summary = message.summary.split(' on ')[0];
                    const preparedMessage = message.summary.split('form 4:');
                    message.summary = preparedMessage[0] + 'form 4 on ' + message.published + ':' + preparedMessage[1];
                }
                if (message.type === '3') {
                    message.summary = message.summary + ' on ' + message.published;
                }
            }

            message.summary = message.summary.replace(symbol, '');
            if (message.source === 'SA') {
                message.summary = message.summary.replace(' ', ': ');
            }
            message.shortUrl = message.short_urls && message.short_urls['https://flashalert.me/'] || message.url;
            messages.push(message);
            if (messages.length > 100) {
                console.log('message limit!');
                this.socket.close();
            }

            this.setState({
                messages
            });
        };

        this.socket.onerror = function(error) {
            console.log("Error => " + error.message);
        };
    }

    componentWillUnmount() {
        this.socket.close();
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        const { autoScroll, autoScrollToggle, messages } = this.state;
        if (autoScroll && autoScrollToggle && messages.length) {
            this.content.scrollTop = this.content.scrollHeight;
        }

    }

    handleChecked = e => {
        const checkboxes = this.state.checkboxes;
        checkboxes[e.target.name] = e.target.checked;
        this.setState({
            checkboxes
        });
    };

    handleToogleFreezer = e => {
        this.setState({
            autoScrollToggle: e.target.checked
        });
    }

    handleChange = value => {
        if (value.length > 10) {
            alert('You can only select 10 stocks');
        } else {
            this.setState({
                stocks: value
            });
        }
    };

    handleMessagesFiltering = message => {
        const { checkboxes, stocks } = this.state;
        const sources = Object.keys(checkboxes).filter(key => checkboxes[key]);
        const symbols = stocks.filter(
            symbol => symbol.value === message.symbol && sources.includes(message.source)
        ).map(symbol => symbol.value);

        if (
            (stocks.length && symbols.length)
            || (!stocks.length && sources.includes(message.source))
        ) return true;
        return false;
    };

    handleMouseEnter = e => {
        const { messages } = this.state;
        if (messages.length) {
            this.setState({
                autoScroll: false
            });
        }

    };

    handleMouseLeave = e => {
        const { messages } = this.state;
        if (messages.length) {
            this.setState({
                autoScroll: true
            });
        }
    };

    fetchSymbolsByTicker = value => {
        if (isASCII(value)) {
            const baseUrl = 'https://ud13av7oi1.execute-api.us-east-1.amazonaws.com';
            return axios.get(`${baseUrl}/v1/fetch-symbols?search=${value}&type`).then(res => res.data);
        } else {
            alert('Please use only ASCII characters');
        }
    };

    handleChangeSymbolType = e => {
        e.preventDefault();
        const value = e.target.value;
        let additionalState = {};
        this.setState({
            symbolType: value,
            ...additionalState
        });
    };

    render() {
        const { messages, checkboxes, stocks, isClosed, autoScrollToggle } = this.state;
        const { SA, SEC, BZ, FL, PR, RTG, CR } = checkboxes;
        return(
          <div className="streaming-container">
            <div className="streaming-header">
                <div className="alerts-grey-block-bottom-container">
                    <div className="streaming-top-head-container">
                        <div className="auto-scroll-toggle-block">
                            <div className="sms-info">
                                {autoScrollToggle && <h3 className="red-text">Freeze Scrolling</h3>}
                                {!autoScrollToggle && <h3 className="green-text">Resume Scrolling</h3>}
                            </div>
                            <Toggle
                                checked={autoScrollToggle}
                                onChange={this.handleToogleFreezer}
                                name="autoScrollToggle"
                            />
                        </div>
                        <AsyncSelect
                            id="tickerSelect"
                            closeMenuOnSelect={isClosed}
                            components={{MultiValueLabel}}
                            onChange={this.handleChange}
                            isMulti
                            loadOptions={this.fetchSymbolsByTicker}
                            placeholder="Start typing stock, ETF or crypto symbols... i.e AMZN, SPY, BTC (10 max)"
                            value={stocks}
                            styles={{
                                control: styles => ({
                                    ...styles,
                                    height: '100%',
                                    minHeight: 55,
                                    boxShadow: '0 0 4px 0 rgba(0,0,0,0.13)'
                                }),
                                dropdownIndicator: styles => ({
                                    display: 'none'
                                }),
                                indicatorSeparator: styles => ({
                                    display: 'none'
                                }),
                                clearIndicator: styles => ({
                                    ...styles,
                                    padding: 16,
                                }),
                                multiValue: styles => ({
                                    ...styles,
                                    backgroundColor: '#FF8A00',
                                    color: '#fff'
                                }),
                                multiValueLabel: styles => ({
                                    ...styles,
                                    color: '#fff',
                                    minWidth: 25,
                                    textAlign: 'center',
                                    paddingRight: 5
                                }),
                                multiValueRemove: styles => ({
                                    ...styles,
                                    borderLeft: '0.5px solid rgba(0,0,0,0.11)'
                                }),
                                input: styles => ({
                                    ...styles,
                                    height: window.innerWidth > 500 ? 'auto' : 80,
                                    
                                })
                            }}
                        />
                    </div>

                </div>
            </div>
              <div className="streaming-body">
                  <div className="streaming-sidebar">
                      <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="SEC"
                              className="select-all"
                              checked={SEC}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>SEC filings</h3>
                      </div>
                      <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="PR"
                              className="select-all"
                              checked={PR}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>Press Releases</h3>
                      </div>
                      <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="SA"
                              className="select-all"
                              checked={SA}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>SeekingAlpha</h3>
                      </div>
                      {/* Deprecated */}
                      {/* <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="FL"
                              className="select-all"
                              checked={FL}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>The Fly</h3>
                      </div> */}
                      <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="BZ"
                              className="select-all"
                              checked={BZ}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>Benzinga</h3>
                      </div>
                      <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="Upgrades"
                              className="select-all"
                              checked={RTG}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>Ratings</h3>
                      </div>
                      <div className="alerts-block-header-checkbox">
                          <Checkbox
                              name="CR"
                              className="select-all"
                              checked={CR}
                              onChange={this.handleChecked}
                              inline="true"
                          />
                          <h3>Crypto</h3>
                      </div>
                  </div>
                  <div className="streaming-content" ref={el => { this.content = el; }} onMouseEnter={this.handleMouseEnter}
                       onMouseLeave={this.handleMouseLeave} >
                          {messages.filter(message => this.handleMessagesFiltering(message)).map(
                              (item, i) => <div key={i} className="streaming-message">
                                  <p><b>{item.symbol}</b>{item.summary}</p><a target="_blank" href={item.shortUrl}>
                                  {item.shortUrl}
                                  </a>
                              </div>
                          )}
                  </div>
              </div>
          </div>
        );
    }
}
