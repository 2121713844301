/**
 * Created by alexanderkulyk on 3/14/19.
 */
import { put, select, call, takeEvery } from 'redux-saga/effects';

import {
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_SUCCESS,
    HANDLE_CHANGE,
    HANDLE_CHECKED,
    HANDLE_UPDATE,
    HANDLE_UPDATE_SUCCESS,
    GET_SYMBOLS_BY_TICKER,
    SET_ALERTS_LOADING,
    HANDLE_CHECKED_REDUCER,
    HANDLE_CHANGE_REDUCER,
    HANDLE_STOP_ALL_SUBSCRIPTIONS_REDUCER,
    HANDLE_STOP_ALL_SUBSCRIPTIONS,
    handleChangeReducer,
    handleCheckedReducer,
    HANDLE_CHANGE_ALERTS_STATE,
    HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT,
    SAVE_EVENT,
    HANDLE_UPDATE_STREAM
} from './actions';

import {
    setUserProp, SET_USER_PROPS, setUserProps
} from '../user/actions';

import {
    SHOW_POPUP
} from '../popup/actions';


import {
    SHOW_QUESTION_POPUP
} from '../questionPopup/actions';

import {
    userSelector
} from '../../../selectors/user';

import {
    alertsSelector
} from '../../../selectors/alerts';

import {
    isASCII, prepareAlerts, scrollTo, prepareServiceStore, plans, premiumFeatures, isAllExclusion
} from '../../../utils/alerts';

import {
    fetchSubscriptions, sendUpdateAlertsRequest, fetchSymbolsByTicker
} from '../../../utils/api';

import { addAuthTokenToRequest } from '../../../utils/api';
import { SMS_LIMIT, SMS_ABSOLUTE_LIMIT, SYMBOLS_LIMIT, SYMBOLS_ABSOLUTE_LIMIT } from '../../../config';

export function* watchGetSubscription() {
    yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptions);
}

export function* watchUpdateSubscriptionStream() {
    yield takeEvery(HANDLE_UPDATE_STREAM, updateSubscriptionStream);
}

export function* watchUpdateSubscriptions() {
    yield takeEvery(HANDLE_UPDATE, updateSubscriptions);
}

export function* watchgetSymbolsByTicker() {
    yield takeEvery(GET_SYMBOLS_BY_TICKER, getSymbolsByTicker)
}

export function* watchHandleChecked(dispatch) {
    yield takeEvery(HANDLE_CHECKED, handleChecked, dispatch);
}

export function* watchHandleChange(dispatch) {
    yield takeEvery(HANDLE_CHANGE, handleChange, dispatch);
}

export function* watchHandleStopAllSubscriptions() {
    yield takeEvery(HANDLE_STOP_ALL_SUBSCRIPTIONS, handleStopAllSubscriptions);
}


function handleUserGoingPay(dispatch, handler, event, reset) {
    if (reset) {
        dispatch(setUserProp('isGoingFree', false));
    } else {
        dispatch(setUserProp('isGoingPay', true));
        dispatch(handler(event));
    }

}

function handleUserGoingFree(dispatch, userGoingPay) {
    if (userGoingPay) {
        dispatch(setUserProp('isGoingPay', false));
    } else {
        dispatch(setUserProp('isGoingFree', true));
    }

}

function handlePremiumFeatures(event, alerts) {
    const premiumInTarget = event && premiumFeatures.includes(event.target.name);

    const space = event && event.target.name.split('_')[3];

    const isAll = premiumInTarget && event.target.name.includes('_all_') && event.target.name.split('_')[2];
    const isOne = premiumInTarget && !isAll && event.target.name.split('_')[1];

    if (premiumInTarget && event.target.checked) return true;

    for (let feature of premiumFeatures) {
        if (alerts[feature]) {
            if (!event) return true;
            if (isAll && !event.target.checked && feature !== event.target.name && !feature.includes(isAll) && !feature.includes(space)) return true;
            if (isOne && !event.target.checked && feature !== event.target.name && !feature.includes(isOne)) return true;
            if (
                !(
                    premiumInTarget && !event.target.checked 
                    && (
                        feature === event.target.name || (
                            isAll && feature.includes(isAll) || isOne && feature.includes('_all_') && feature.includes(isOne)
                        )
                    )
                )
            ) return true;
        }
    }
    return false;
}

function handleSMSLimit(event, alerts) {
        const smsTarget = event && event.target.name.includes('phone');

        const isAll = smsTarget && event.target.name.includes('_all_') && event.target.name.split('_')[3];
        const isOne = smsTarget && !isAll && event.target.name.split('_')[3];

        const preparedName = isAll && event.target.name.split('_').slice(0, -1).join('_');

        const phoneAmount = Object.keys(alerts).filter(
            item => item.includes('phone') && !Object.keys(isAllExclusion).filter(
                exc => item.includes(exc)
            ).length && alerts[item]
        );

        let amount = 1;

        if (isAll && Object.keys(isAllExclusion).includes(preparedName)) {
            amount = isAllExclusion[preparedName];
        }

        const resultAmount = smsTarget ? (
            event.target.checked ? phoneAmount.length + amount : phoneAmount.length - amount
        ) : phoneAmount.length;
        if (resultAmount > SMS_LIMIT) return true
        else return false
}

export function* handleStopAllSubscriptions() {
    const user = yield select(userSelector);
    
    yield put({
        type: SET_USER_PROPS,
        payload: {
            isGoingPay: !user.freePeriod,
            isGoingFree: false,
            plan: {...user.plan, nextPlan: {type: '', additionalSymbols: 0}}
        }
    });
    return yield put({
        type: HANDLE_STOP_ALL_SUBSCRIPTIONS_REDUCER
    });
}

export function* handleChecked(dispatch, action) {
    const event = action.payload;
    const savedEvent = {
        target: {
            name: event.target.name,
            checked: event.target.checked
        }
    };
    const alerts = yield select(alertsSelector);
    const user = yield select(userSelector);

    const isCryptoSymbols = event.target.checked
        && event.target.name.includes('_cr_') && !event.target.name.includes('_sa_') && !alerts.isCryptoSymbols;
    const isTWTRSymbols = event.target.checked
        && event.target.name.includes('_twtr_') && !alerts.isTWTRSymbols;
    const isCommonSymbols = event.target.checked && !event.target.name.includes('item') && !event.target.name.startsWith('all_') && !event.target.name.includes('is_dnd') && !event.target.name.includes('_twtr_') && !(event.target.name.includes('_cr_') && !event.target.name.includes('_sa_')) && (!alerts.stocks || (alerts.stocks && !alerts.stocks.filter(stock => stock.type !== 'tweet' && stock.type !== 'crypto').length));

    if (isCommonSymbols) {
        scrollTo('div.symbols-title-container');
        return yield put({
            type: SHOW_POPUP,
            payload: 'Please select stock symbol first and then select at least one source'
        });
    }
    if (isCryptoSymbols) {
        scrollTo('div.symbols-title-container');
        return yield put({
            type: SHOW_POPUP,
            payload: 'Please select Crypto currency first'
        });
    }

    if (isTWTRSymbols) {
        scrollTo('div.symbols-title-container');
        return yield put({
            type: SHOW_POPUP,
            payload: 'Please select tweet source first'
        });
    }
    const paymentType = user.plan.type;
    const paymentNextType = user.plan.nextPlan.type;

    const isSymbolsPaid = user.freePeriod ? alerts.stocks.length > plans.free.symbolsAmount : alerts.stocks.length;
    const isPremiumFeature = handlePremiumFeatures(event, alerts);
    const isSMSLimit = user.isSMSLimit ? handleSMSLimit(event, alerts) : false;

    const isUserGoingToFree = !isPremiumFeature && !isSMSLimit && !isSymbolsPaid && paymentType != 'free' && user.freePeriod;
    const isUserGoingToPay = paymentType == 'free' && (isPremiumFeature || isSMSLimit || isSymbolsPaid) && !paymentNextType;
    const isUserFreeExpires = !user.freePeriod && (paymentType == 'free' && !paymentNextType);

    if (!isSymbolsPaid && !isPremiumFeature && !isSMSLimit && paymentType == 'free' && paymentNextType) {
        dispatch(setUserProps({
            plan: {...user.plan, nextPlan: {type: '', additionalSymbols: 0}},
            isGoingPay: false, isGoingFree: false
        }));
    }

    if (paymentType === user.plan.type && user.plan.nextPlan.type === 'free' && user.freePeriod) {
        dispatch(setUserProps({
            plan: {...user.plan, nextPlan: {type: '', additionalSymbols: 0}},
            isGoingPay: false, isGoingFree: false
        }));
    }

    if (isUserGoingToFree && !isUserFreeExpires) {
        dispatch(setUserProps({
            plan: {...user.plan, nextPlan: {type: 'free', additionalSymbols: 0}},
            isGoingPay: false, isGoingFree: true
        }));
    } 
    if (isUserGoingToPay || isUserFreeExpires) {
        handleUserGoingPay(dispatch, '', '', true);
        if (isPremiumFeature) {
            return yield put({
                type: SHOW_QUESTION_POPUP,
                payload: {
                    message: '<p>This choice requires $9.99 monthly subscription:</p>' +
                    '<li>you’ve selected premium feature.</li>' +
                    '<p>Paid subscription will allow you up to 30 symbols and unlimited SMS.</p>',
                    callbacks: {
                        'Subscribe': () => {
                            handleUserGoingPay(dispatch, handleCheckedReducer, savedEvent);
                            dispatch(setUserProps({
                                plan: {...user.plan, nextPlan: {type: 'basic', additionalSymbols: 0}},
                                isGoingPay: true, isGoingFree: false
                            }));
                        },
                        'Cancel': () => {}
                    }
                }
            });
        } else if (isSMSLimit) {
            return yield put({
                type: SHOW_QUESTION_POPUP,
                payload: {
                    message: '<p>This choice requires $9.99 monthly subscription:</p>' +
                    '<li>you’ve selected more than one SMS.</li>' +
                    '<p>Paid subscription will allow you up to 30 symbols and unlimited SMS.</p>',
                    callbacks: {
                        'Subscribe': () => {
                            handleUserGoingPay(dispatch, handleCheckedReducer, savedEvent);
                            dispatch(setUserProps({
                                plan: {...user.plan, nextPlan: {type: 'basic', additionalSymbols: 0}},
                                isGoingPay: true, isGoingFree: false
                            }));
                        },
                        'Cancel': () => {}
                    }
                }
            });
        } else if (isUserFreeExpires) {
            return yield put({
                type: SHOW_QUESTION_POPUP,
                payload: {
                    message: '<p>This choice requires $9.99 monthly subscription:</p>' +
                    '<li>Your free subscription is expired.</li>' +
                    '<p>Paid subscription will allow you up to 30 symbols.</p>',
                    callbacks: {
                        'Subscribe': () => {
                            handleUserGoingPay(dispatch, handleCheckedReducer, savedEvent);
                            dispatch(setUserProps({
                                plan: {...user.plan, nextPlan: {type: 'basic', additionalSymbols: 0}},
                                isGoingPay: true, isGoingFree: false
                            }));
                        },
                        'Cancel': () => {}
                    }
                }
            });
        }
    }

    yield put({
        type: HANDLE_CHECKED_REDUCER,
        payload: action.payload
    })
}

export function* handleChange(dispatch, action) {
    const user = yield select(userSelector);
    let alerts = yield select(alertsSelector);
    const symbols = action.payload;
    if (symbols && symbols.length > SYMBOLS_ABSOLUTE_LIMIT) {
        return yield put ({
            type: SHOW_POPUP,
            payload: 'Maximum 300 symbols allowed'
        });
    }
    const isTWTRSymbols = symbols && symbols.length && symbols.filter(symbol => symbol.value.includes('@')).length;
    const isCryptoSymbols = symbols && symbols.length && symbols.filter(symbol => symbol.type === 'crypto').length;
    const isStockSymbols = symbols && symbols.filter(symbol => ['stock', 'otc', 'etf'].includes(symbol.type)).length;
    if (!isStockSymbols) {
        const allStockSymbolsKeys = Object.keys(alerts).filter(alert => (!alert.includes('_cr_') || alert.includes('_sa_') && !alert.includes('_twtr_')) && alerts[alert] && alert.includes('is_'));
        const allStockSymbols = allStockSymbolsKeys.reduce((acc, alert) => {
            acc[alert] = false;
            return acc;
        }, {});

        yield put({
            type: HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT,
            payload: allStockSymbols
        });
        alerts = {...alerts, ...allStockSymbols};
    }

    if (isTWTRSymbols && !alerts.isTWTRSymbols) {
        yield put({
            type: HANDLE_CHANGE_ALERTS_STATE,
            payload: {
                name: 'isTWTRSymbols',
                value: true
            }
        })
    } else if (!isTWTRSymbols && alerts.isTWTRSymbols) {
        const allTWTRSymbolsKeys = Object.keys(alerts).filter(alert => alert.includes('_twtr_') && alerts[alert]);
        const allTWTRSymbols = allTWTRSymbolsKeys.reduce((acc, alert) => {
            acc[alert] = false;
            return acc;
        }, {});

        allTWTRSymbols['isTWTRSymbols'] = false;

        yield put({
            type: HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT,
            payload: allTWTRSymbols
        });
        alerts = {...alerts, ...allTWTRSymbols};
    }

    if (isCryptoSymbols && !alerts.isCryptoSymbols) {
        yield put({
            type: HANDLE_CHANGE_ALERTS_STATE,
            payload: {
                name: 'isCryptoSymbols',
                value: true
            }
        });
    } else if (!isCryptoSymbols && alerts.isCryptoSymbols) {
        const allCryptoSymbolsKeys = Object.keys(alerts).filter(alert => alert.includes('_cr_') &&
            !alert.includes('_sa_') && alerts[alert]);
        const allCryptoSymbols = allCryptoSymbolsKeys.reduce((acc, alert) => {
            acc[alert] = false;
            return acc;
        }, {});

        allCryptoSymbols['isCryptoSymbols'] = false;

        yield put({
            type: HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT,
            payload: allCryptoSymbols
        });
        alerts = {...alerts, ...allCryptoSymbols};
    }

    const isSMSPaid = user.isSMSLimit ? handleSMSLimit(null, alerts) : false;
    const isPremiumFeature = handlePremiumFeatures(null, alerts);

    const paymentType = user.plan.type;
    const userPaymentPlanObject = plans[paymentType];
    const paymentNextType = user.plan.nextPlan.type;

    let costText = '';
    let symbolsCurrentLimitText = '';
    let symbolsNextLimitText = '';

    let currentType = paymentNextType || paymentType;
    let currentPlanObject = paymentNextType ? plans[paymentNextType] : userPaymentPlanObject;
    let plusSymbols = 0;
    let updated = 0;

    const currentPlanSymbolsLimit = currentPlanObject.symbolsAmount + user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols;

    const prevPlanType = currentPlanObject.prev;

    const isSymbolsLimitUp = user.isSymbolLimit && symbols.length > currentPlanSymbolsLimit;
    let isSymbolsLimitDown = prevPlanType ? user.isSymbolLimit && symbols.length <= plans[paymentNextType && paymentNextType != currentType ? currentType : prevPlanType].symbolsAmount : false;
    if (isSymbolsLimitDown && prevPlanType == 'free' && isPremiumFeature) {
        isSymbolsLimitDown = false;
    }
    const isSymbolsLimitDownByAdditionalSymbols = currentType === 'advanced' && (user.plan.additionalSymbols || user.plan.nextPlan.additionalSymbols) && (currentPlanSymbolsLimit - 50 >= symbols.length) && (user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols);

    if (isSymbolsLimitUp && (user.plan.nextPlan.additionalSymbols >= 0)) {
        if (paymentNextType) {
            const nextPaymentPlan = plans[paymentNextType].next;
            if (nextPaymentPlan) {
                if (nextPaymentPlan === 'additionalSymbols') {
                    plusSymbols += plans[nextPaymentPlan].amount;
                    updated += 1
                } else {
                    currentType = nextPaymentPlan;
                    updated += 1
                }
            } else {
                currentType = nextPaymentPlan;
                updated += 1
            }
        } else {
            const nextPaymentPlan = plans[currentType].next;
            if (nextPaymentPlan === 'additionalSymbols') {
                plusSymbols += plans[nextPaymentPlan].amount
            } else {
                currentType = nextPaymentPlan;
            }
            updated += 1
        }
    
    } else if (isSymbolsLimitDown) {
        currentType = prevPlanType
        updated -= 1;
    } else if (isSymbolsLimitDownByAdditionalSymbols) {
        plusSymbols -= 50;
        updated -= 1;
    } 

    if (updated) {
        const totalCost = (plans[currentType].cost + ((user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols + plusSymbols) / plans['additionalSymbols']['amount']) * plans['additionalSymbols']['cost']) / 100;
        const totalCurrentSymbolAmount = plusSymbols ? plans[currentType].symbolsAmount + user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols : plans[plans[currentType].prev] ? plans[plans[currentType].prev].symbolsAmount : null;
        const totalNextSymbolAmount = plans[currentType].symbolsAmount + user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols + plusSymbols;
        
        costText = `${totalCost}`;
        symbolsCurrentLimitText = `${totalCurrentSymbolAmount}`;
        symbolsNextLimitText = `${totalNextSymbolAmount}`;
    }
    const isUserFreeExpires = !user.freePeriod && (paymentType == 'free' && !paymentNextType);
    const userGoingFree = !isPremiumFeature && currentType === 'free' && paymentType !== 'basic';

    const addTextFirst = "<p>For 100 symbols you will be charged $29.99 a month.</p>";
    const addTextSecond = "<p>For additional 50 symbols above 100, you will be charged extra $10 a month.</p>";
    const addText = paymentType === 'free' ? addTextFirst + addTextSecond : addTextSecond;
    
    if (currentType == 'free' && user.plan.type != 'free' && !user.freePeriod) {
        currentType = user.plan.type;
    } else if (currentType === 'free' && !user.freePeriod) {
        currentType = 'basic'
    }

    if (userGoingFree && user.freePeriod) {
        handleUserGoingFree(dispatch, user.isGoingPay);
    }

    if (isUserFreeExpires) {
        return yield put({
            type: SHOW_QUESTION_POPUP,
            payload: {
                message: '<p>This choice requires $9.99 monthly subscription:</p>' +
                '<li>Your free subscription is expired.</li>' +
                '<p>Paid subscription will allow you up to 30 symbols and unlimited SMS.</p>',
                callbacks: {
                    'Subscribe': () => {
                        handleUserGoingPay(dispatch, handleChangeReducer, symbols);
                        dispatch(setUserProps({plan: {...user.plan, nextPlan: {type: currentType, additionalSymbols: user.plan.nextPlan.additionalSymbols + plusSymbols}}}));
                    },
                    'Cancel': () => {}
                }
            }
        });
    } else if (updated > 0) {
        if (user.plan.type === currentType && !plusSymbols) {
            dispatch(setUserProps({isGoingPay: false, isGoingFree: false, plan: {...user.plan, nextPlan: {type: '', additionalSymbols: 0}}}));
        } else {
            return yield put({
                type: SHOW_QUESTION_POPUP,
                payload: {
                    message: `<p>This choice requires $${costText} monthly subscription:</p>` +
                    `<li>you’ve selected more than ${symbolsCurrentLimitText} symbols.</li>` +
                    `<p>Paid subscription will allow you up to ${symbolsNextLimitText} symbols and unlimited SMS.</p>` + addText,
                    callbacks: {
                        'Subscribe': () => {
                            handleUserGoingPay(dispatch, handleChangeReducer, symbols);
                            dispatch(setUserProps({plan: {...user.plan, nextPlan: {type: currentType, additionalSymbols: user.plan.nextPlan.additionalSymbols + plusSymbols}}}));
                        },
                        'Cancel': () => {}
                    }
                }
            });
        }
    } else if (updated < 0) {
        if (user.plan.type === currentType && !plusSymbols) {
            dispatch(setUserProps({isGoingPay: false, isGoingFree: false, plan: {...user.plan, nextPlan: {type: '', additionalSymbols: 0}}}));
        } else {
            const addObj = {};
            if (user.plan.type !== 'free' && currentType === 'free' && !isPremiumFeature && !isSMSPaid && user.freePeriod) {
                addObj.isGoingFree = true;
            }
            if (user.plan.type !== 'free' && currentType !== 'free' && plans[user.plan.type].order >= plans[currentType].order) {
                addObj.isGoingPay = false;
            }

            dispatch(setUserProps({...addObj, plan: {...user.plan, nextPlan: {type: currentType, additionalSymbols: user.plan.nextPlan.additionalSymbols + plusSymbols}}}));
        }
    }

    yield put({
        type: HANDLE_CHANGE_REDUCER,
        payload: action.payload
    })
}

export function* updateSubscriptionStream(action) {
    const { payload } = action;
    try {
        const { streams } = yield select(alertsSelector);
        const user = yield select(userSelector);
        const preparedServiceStore = yield call(prepareServiceStore, payload, user, streams);
        yield call(sendUpdateAlertsRequest, preparedServiceStore);
        yield put ({
            type: SHOW_POPUP,
            payload: 'Success!'
        });
        yield put({
            type: SET_ALERTS_LOADING,
            payload: false
        })

    } catch(e) {
        console.log('updateSubscriptionStream error => ', e)
        yield put({
            type: SHOW_POPUP,
            payload: 'Subscription error'
        });
    }
}

export function* updateSubscriptions() {
    const alerts = yield select(alertsSelector);
    const user = yield select(userSelector);
    const preparedAlerts = yield call(prepareAlerts, alerts, user);
    try {
        if (preparedAlerts.message) {
            scrollTo('div.symbols-title-container');
            yield put({
                type: SET_ALERTS_LOADING,
                payload: false
            })
            return yield put({
                type: SHOW_POPUP,
                payload: preparedAlerts.message
            });
        }
        yield call(sendUpdateAlertsRequest, preparedAlerts);

        yield put({
            type: HANDLE_UPDATE_SUCCESS,
            payload: preparedAlerts,
        });
        yield put({
            type: GET_SUBSCRIPTIONS
        });
        yield put ({
            type: SHOW_POPUP,
            payload: 'Success!'
        });
        yield put({
            type: SET_ALERTS_LOADING,
            payload: false
        })
    } catch(e) {
        if (e.message === 'Network Error') {
            yield put({
                type: HANDLE_UPDATE_SUCCESS,
                payload: preparedAlerts,
            });
            yield put({
                type: GET_SUBSCRIPTIONS
            });
            yield put ({
                type: SHOW_POPUP,
                payload: 'Success!'
            });
        } else {
            yield put({
                type: SHOW_POPUP,
                payload: 'Subscription error'
            });
        }
       
        yield put({
            type: SET_ALERTS_LOADING,
            payload: false
        });
    }
}

export function* getSymbolsByTicker(action) {
    const { inputValue, callback } = action.payload;
    if (isASCII(inputValue) && !/\d/.test(inputValue)) {
        if (inputValue.length > 8) {
           return yield put({
                type: SHOW_POPUP,
                payload: 'Limit for stock symbol is 8 characters'
            });
        }
        const { symbolType } = yield select(alertsSelector);
        
        const result = yield call(fetchSymbolsByTicker, inputValue, symbolType);
        let data = result.data;
        // deprecated 20.06.2019
        // if (!data.length && symbolType !== 'tweet' && symbolType !== 'crypto') {
        //     data = [
        //         {
        //             value: inputValue.toUpperCase(),
        //             label: `No matching symbol found. Would you like to subscribe to ${inputValue.toUpperCase()} anyway?`
        //         }
        //     ]
        // }
        yield callback(data);
    } else {
        yield put({
            type: SHOW_POPUP,
            payload: 'Please use only ASCII characters and only letters'
        });
    }
}

export function* getSubscriptions() {
    yield call(addAuthTokenToRequest);
    const user = yield select(userSelector);
    const { email } = user;

    try {
        const response = yield call(fetchSubscriptions, email);
        const data = response.data || '';
        const sources = ((data[data.length - 2] || {}).sources || []).filter(src => !src.includes('ITEM')).length;
        const items = (data[data.length - 4] || {}).items || [];
        const isPayedSMS = items.filter(item => item.includes(':PHONE')).length > 1;

        const userData = data ? data[data.length - 3].meta : { isSMSLimit: true, isSymbolLimit: true, paymentStatus: null };
        console.log('test in saga userData', userData)
        const { isSMSLimit, isSymbolLimit, B_STREAM, P_STREAM, basicQueueType, premiumQueueType, freePeriod, premiumFreeTrialDaysLeft, premiumFreeTrialStarted } = userData;
        yield put({
            type: SET_USER_PROPS,
            payload: {
                // isGoingFree: !sources && (!isSMSLimit || !isPayedSMS) && userData.paymentStatus,
                isSMSLimit,
                isSymbolLimit,
                B_STREAM,
                P_STREAM,
                basicQueueType,
                premiumQueueType,
                premiumFreeTrialDaysLeft,
                premiumFreeTrialStarted,
                freePeriod,
                isGoingPay: (!freePeriod && ((userData.subscriptionType || 'free') === 'free')) ? true : false,
                paymentStatus: userData.paymentStatus,
                plan: {
                    type: userData.paymentStatus != 'FAIL' ? (userData.subscriptionType || 'free') : 'free',
                    additionalSymbols: userData.additionalSymbols,
                    nextPlan: {
                        type: '',
                        additionalSymbols: 0
                    }
                },
            }
        });
        yield put({
            type: GET_SUBSCRIPTIONS_SUCCESS,
            payload: data
        });

    } catch (error) {
        console.log('test get subscriptions error => ' + error);
        yield put({
            type: SHOW_POPUP,
            payload: 'Subscription error'
        });
    }
}