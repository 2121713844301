/**
 * Created by alexanderkulyk on 3/26/19.
 */

import React, { Component } from "react";
import "./MessagePopup.css";

export default class Login extends Component {
    onClose = () => {
        const { hidePopupMessage } = this.props;
        hidePopupMessage();
    };

    render() {
        const { status, message } = this.props.popup;
        return(
            <div className={status ? "popup-container popup-active popup-active-priority" : "popup-container"}>
                <div className="popup-close-button" onClick={this.onClose}>x</div>
                <div className="popup-body">
                    <div className="popup-message">{message}</div>
                </div>
            </div>
        )
    }
}