/**
 * Created by alexanderkulyk on 3/26/19.
 */

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./PaymentPopup.css";
import StripeCard from './StripeCard';
import MaterialUICheckbox from '@material-ui/core/Checkbox';
import { stripeAPIKey } from '../../config';
import { plans } from '../../utils/alerts';

const Checkbox = props => {
    return (
        <div className="checkbox-container">
            <MaterialUICheckbox { ...props } classes={{
                root: 'fa-ui-checkbox',
                colorSecondary: 'fa-ui-checkbox-color'
            }} />
            <label className="checkbox-label">{props.children}</label>
        </div>
    )
}

export default class PaymentPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planType: 'payed',
            errorMessage: '',
            confirm: false,
            isLoading: false

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.paymentPopup.status && !this.props.paymentPopup.status) {
            this.setState({
                isLoading: false
            });
        }
    }

    onChecked = e => {
        this.setState({
            [e.target.name]: e.target.checked
        });
    }

    onClick = e => {
        const { hidePaymentPopup, handleStopAllSubscriptions } = this.props;
        handleStopAllSubscriptions();
        hidePaymentPopup();
    };

    handleStateChange = (target, value) => {
        this.setState({
            [target]: value
        })
    };

    handleResult = res => {
        this.setState({
            isLoading: true
        });

        const {
            hidePaymentPopup, showPopupMessage, setUserProps, handleUpdate, handleStripePayment, paymentPopup
        } = this.props;

        if (res.error) {
            this.setState({
                errorMessage: res.error.message
            });
        } else {
            const paymentPlan = this.props.user.plan.nextPlan.type;

            const stripePayload = {
                token: res.token,
                email: this.props.user.email,
                phone: this.props.user.phone_number,
                firstname: this.props.user.given_name,
                lastname: this.props.user.family_name,
                zip: this.props.user.address,
                paymentPlan
            };

            if (paymentPopup.meta) {
                stripePayload['type'] = paymentPopup.meta.type;
            }
            stripePayload.additionalSymbols = this.props.user.plan.additionalSymbols + this.props.user.plan.nextPlan.additionalSymbols;
            handleStripePayment(stripePayload);
        }
    }

    render() {
        const { status, message, cost, meta } = this.props.paymentPopup;
        const { planeType, errorMessage, confirm } = this.state;

        return(
            <div id="payment-popup" className={
                status ? "popup-container big-popup-container popup-active" : "popup-container big-popup-container"
            }>
                <div className="popup-close-button" type="button" name="no" onClick={this.onClick}>x</div>
                <div className="popup-body">
                    <div className="popup-message">{message}</div>
                    <div className="symbols-checkboxes-container">
                    {/*<RadioGroup*/}
                        {/*name="planType"*/}
                        {/*value='payed'*/}
                        {/*onChange={this.handlePlaneChange}*/}
                    {/*>*/}
                        {/*<FormControlLabel*/}
                            {/*value={planeType}*/}
                            {/*control={<Radio classes={{*/}
                                {/*root: 'fa-radio-button',*/}
                            {/*}}*/}
                            {/*/>}*/}
                            {/*label="$9.99 per month after 30 day free trial (10 SMS channels and up to 30 symbols)"*/}
                        {/*/>*/}
                    {/*</RadioGroup>*/}
                    </div>
                    <div className="card-info">
                        <p>{`You will be charged $${cost} a month starting today.
                            You can always cancel your next month subscription from Alerts screen for any reason but
                            unused parts of the current monthly fee won't be refunded.`}</p>
                        <p className="card-confirm" id="confirm-checkbox">I agree with the <a target="_blank" href="/terms">terms</a> by clicking here
                            <Checkbox
                                key='confirm'
                                name='confirm'
                                checked={confirm}
                                onChange={this.onChecked}
                                inline="true"
                            />
                        </p>
                    </div>
                    <StripeCard
                        cost={cost}
                        stripePublicKey={stripeAPIKey}
                        handleResult={this.handleResult}
                        errorMessage={errorMessage}
                        confirm={confirm}
                        user={this.props.user}
                        isLoading={this.state.isLoading}
                        handleStateChange={this.handleStateChange}
                    />
                    <div className="popup-buttons-block">
                        <Button className='answer-no-button' onClick={this.onClick} name="no">Cancel</Button>
                    </div>
                </div>
            </div>
        )
    }
}