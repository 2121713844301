/**
 * Created by alexanderkulyk on 3/15/19.
 */

import { createSelector } from 'reselect';

const alertsStateSelector = state => state.alerts;

export const alertsSelector = createSelector(
    alertsStateSelector,
    alerts => alerts
);
