import React, { Component } from "react";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import "./Footer.css";
import ContactForm from './ContactUs';


export default class Footer extends Component {
    render() {
        return (
            <div className="Footer">

                <ContactForm { ...this.props }/>
                <div className="footer-bottom">
                    <h1>FlashAlert: Low-latency actionable news</h1>
                <Navbar className="footer-bottom-links">
                    <Nav>
                        <NavItem eventKey={1} href="/about">
                            About Us
                        </NavItem>
                        <NavItem eventKey={2} href="/privacy">
                            Privacy
                        </NavItem>
                        <NavItem eventKey={3} href="/terms">
                            Terms
                        </NavItem>
                        <NavItem eventKey={4} href="/accessibility">
                            Accessibility
                        </NavItem>
                    </Nav>
                    <h4>© 2018-{new Date().getFullYear()} FlashAlert. Omnichannel Finance Product</h4>
                </Navbar>

                </div>
            </div>
        );
    }
}