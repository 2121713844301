/**
 * Created by alexanderkulyk on 3/26/19.
 */
import { createAction } from 'redux-actions';

import {
    debouncedAction
} from '../../../utils/common';

export const SET_POPUP_MESSAGE = 'popup:set';

export const SHOW_POPUP = 'popup:show';
export const HIDE_POPUP = 'popup:hide';
export const DEBOUNCED_HIDE_POPUP = 'popup:hide:debounced';

export const showPopupMessage = createAction(
    SHOW_POPUP, message => message
);

export const hidePopupMessage = createAction(HIDE_POPUP);

export const debouncedHidePopupMessage = debouncedAction(
    DEBOUNCED_HIDE_POPUP, 'debouncedHidePopupMessage', message => message
);

export const setPopupMessage = createAction(
    SET_POPUP_MESSAGE, (status, message) => ({ status, message })
);