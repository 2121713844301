/**
 * Created by alexanderkulyk on 3/11/19.
 */

export const pureState = {
    isFetching: true,
    isClosed: true,
    isDelete: false,
    isDisabled: false,
    isEmail: false,
    isLoading: false,
    isSMS: false,
    all_13_email: false,
    all_13_phone: false,
    item_1_03_email: false,
    item_1_03_phone: false,
    item_2_02_email: false,
    item_2_02_phone: false,
    item_2_04_email: false,
    item_2_04_phone: false,
    item_2_06_email: false,
    item_2_06_phone: false,
    item_4_02_email: false,
    item_4_02_phone: false,
    is_all_twtr_email: false,
    is_all_twtr_phone: false,
    is_all_pr_email: false,
    is_all_pr_phone: false,
    is_all_rt_email: false,
    is_all_rt_phone: false,
    is_all_sa_email: false,
    is_all_sa_phone: false,
    is_all_sec_email: false,
    is_all_sec_phone: false,
    isCreateAlert: true,
    is_dnd: false,
    is_pr_bw_email: false,
    is_pr_bw_phone: false,
    is_pr_aw_email: false,
    is_pr_aw_phone: false,
    is_pr_glob_email: false,
    is_pr_glob_phone: false,
    is_pr_nw_email: false,
    is_pr_nw_phone: false,
    is_pr_earn_email: false,
    is_pr_earn_phone: false,
    is_sa_cr_email: false,
    is_sa_cr_phone: false,
    is_sa_ls_email: false,
    is_sa_ls_phone: false,
    is_sa_tr_email: false,
    is_sa_tr_phone: false,
    is_sec_4_email: false,
    is_sec_4_phone: false,
    is_sec_8_email: false,
    is_sec_8_phone: false,
    is_sec_10_email: false,
    is_sec_10_phone: false,
    is_sec_13_email: false, 
    is_sec_13_phone: false,
    is_sec_ot_email: false,
    is_sec_ot_phone: false,
    is_all_bz_email: false,
    is_all_bz_phone: false,
    // is_all_fl_email: false,
    // is_all_fl_phone: false,
    is_all_cr_email: false,
    is_all_cr_phone: false,
    is_cr_cd_email: false,
    is_cr_cd_phone: false,
    is_cr_ct_email: false,
    is_cr_ct_phone: false,
    is_cr_nb_email: false,
    is_cr_nb_phone: false,
    is_cr_bc_email: false,
    is_cr_bc_phone: false,
    symbolType: 'stock',
    stocks: [],
    isCryptoSymbols: false,
    isTWTRSymbols: false,
    savedEvent: {},
    afterSignUpAction: ''
};

export default {
    ...pureState,
    isLoading: false,
    symbols: "",
    id: "",
    isCreateAlert: false,
    stateCopy: {},
    isResetAllSubscriptions: false,
    isDelete: false,
    streams: {}
};