/**
 * Created by alexanderkulyk on 3/11/19.
 */

import { pureState } from './state';
import { sec10, sec8, sec4, handleServices, getServiceScheme, ITEMS_SCHEME, compareByFirstSymbol } from '../../../utils/alerts';
import { pure } from 'recompose';

export const getSubscriptionsSuccess = (state, payload) => {
    const streams = handleServices(payload !== '' ? payload.pop() : {});

    let { sources, attributes } = payload !== '' ? payload.pop(): { sources: [], attributes: [] };
    const data = payload !== '' ? payload.pop() : { meta: { id: 'temp' } };
    let items = payload !== '' ? payload.pop().items : [];

    sources = sources.reduce((acc, source) => {
        let pureSource = source.split(':');
        const type = pureSource.pop();
        const symbol = pureSource.shift();
        const pureNamespace = pureSource.join(':');
        const preparedNamespace = (symbol === 'FULLSYM' ? symbol + ':' : '') + pureNamespace + ':' + type;

        if (sec10.includes(pureNamespace)) {
            if (!acc.includes(preparedNamespace)) {
                acc.push(symbol + ':SEC:10:' + type);
            }
        } else if (sec4.includes(pureNamespace)) {
            if (!acc.includes(preparedNamespace)) {
                acc.push(symbol + ':SEC:4:' + type);
            }
        } else if (sec8.includes(pureNamespace)) {
            if (!acc.includes(preparedNamespace)) {
                acc.push(symbol + ':SEC:8:' + type);
            }
        } else {
            acc.push(source);
        }
        return acc
    }, []);

    const isCryptoSymbols = Boolean(sources.filter(src => src.includes(':CR:')).length);
    const isTWTRSymbols = Boolean(sources.filter(src => src.includes(':TWTR:')).length);

    const sourcesData = {
        '_sec_' : {operator: '===', amount: 5}, '_sa_': {}, '_cr_': {operator: '===', amount: 4},
        '_pr_': {operator: '<=', amount: 4}, // '_fl_': {operator: '===', amount: 1},
        '_bz_': {operator: '===', amount: 1}, '_twtr_': {operator: '===', amount: 1}
    };
    items = items.reduce((acc, item) => {
       const [_, __, _item, _type] = item.toLowerCase().split(':');
        acc[_item + '_' + _type] = true;
        return acc;
    }, {});

    const is_sa = Object.keys(state).filter(key => key.startsWith('is_sa'));
    sources = sources.reduce((acc, next) => {
        if (next.includes('ITEM')) return acc;
        const [symbol, mainSource, source, _type] = next.toLowerCase().split(':');
        if (!mainSource || !source || !_type) return acc;
        if (symbol === 'fullsym') {
            const target = 'all_' + source + '_' + _type;
            acc[target] = true;
        } else if (source === 'ar') {
            const target = 'is_all_' + mainSource + '_' + _type;
            acc[target] = true;
        } else if(source === 'ud') {
            const target = 'is_all_rt_' + _type;
            acc[target] = true;
        } else {
            const target = 'is_' + mainSource + '_' + source + '_' + _type;
            acc[target] = true;
        }

            return acc;
    }, {});

    for (let key in sourcesData) {
        const srcKeys = Object.keys(sources);
        if (key === '_sa_') {
            if (srcKeys.includes('is_sa_all_email')) {
                sources.is_all_sa_email = true;
                for (let saKey of is_sa) {
                    if (saKey.includes('email')) {
                        sources[saKey] = true;
                    }
                }
                delete sources.is_sa_all_email;
            }

            if (srcKeys.includes('is_sa_all_phone')) {
                sources.is_all_sa_phone = true;
                for (let saKey of is_sa) {
                    if (saKey.includes('phone')) {
                        sources[saKey] = true;
                    }
                }
                delete sources.is_sa_all_phone;
            }

        } else {
            const emailKeys = srcKeys.filter(
                item => item.includes(key) && !item.includes('_sa_') && item.includes('email')
            );
            const phoneKeys = srcKeys.filter(
                item => item.includes(key) && !item.includes('_sa_') && item.includes('phone')
            );
            const targets = [];
            let emailCondition, phoneCondition;
            if (sourcesData[key].operator === '===') {
                emailCondition = sourcesData[key].amount === emailKeys.length;
                phoneCondition = sourcesData[key].amount === phoneKeys.length;
            } else if (sourcesData[key].operator === '<=') {
                emailCondition = sourcesData[key].amount <= emailKeys.length;
                phoneCondition = sourcesData[key].amount <= phoneKeys.length;
            }

            for (let emailKey of emailKeys) {
                if (emailKey.includes(key) && emailCondition) {
                    targets.push('is_all' + key  + 'email');
                    break
                }
            }

            for (let phoneKey of phoneKeys) {
                if (phoneKey.includes(key) && phoneCondition) {
                    targets.push('is_all' + key  + 'phone');
                    break
                }
            }

            for (let target of targets) {
                sources[target] = true;
            }
        }
    }

    attributes = attributes.reduce((acc, next) => {
        const items = next.split(':');
        acc[`is_${items[1].toLowerCase()}`] = true;
        return acc;
    }, {});
    const isCreateAlert = Object.keys(sources).length === 0 && sources.constructor === Object && !Object.keys(items).length && !payload.length;

    return {
        ...state,
        ...pureState,
        stocks: payload,
        ...items,
        id: data.meta.id,
        isCreateAlert,
        ...sources,
        ...attributes,
        isCryptoSymbols,
        isTWTRSymbols,
        streams,
        stateCopy: {
            ...state,
            ...pureState,
            stocks: payload,
            ...items,
            id: data.meta.id,
            isCreateAlert,
            ...sources,
            ...attributes,
            isCryptoSymbols,
            isTWTRSymbols,
            streams
        },
    };
};

export const handleCheckedReducer = (state, event) => {
    const excludeAllArr = ['all_13_email', 'all_13_phone'];
    const targetName = event.target.name;
    const isDND = targetName.includes('is_dnd');
    const eventChecked = event.target.checked;
    if (!eventChecked) {
        state.isDelete = true;
    }

    if (isDND) {
        return {
            ...state,
            is_dnd: eventChecked
        }
    }

    const [_is, sourceGroup, originalSource, _type] = targetName.split('_');

    if (sourceGroup === 'all') {
        const source = '_' + originalSource;
        const targets = Object.keys(state).filter(target => target.includes(source) && target.includes(_type));
        const result = targets.reduce((acc, target) => {
            if (originalSource === 'cr' && target.includes('_sa_')) return acc;
            acc[target] = eventChecked;
            return acc;
        }, {});
        return { ...state, ...result }
    } else if (sourceGroup) {
        const targetAllGroup = Object.keys(state).filter(
            target => target.includes(sourceGroup) && target.includes(_type)
        );

        const targetAll = targetAllGroup.filter(
            target => target.includes('all') && !excludeAllArr.includes(target)
        );
        const targetAllGroupChecked = targetAllGroup.filter(
            target => state[target] || target === targetName && eventChecked
            ).length === targetAllGroup.length - 1;
        if (targetAll[0]) {
            return {
                ...state,
                [targetName]: eventChecked,
                [targetAll[0]]: targetAllGroupChecked
            }
        }
        return {
            ...state,
            [targetName]: eventChecked
        }

    }

    return {
        ...state,
        [targetName]: eventChecked
    };
};

export const handleStopAllSubscriptionsReducer = state => {
    return {
        ...state, ...state.stateCopy, stateCopy: {...state.stateCopy}, isResetAllSubscriptions: false, isLoading: false
    };
};

export const handleChangeReducer = (state, value) => {
    if(value.length === 0) {
        return { ...state, isDelete: true, stocks: ""};
    } else {
        return { ...state, stocks: value, isDelete: false };
    }
};

export const handleUpdate = (state, preparedAlerts) => {
    const result = {};

    if (state.isResetAllSubscriptions) {
        result.isResetAllSubscriptions = false;
    }
    const isCreateAlert = !preparedAlerts.namespaces.length && !preparedAlerts.subscriptions.length;

    if (state.isCreateAlert && !isCreateAlert) {
        result.isCreateAlert = false;
    } else if (isCreateAlert) {
        setTimeout(() => {
            result.isCreateAlert = true;
            return {
                ...state, ...result, stateCopy: { ...state, ...result }, isCreateAlert: false
            }
        }, 500);
    }

    return {
        ...state, ...result, isDelete: false, stateCopy: { ...state, ...result }, isCreateAlert: false
    }
};

export const setAlertsLoading = (state, value) => ({ ...state, isLoading: value });

export const clearUserId = state => ({ ...state, ...pureState, id: '' });

export const handleChangeAlertsState = (state, { name, value }) => {
    return {...state, [name]: value};
};

export const handleChangeAlertsStateByObject = (state, payload) => {
    return { ...state, ...payload };
}

export const saveEvent = (state, event) => ({ ...state, savedEvent: event});

export const updateStreamsState = (state, { name, value }) => {
    const arrName = name.split(':');
    const type = arrName.pop();
    const allName = arrName.pop();
    const service_scheme = getServiceScheme(type);
    let result = {};

    if (!value && allName !== 'ALL' && state.streams['ALL:' + type]) {
        result['ALL:' + type] = value;
    }

    if (value && allName !== 'ALL' && !state.streams['ALL:' + type] && service_scheme.every(
            scheme => scheme.name.includes(name) || state.streams[scheme.name] || scheme.included && scheme.included.every(
                includedScheme => includedScheme.name.includes(name) || state.streams[includedScheme.name]
            )
        ) && (allName === 'ITEMS' || Object.values(ITEMS_SCHEME).map(namespace => namespace + ':' + type).every(namespace => namespace === name || state.streams[namespace]))
    ) {
        result['ALL:' + type] = value;
    }

    if (name.includes('ITEM')) {
        const namespaces = Object.values(ITEMS_SCHEME).map(namespace => namespace + ':' + type);
        if (name.includes('ALL')) {
            result = { ...result, ...namespaces.reduce((acc, namespace) => {
                acc[namespace] = value
                return acc;
            }, {})};
            result['ALL:ITEMS:' + type] = value
        } else {
            if (namespaces.filter(namespace => namespace !== name).every(namespace => !value ? !!state.streams[namespace] : state.streams[namespace] === value)) {
                result['ALL:ITEMS:' + type] = value
            }
            result[name] = value
        }
        return ({ ...state, streams: { ...state.streams, ...result }});
    }
    
    if (name.includes('ALL')) {
        const namespace_scheme = service_scheme.filter(namespace => allName === 'ALL' ? true : namespace.name === name);
        for (let namespace of namespace_scheme) {
            if (allName === 'ALL') {
                result[namespace.name] = value;
            }
            if (namespace.included) {
                for (let included_namespace of namespace.included) {
                    if (!included_namespace.disabled) {
                        result[included_namespace.name] = value
                    }
                }
            }
        }
        if (allName === 'ALL') {
            Object.values(ITEMS_SCHEME).map(namespace => namespace + ':' + type).forEach(namespace => result[namespace] = value)
            result['ALL:ITEMS:' + type] = value;
        }

    } else {
        const namespace_scheme = service_scheme.filter(namespace => namespace.included && namespace.included.filter(includedNamespace => includedNamespace.name === name).length);
        for (let namespace of namespace_scheme) {
            if (namespace.included.filter(ns => ns.name !== name).every(ns => !value ? !!state.streams[ns.name] : state.streams[ns.name] === value)) {
                result[namespace.name] = value;
            }
        }
    }
    result[name] = value;
    return ({ ...state, streams: { ...state.streams, ...result }});
}

export const handleAlertsStateUpdate = (state, obj) => {
    return { ...state, ...obj }
}
