/**
 * Created by alexanderkulyk on 3/20/19.
 */
import { Auth } from "aws-amplify";

export const isASCII = str => /^[\x00-\x7F]*$/.test(str);

export const isAllExclusion = {'is_all_sa': 3, 'is_all_pr': 4, 'is_all_sec': 5, 'is_all_cr': 4, 'is_all_twtr': 1}; 

export const cryptoSymbols = ["ADA", "BCH", "BNB", "BTC", "DASH", "EOS", "ETH", "LTC", "TRX", "USDT", "XLM", "XMR", "XRP"];
export const secNT = ['SEC:10Q:NT', 'SEC:10K:NT', 'SEC:20F:NT'];
export const sec10Pure = ['SEC:10Q', 'SEC:10K', 'SEC:20F'];
export const sec10 = [...sec10Pure, ...secNT];
export const sec4 = ['SEC:3', 'SEC:3A', 'SEC:4', 'SEC:4A', 'SEC:5', 'SEC:5A'];
export const sec8 = ['SEC:8K', 'SEC:6K', 'SEC:8KA', 'SEC:6KA'];
export const pr = ['PR:BW', 'PR:GLOB','PR:NW']
export const sf = ['SEC:S1', 'SEC:S2', 'SEC:F1', 'SEC:F2']

export const P_STREAM_SCHEME = [
    {
        label: 'SEC',
        name: 'ALL:SEC:P_STREAM',
        transformTo: [ ...sec4, ...secNT, ...sf, 'SEC:13', 'SEC:OT'],
        included: [
            {
                label: 'S1/S3/F1/F3',
                name: 'SEC:SF:P_STREAM',
                disabled: false,
                transformTo: [ ...sf ]
            },
            {
                label: 'NT 10K/10Q/20F',
                name: 'SEC:NT:P_STREAM',
                disabled: false,
                transformTo: [ ...secNT ]
            },
            {
                label: 'Form3, 4, and 5',
                name: 'SEC:FORM345:P_STREAM',
                disabled: false,
                transformTo: [ ...sec4 ]
            },
            {
                label: '13D/13G/13F',
                name: 'SEC:13:P_STREAM',
                disabled: false,
                transformTo: [ 'SEC:13' ]
            }
        ]

    },
    {
        label: 'Press Releases',
        name: 'ALL:PR:P_STREAM',
        transformTo: [ ...pr ],
        included: [
            {
                label: 'Business Wire',
                name: 'PR:BW:P_STREAM',
                disabled: false,
                transformTo: [ pr[0] ]
            },
            {
                label: 'Glob Newswire',
                name: 'PR:GLOB:P_STREAM',
                disabled: false,
                transformTo: [ pr[1] ]
            },
            {
                label: 'PR Newswire',
                name: 'PR:NW:P_STREAM',
                disabled: false,
                transformTo: [ pr[2] ]
            }
        ]
    }
]
    
export const B_STREAM_SCHEME = [
    {
        label: 'SEC',
        name: 'ALL:SEC:B_STREAM',
        transformTo: [ ...sec4, ...sec10, ...sec8, 'SEC:OT'],
        included: [
            {
                label: '10K/10Q/20F',
                name: 'ALL:SEC:B_STREAM',
                disabled: true
            },
            {
                label: 'Form3,4,5',
                name: 'ALL:SEC:B_STREAM',
                disabled: true
            },
            {
                label: '8K/6K',
                name: 'ALL:SEC:B_STREAM',
                disabled: true
            },
            {
                label: 'All other filings',
                name: 'ALL:SEC:B_STREAM',
                disabled: true
            },
        ]
    },
    {
        label: 'SeekingAlpha',
        name: 'ALL:SA:B_STREAM',
        transformTo: ['SA:ALL'],
        included: [
            {
                label: 'Transcripts',
                name: 'ALL:SA:B_STREAM',
                disabled: true
            },
            {
                label: 'Long articles',
                name: 'ALL:SA:B_STREAM',
                disabled: true
            },
            {
                label: 'Current news',
                name: 'ALL:SA:B_STREAM',
                disabled: true
            }
        ]
    },
    {
        label: 'Press Releases',
        name: 'ALL:PR:B_STREAM',
        transformTo: [ ...pr ],
        included: [
            {
                label: 'Business Wire',
                name: 'ALL:PR:B_STREAM',
                disabled: true
            },
            {
                label: 'Glob Newswire',
                name: 'ALL:PR:B_STREAM',
                disabled: true
            },
            {
                label: 'PR Newswire',
                name: 'ALL:PR:B_STREAM',
                disabled: true
            }
        ]
    },
    {
        label: 'Upgrade/Downgrade',
        name: 'ALL:UD:B_STREAM',
        transformTo: ['RTG:UD']
    },
    {
        label: 'Benzinga',
        name: 'ALL:BZ:B_STREAM',
        transformTo: ['BZ:AR']
    },
    // {
    //     label: 'The Fly',
    //     name: 'ALL:FL:B_STREAM',
    //     transformTo: ['FL:AR']
    // }
];

export const ITEMS_SCHEME = {
    'Item 1.01 Entry into a Material Definitive Agreement' : 'SEC:8K:ITEM_1_01',
    'Item 1.02 Termination of a Material Definitive Agreement' : 'SEC:8K:ITEM_1_02',
    'Item 1.03 Bankruptcy or Receivership': 'SEC:8K:ITEM_1_03',
    'Item 1.04 Mine Safety': 'SEC:8K:ITEM_1_04',
    'Item 2.01 Completion of Acquisition or Disposition of Assets': 'SEC:8K:ITEM_2_01',
    'Item 2.02 Results of Operations and Financial Condition': 'SEC:8K:ITEM_2_02',
    'Item 2.03 Creation of a Direct Financial Obligation or an Obligation under an Off-Balance Sheet Arrangement of a Registrant': 'SEC:8K:ITEM_2_03',
    'Item 2.04 Triggering Events or Default': 'SEC:8K:ITEM_2_04',
    'Item 2.05 Costs Associated with Exit or Disposal Activities': 'SEC:8K:ITEM_2_05',
    'Item 2.06 Material Impairments': 'SEC:8K:ITEM_2_06',
    'Item 3.01 Notice of Delisting': 'SEC:8K:ITEM_3_01',
    'Item 3.02 Unregistered Sales of Equity Securities': 'SEC:8K:ITEM_3_02',
    'Item 3.03 Material Modification to Rights of Security Holders': 'SEC:8K:ITEM_3_03',
    "Item 4.01 Changes in Registrant's Certifying Accountant": 'SEC:8K:ITEM_4_01',
    'Item 4.02 Non-Reliance on Previously Issued Financial Statements or a Related Audit Report or Completed Interim Review': 'SEC:8K:ITEM_4_02',
    'Item 5.01 Changes in Control of Registrant': 'SEC:8K:ITEM_5_01',
    'Item 5.02 Change in Directors or Officers; Compensatory Arrangements': 'SEC:8K:ITEM_5_02',
    'Item 5.03 Amendments to Articles of Incorporation or Bylaws; Change in Fiscal Year': 'SEC:8K:ITEM_5_03',
    "Item 5.04 Temporary Suspension of Trading Under Registrant's Employee Benefit Plans": 'SEC:8K:ITEM_5_04',
    "Item 5.05 Amendment to Registrant's Code of Ethics, or Waiver of a Provision of the Code of Ethics": 'SEC:8K:ITEM_5_05',
    'Item 5.06 Change in Shell Company Status': 'SEC:8K:ITEM_5_06',
    'Item 5.07 Submission of Matters to a Vote of Security Holders': 'SEC:8K:ITEM_5_07',
    'Item 5.08 Shareholder Director Nominations': 'SEC:8K:ITEM_5_08',
    'Item 6.01 ABS Informational and Computational Material': 'SEC:8K:ITEM_6_01',
    'Item 6.02 Change of Servicer or Trustee': 'SEC:8K:ITEM_6_02',
    'Item 6.03 Change in Credit Enhancement or Other External Support': 'SEC:8K:ITEM_6_03',
    'Item 6.04 Failure to Make a Required Distribution': 'SEC:8K:ITEM_6_04',
    'Item 6.05 Securities Act Updating Disclosure': 'SEC:8K:ITEM_6_05',
    'Item 7.01 Regulation FD Disclosure': 'SEC:8K:ITEM_7_01',
    'Item 8.01 Other Events': 'SEC:8K:ITEM_8_01',
    'Item 9.01 Financial Statements and Exhibits': 'SEC:8K:ITEM_9_01'
}

export const serviceSchemeObject = {
    P_STREAM_SCHEME,
    B_STREAM_SCHEME
}

export const premiumFeatures = [
    'is_pr_earn_email', 'is_pr_earn_phone', 'is_all_pr_email', 'is_all_pr_phone',
    'is_all_sec_email', 'is_all_sec_phone',
    'all_13_email', 'all_13_phone',
    'item_1_03_email', 'item_1_03_phone',
    'item_2_02_email', 'item_2_02_phone',
    'item_2_04_email', 'item_2_04_phone',
    'item_2_06_email', 'item_2_06_phone',
    'item_4_02_email', 'item_4_02_phone',
    'is_sec_13_email', 'is_sec_13_phone'
];

export const plans = {
    free: {
        symbolsAmount: 4,
        smsAmount: 1,
        premiumFeatures: false,
        cost: 0,
        next: 'basic',
        prev: null,
        order: 0
    },
    basic: {
        symbolsAmount: 30,
        smsAmount: Infinity,
        premiumFeatures: true,
        cost: 999,
        next: 'advanced',
        prev: 'free',
        order: 1
    },
    advanced: {
        symbolsAmount: 100,
        smsAmount: Infinity,
        premiumFeatures: true,
        cost: 2999,
        next: 'additionalSymbols',
        prev: 'basic',
        order: 2
    },
    additionalSymbols: {
        cost: 1000,
        amount: 50
    }
}

export const getServiceScheme = service => serviceSchemeObject[service + '_SCHEME'];

export const handleServices = data => {
    const types = new Set(data.map(namespace => namespace.split(':').pop()));
    let namespaces = [].concat(data.filter(item => item.includes('ITEM')));
    for (let type of types) {
        const service_scheme = getServiceScheme(type);
        const serviceData = data.filter(namespace => namespace.includes(type)).map(namespace => namespace.replace(':' + type, ''));
        const isAll = serviceData.includes('ALL');
        if (isAll) {
            namespaces.push('ALL:' + type)
            const result = {};
            Object.values(ITEMS_SCHEME).map(namespace => namespace + ':' + type).forEach(namespace => namespaces.push(namespace));
            namespaces.push('ALL:ITEMS:' + type)
        }
        for (let item of service_scheme) {
            if (isAll) {
                namespaces.push(item.name)
            } else if (item.transformTo && item.transformTo.every(namespace => serviceData.includes(namespace))) {
                namespaces.push(item.name)
            }
            if (item.included) {
                for (let includedItem of item.included) {
                    if (isAll) {
                        namespaces.push(includedItem.name)
                    } else if (!includedItem.disabled && includedItem.transformTo && includedItem.transformTo.every(namespace => serviceData.includes(namespace))) {
                        namespaces.push(includedItem.name)
                    }
                }
            }
        }
    }
    return namespaces.reduce((acc, namespace) => {
        acc[namespace] = true;
        return acc
    }, {});
}

export const prepareServiceStore = (service_data, user) => {
    return {
        email: user.email,
        phone: user.phone_number,
        type: 'P_STREAM',
        namespaces: [],
        subscriptions: [],
        ...service_data
    }
}

export const prepareAlerts = (alerts, user) => {
    if(!Auth.currentUserInfo()) {
        return;
    }

    const items = Object.keys(alerts)
        .filter(item => item.startsWith('item') && alerts[item])
        .map(item => item.replace('_email', ':email').replace('_phone', ':phone'));

    const alertsKey = Object.keys(alerts);

    const is_all_sa_email = alerts.is_all_sa_email;
    const is_all_sa_phone = alerts.is_all_sa_phone;
    

    let sources = alertsKey.filter(
        src => {
            if (src.includes('_sa')) {
                if (src.includes('email') && is_all_sa_email) {
                    return false;
                }
                if (src.includes('phone') && is_all_sa_phone) {
                    return false;
                }

            }
            return src.startsWith('is_') && !src.startsWith('is_all_') && src !== 'is_dnd' && alerts[src];
        }
    ).map(src => {
        const [is, original_source, local_source, _type] = src.split('_');
        return `${original_source.toUpperCase()}:${local_source.toUpperCase()}:${_type}`;
    });

    const sourcesMonoAll = {
        sa: 'SA:ALL',
        rt: 'RTG:UD',
        bz: 'BZ:AR',
        fl: 'FL:AR',
        twtr: 'TWTR:TWTR'
    };

    const sourcesAll = alertsKey.filter(src => {
        return src.includes('is_all_') && alerts[src] && Object.keys(sourcesMonoAll).includes(src.split('_')[2])
    }).map(src => {
        const [is, all, original_source, _type] = src.split('_');
        return `${sourcesMonoAll[original_source]}:${_type}`;

    });
    sources = sources.concat(sourcesAll);
    sources = Array.from(new Set(sources));
    const stocks = alerts.stocks || [];
    const attributes = [];

    if (alerts.is_dnd) {
        attributes.push('_ATT:DND');
    }

    // alerts.stocks && alerts.stocks.forEach((x) => {
    //     stocks.push(x.value);
    // });

    if (!attributes.length) attributes.push("");
    if (!sources.length) sources.push("");

    const preparedSources = sources.map(
        source => source.toUpperCase()
    ).reduce((acc, source) => {
        if (source.includes('SEC:10')) {
            const _type = source.split(':')[2];
            acc = acc.concat(sec10.map(item => item + ':' + _type));
        } else if (source.includes('SEC:4')) {
            const _type = source.split(':')[2];
            acc = acc.concat(sec4.map(item => item + ':' + _type));
        } else if (source.includes('SEC:8')) {
            const _type = source.split(':')[2];
            acc = acc.concat(sec8.map(item => item + ':' + _type)); 
        } else {
            acc.push(source);
        }
        return acc;
    }, []);

    if (preparedSources[0] === "") {
        stocks.splice(0, stocks.length);
        preparedSources.splice(0, preparedSources.length);
    }
    if (attributes[0] === "") {
        attributes.pop();
    }

    if (alerts.stocks.length && sources[0] === '') {
        return {
            message: 'Please select at least one source'
        }
    }
    if (alerts.stocks && alerts.stocks.filter(stock => stock.type === 'tweet').length && !sources.filter(source => source.includes('TWTR:TWTR')).length) {
        return {
            message: 'Please select at least one tweet source'
        }
    }
    if (alerts.stocks && alerts.stocks.filter(stock => stock.type === 'crypto').length && !sources.filter(source => source.startsWith('CR:')).length) {
        return {
            message: 'Please select at least one crypto source'
        }
    }
    if (alerts.stocks && alerts.stocks.filter(stock => stock.type === 'stock' || stock.type === 'etf' || stock.type === 'otc').length && !sources.filter(source => !source.startsWith('CR:') && !source.includes('TWTR:TWTR')).length) {
        return {
            message: 'Please select at least one Stock, ETF or OTC source'
        }
    }

    const approvedStocks = [];
    const subscriptionForAllSymbols = Object.keys(alerts).filter(alert => alert.startsWith('all_') && alerts[alert]).map(alert => {
        const alertList = alert.split('_');
        return 'FULLSYM:SEC:' + alertList[1] + ':' + alertList[2].toUpperCase();
    });
    const namespaces = attributes.concat(
        stocks.reduce((acc, stock) => {
            preparedSources.forEach(source => {
                if (source.includes('TWTR:TWTR') && stock.value.startsWith('@')) {
                    acc.push(stock.value + ':' + source);
                    if (!approvedStocks.includes(stock.value)) approvedStocks.push(stock.value);
                } else if (source.startsWith('CR:') && stock.type === 'crypto') {
                    acc.push(stock.value + ':' + source);
                    if (!approvedStocks.includes(stock.value)) approvedStocks.push(stock.value);
                } else if (!source.startsWith('CR:') && !source.includes('TWTR:TWTR') && !stock.value.startsWith('@') && stock.type !== 'crypto') {
                    acc.push(stock.value + ':' + source);
                    if (!approvedStocks.includes(stock.value)) approvedStocks.push(stock.value);
                }
            })
            return acc;
        }, []).concat(items.map(item => 'SEC:8K:' + item.toUpperCase()))
    ).concat(subscriptionForAllSymbols);
    const subscriptions = approvedStocks.concat(items.map(item => item.split(':')[0].toUpperCase()).reduce((acc, item) => {
        if (!acc.includes(item)) acc.push(item)
        return acc
    }, []));

    return {
        email: user.email,
        phone: user.phone_number,
        type: 'SUBS',
        namespaces,
        subscriptions
    }
};

export function scrollTo(selector) {
    const symbolsTitle = document.querySelector(selector);
    const scrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
    };
    symbolsTitle.scrollIntoView(scrollIntoViewOptions);
}

export const compareByFirstSymbol = (a, b) => a.value[0] > b.value[0] ? 1 : b.value[0] > a.value[0] ? -1 : 0;
