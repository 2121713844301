/**
 * Created by alexanderkulyk on 3/27/19.
 */

import initialState from './state';

import {
    SWITCH_SIGN_TARGET
} from './actions';

import {
    switchSignTarget
} from './reducers';

export default function reducer(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case SWITCH_SIGN_TARGET:
            return switchSignTarget(state, payload);
        default:
            return state;
    }
}