import React, { Component } from "react";
import LoaderButton from "../../../components/LoaderButton";
import "./Login.css";
import { Auth } from "aws-amplify";
import Input from '../../Form/Input';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            isValid: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        const { getUser, showPopupMessage, history } = this.props;
        const { email, password } = this.state;

        this.setState({ isLoading: true });

        try {
            await Auth.signIn(email, password);
            getUser();
            setTimeout(() => history.push("/alerts"), 1000);
        } catch (e) {
            this.setState({ isValid: false });
            showPopupMessage(
                'Incorrect user email / password'
            );
            this.setState({ isLoading: false });
        }
    };

    render() {
        const { email, isValid, password } = this.state;
        const { switchSignTargetToResetPassword } = this.props;
        return (
                <form onSubmit={this.handleSubmit}>
                    <h1>Sign In</h1>
                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        value={email}
                        valid={isValid.toString()}
                        onChange={this.handleChange}
                        bsSize="large"
                        className={isValid ? '' : 'has-error'}
                    />
                    <Input
                        name="password"
                        type="password"
                        label="Password"
                        value={password}
                        valid={isValid.toString()}
                        onChange={this.handleChange}
                        bsSize="large"
                        className={isValid ? '' : 'has-error'}
                    />
                    <div className="signin-reset-password-text-block">
                        <p>Forgot your password?</p>
                        <a className="underform-link" href="/" onClick={switchSignTargetToResetPassword}>
                            Reset password
                        </a>
                    </div>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Sign In"
                        loadingText="Logging in..."
                        className="sign-form-fa-submit"
                    />

                </form>
        );
    }
}