/**
 * Created by alexanderkulyk on 3/11/19.
 */

import initialState from './state';

import {
    GET_SUBSCRIPTIONS_SUCCESS,
    HANDLE_CHANGE_ALERTS_STATE,
    HANDLE_CHECKED_REDUCER,
    HANDLE_STOP_ALL_SUBSCRIPTIONS_REDUCER,
    HANDLE_CHANGE_REDUCER,
    HANDLE_UPDATE_SUCCESS,
    SET_ALERTS_LOADING,
    CLEAR_USER_ID,
    SAVE_EVENT,
    HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT,
    UPDATE_STREAM_STATE,
    ALERTS_STATE_UPDATE
} from './actions';

import {
    getSubscriptionsSuccess,
    handleCheckedReducer,
    handleStopAllSubscriptionsReducer,
    handleChangeReducer,
    handleUpdate,
    setAlertsLoading,
    clearUserId,
    handleChangeAlertsState,
    saveEvent,
    handleChangeAlertsStateByObject,
    updateStreamsState,
    handleAlertsStateUpdate
} from './reducers';


export default function reducer(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case UPDATE_STREAM_STATE:
            return updateStreamsState(state, payload);
        case GET_SUBSCRIPTIONS_SUCCESS:
            return getSubscriptionsSuccess(state, payload);
        case HANDLE_CHECKED_REDUCER:
            return handleCheckedReducer(state, payload);
        case HANDLE_STOP_ALL_SUBSCRIPTIONS_REDUCER:
            return handleStopAllSubscriptionsReducer(state, payload);
        case HANDLE_CHANGE_REDUCER:
            return handleChangeReducer(state, payload);
        case HANDLE_UPDATE_SUCCESS:
            return handleUpdate(state, payload);
        case SET_ALERTS_LOADING:
            return setAlertsLoading(state, payload);
        case HANDLE_CHANGE_ALERTS_STATE:
            return handleChangeAlertsState(state, payload);
        case CLEAR_USER_ID:
            return clearUserId(state);
        case SAVE_EVENT:
            return saveEvent(state, payload);
        case HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT:
            return handleChangeAlertsStateByObject(state, payload);
        case ALERTS_STATE_UPDATE:
            return handleAlertsStateUpdate(state, payload);
        default:
            return state;
    }
}