/**
 * Created by alexanderkulyk on 3/11/19.
 */

/**
 * Create the store with asynchronously loaded reducers
 */
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, createTransform } from 'redux-persist';
import createDebounce from 'redux-debounce';
// import { REHYDRATE } from 'redux-persist/constants';
import createActionBuffer from 'redux-action-buffer';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import saga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const debouncer = createDebounce({
    getSymbolsByTicker: 250,
    debouncedHidePopupMessage: 6000
});

const enhancer = compose(
    applyMiddleware(
        debouncer,
        // createActionBuffer(REHYDRATE),
        sagaMiddleware,
    ),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
);



export default function configureStore() {
    const store = createStore(reducers, enhancer);
    sagaMiddleware.run(saga, store.dispatch);
    return store;
}
