/**
 * Created by alexanderkulyk on 3/26/19.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hidePopupMessage, debouncedHidePopupMessage } from '../../store/actions';
import { popupSelector } from '../../selectors/popup';
import MessagePopup from './MessagePopup';

class MessagePopupContainer extends Component {
    componentDidUpdate(prevProps) {
        const { status } = this.props.popup;
        const { debouncedHidePopupMessage } = this.props;
        if (status) {
            debouncedHidePopupMessage();
        }
    }

    render() {
        return (
            <MessagePopup { ...this.props } />
        )
    }
}

const mapDispatchToProps = {
    hidePopupMessage,
    debouncedHidePopupMessage
};

const mapStateToProps = state => ({ popup: popupSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(MessagePopupContainer);