//import React from 'react';
//import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
//import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

//////////////////////////
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./index.css";
import config from "./config";
import Amplify from "aws-amplify";

import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import configureStore from './store';

import isSecureForm from './secureForm';

const store = configureStore();
// optional cofiguration
const options = {
    position: 'top center',
    timeout: 5000,
    offset: '30px',
    transition: 'scale'
};

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "register",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            },
        ]
    }
});

ReactDOM.render(
    <div> {
        isSecureForm() ? isSecureForm() :
        <Provider store={store}>
            <Router>
                <div>
                    <AlertProvider template={AlertTemplate} {...options}>
                        <App />
                    </AlertProvider>
                </div>
            </Router>
        </Provider>
    }
    </div>,
    document.getElementById("root")
);


//serviceWorker.unregister();
serviceWorker.register();