/**
 * Created by alexanderkulyk on 3/26/19.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    hidePaymentPopup, showPopupMessage, setUserProps, handleUpdate, handleStopAllSubscriptions, handleStripePayment
} from '../../store/actions';
import { paymentPopupSelector } from '../../selectors/paymentPopup';
import { alertsSelector } from '../../selectors/alerts';
import PaymentPopup from './PaymentPopup';
import { userSelector } from '../../selectors/user';

class PaymentPopupContainer extends Component {
    render() {
        return (
            <PaymentPopup { ...this.props } />
        )
    }
}

const mapDispatchToProps = {
    hidePaymentPopup,
    showPopupMessage,
    setUserProps,
    handleUpdate,
    handleStopAllSubscriptions,
    handleStripePayment
};

const mapStateToProps = state => ({
    paymentPopup: paymentPopupSelector(state),
    savedEvent: alertsSelector(state).savedEvent,
    user: userSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPopupContainer);