import React, { Component } from "react";
import Input from '../../Form/Input';
import LoaderButton from "../../../components/LoaderButton";
import "./Signup.css";
import { Auth } from "aws-amplify";
import ReCAPTCHA from "react-google-recaptcha";
import { emailCheckRe } from '../../../utils/user';
import { isFeatureActive } from '../../../utils/common';
import ReactDOM from 'react-dom';
import { checkUserDuplicatesRequest } from '../../../utils/api';
import MaterialUICheckbox from '@material-ui/core/Checkbox';

export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.emailInput = null;
        this.phoneInput = null;

        this.state = {
            isLoading: false,
            email: "",
            confirmCheckbox: false,
            password: "",
            given_name: "",
            family_name: "",
            address: "",
            phone_number: "",
            confirmPassword: "",
            confirmationCode: "",
            newUser: null,
            emailError: '',
            phoneError: ''
        };

        this.recaptchaRef = React.createRef();

        this.tos = "Check to agree with FlashAlert Privacy Policy, Cookie Policy and Terms of Service";
    }

    validateForm() {
        const {
            email, password, given_name, family_name, phone_number, address, confirmPassword, confirmCheckbox
        } = this.state;
        return (
            email.length > 0 && this.validateEmail() === 'success' && 
            password.length > 0 &&
            given_name.length > 0 &&
            family_name.length > 0 &&
            phone_number.length > 0 &&
            address.length > 0 &&
            password === confirmPassword //&& confirmCheckbox === true
        );
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }

    validateEmail() {
        if (this.state.emailError) return "error";
        if (!this.state.email) return null;
        return this.state.email.match(emailCheckRe) ? "success" : "error";
    }

    validatePassword() {
        if (!this.state.password) return null;
        return this.state.password.match(/\S{8,}/) && this.state.password.match(/\d{1,}/) ? "success" : "error";
    }

    validateConfirmPassword() {
        if (!this.state.confirmPassword) return null;
        return this.state.password === this.state.confirmPassword ? "success" : "error";
    }

    validatePhoneNumber() {
        if (!this.state.phone_number) return null;
        if (this.state.phoneError) return "error";
        if (this.state.phone_number.startsWith('+15555555555')) return "error";
        if (isFeatureActive('PHONE_NUMBER_PROD_VALIDATION')) {
            return this.state.phone_number.match(/^\+\d{11}$/) ? "success" : "error";
        } else {
            return this.state.phone_number.match(/^\+\d{11,13}$/) ? "success" : "error";
        }

    }

    validateZip(){
        if (!this.state.address) return null;
        return this.state.address.match(/^\w{5,10}$/) ? "success" : "error";
    }

    handlePhoneOnBlur = event => {
        if (this.state.phone_number) {
            if (!this.state.phone_number.startsWith('+')) {
                if (this.state.phone_number.length === 10) {
                    this.setState({
                        phone_number: '+1' + this.state.phone_number
                    });
                } else if (this.state.phone_number.length > 10) {
                    this.setState({
                        phone_number: '+' + this.state.phone_number
                    });
                }
            }
        }
    }

    handleChange = event => {
        // if (event.target.id === 'phone_number' && event.target.value.length === 10 && !event.target.value.startsWith('+1')) {
        //     event.target.value = '+1' + event.target.value;
        // }
        // if (event.target.name === 'confirmCheckbox') {
        //     this.setState({
        //         confirmCheckbox: !this.state.confirmCheckbox
        //     });
        // }
        if (event.target.id === 'email' && this.state.emailError) {
            this.setState({
                emailError: ''
            });
        }

        if (event.target.id === 'phone_number' && this.state.phoneError) {
            this.setState({
                phoneError: ''
            });
        }

        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        const { showPopupMessage, handleUserAttrs } = this.props;

        this.setState({ isLoading: true });

        try {
            if (isFeatureActive('CAPTCHA')) this.recaptchaRef.current.execute();
            const checkDuplicatesStatus = await checkUserDuplicatesRequest({phone: this.state.phone_number}).then(
                response => response.status
            ).catch(error => error.response.status);
            if (checkDuplicatesStatus == 200) {
                const newUser = await Auth.signUp({
                    username: this.state.email,
                    password: this.state.password,
                    attributes: {
                        given_name: this.state.given_name,
                        family_name: this.state.family_name,
                        phone_number: this.state.phone_number,
                        address: this.state.address
                    }
                });
                handleUserAttrs({
                    email: this.state.email,  
                    phone: this.state.phone_number, 
                    first_name: this.state.given_name,
                    last_name: this.state.family_name,
                    zip: this.state.address
                });
    
                this.setState({
                    newUser
                });
            } else if (checkDuplicatesStatus == 403) {
                const phoneError = 'An account with the given phone number already exists.';
                showPopupMessage(phoneError);

                this.setState({
                    phoneError
                });

                if (this.phoneInput) {
                    ReactDOM.findDOMNode(this.phoneInput).focus();
                }
            }
        } catch (e) {
            if (e.message && e.message.includes('email')) {
                this.setState({
                    emailError: e.message
                });
                if (this.emailInput) {
                    ReactDOM.findDOMNode(this.emailInput).focus();
                }
                
            }
            
            showPopupMessage(e.message);
        }
        this.setState({ isLoading: false });
    };

    handleConfirmationSubmit = async event => {
        event.preventDefault();
        const { showPopupMessage, getUser, history } = this.props;

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            await Auth.signIn(this.state.email, this.state.password);
            getUser();
            setTimeout(() => history.push("/alerts"), 1000);
        } catch (e) {
            showPopupMessage(e.message);
            this.setState({ isLoading: false });
        }
    };

    renderConfirmationForm() {
        const { confirmationCode, isLoading } = this.state;
        return (
            <form onSubmit={this.handleConfirmationSubmit}>
                <Input
                    name="confirmationCode"
                    type="tel"
                    label="Confirmation Code"
                    value={confirmationCode}
                    placeholder="Enter Code"
                    onChange={this.handleChange}
                    helpText="Please check your email for the code."
                    bsSize="large"
                    autoFocus={true}
                />
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateConfirmationForm()}
                    type="submit"
                    isLoading={isLoading}
                    text="Verify"
                    loadingText="Verifying"
                />
            </form>
        );
    }

    renderForm() {
        const {
            email, password, confirmPassword, given_name, family_name, phone_number, address, isLoading, confirmCheckbox
        } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <h1>Sign Up</h1>
                <div className="form-fa-two-input-block">
                    <Input
                        name="given_name"
                        type="text"
                        label="First Name*"
                        value={given_name}
                        onChange={this.handleChange}
                        validationState={this.validateConfirmPassword()}
                        bsSize="large"
                    />
                    <Input
                        name="family_name"
                        type="text"
                        label="Last Name*"
                        value={family_name}
                        onChange={this.handleChange}
                        bsSize="large"
                    />
                </div>
                <Input
                    name="email"
                    type="email"
                    label="Email*"
                    value={email}
                    onChange={this.handleChange}
                    validationState={this.validateEmail()}
                    bsSize="large"
                    inputRef={ref => { this.emailInput = ref; }}
                />
                <Input
                    name="password"
                    type="password"
                    label="Password*"
                    value={password}
                    onChange={this.handleChange}
                    validationState={this.validatePassword()}
                    bsSize="large"
                    helpText="Use at least 8 lower-case letters and numbers"
                />
                <Input
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password*"
                    value={confirmPassword}
                    onChange={this.handleChange}
                    validationState={this.validateConfirmPassword()}
                    bsSize="large"
                />
                <Input
                    inputRef={ref => { this.phoneInput = ref; }}
                    name="phone_number"
                    type="tel"
                    label="Mobile Phone Number*"
                    value={phone_number}
                    validationState={this.validatePhoneNumber()}
                    onChange={this.handleChange}
                    onBlur={this.handlePhoneOnBlur}
                    bsSize="large"
                    maxLength={isFeatureActive('PHONE_NUMBER_PROD_VALIDATION') ? 12 : 14}
                    tooltip="+15555555555 (US numbers must start with +1)"
                />
                <Input
                    name="address"
                    type="text"
                    label="Zip Code*"
                    value={address}
                    onChange={this.handleChange}
                    bsSize="large"
                    validationState={this.validateZip()}
                    placeholder="55555"
                    maxLength={10}
                />

                <h3 className="form-policy">
                    By submitting this form, you agree to our <a href="/terms" target="_blank">Terms of Use</a> & <a href="/privacy" target="_blank">Privacy Policy</a>
                </h3>
                {/* <h3 className="form-policy"> // 
                <MaterialUICheckbox
                    classes={{
                        root: 'fa-ui-checkbox',
                        colorSecondary: 'fa-ui-checkbox-color'
                    }}
                    name='confirmCheckbox'
                    checked={confirmCheckbox}
                    onChange={this.handleChange}
                    inline="true"></MaterialUICheckbox>
                    By checking this box you agree to receive SMS messages. You can opt out any time by replying “STOP”. 
                    
                </h3> */}

                {isFeatureActive('CAPTCHA') &&
                    <ReCAPTCHA sitekey="6LeKuqAUAAAAANcJs_sZ6UJpKPZ8iASjJilZHPyZ" ref={this.recaptchaRef} size="invisible" />
                }
                <br />
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={isLoading}
                    text="Create account"
                    loadingText="Signing up..."
                    className="sign-form-fa-submit"
                />


            </form>
        );
    }

    render() {
        return (
            <div className="Signup">
                {this.state.newUser === null
                    ? this.renderForm()
                    : this.renderConfirmationForm()}
            </div>
        );
    }
}