import React, { Component } from "react";
import {
    Media, Label, PageHeader
} from "react-bootstrap";
import "./UseCases.css";

export default class UseCases extends Component {
    render() {
        return (
            <div className="page-container">
                <div className="grey-block page-media">
                    <PageHeader className="centered-title">
                        Use Cases
                    </PageHeader>
                    <div className="flex-container">

            <Media>
                <Media.Body>
                <Media.Heading>How to use FlashAlert</Media.Heading>
                    <p><Media.Left>
                        <h2>
                            <img width={64} height={64} id="imgRounded" src="/images/flash-alert-logo-64x60.png" alt="thumbnail" />
                        </h2>
                    </Media.Left>
                        FlashAlert is your personal low-latency service for latest SEC filings,
                        PR News releases, upgrades and downgrades covering about 13,000 publicly traded U.S. companies.
                        If your company trades on NASDAQ, NYSE, AMEX, OTC or Pink Sheets – we got you covered
                    around the clock!
                    </p>
                </Media.Body>
            </Media>

            {/* <Media>

                <Media.Body>
                    <Media.Heading>FlashAlert Beta</Media.Heading>
                    <p>
                    <Media.Left>
                        <h2>
                            <Label>Beta</Label>
                        </h2>
                    </Media.Left>
                        While the service is in beta version, you may receive up to 10 stock tickers, unlimited emails, and one SMS channel
                        completely free of charge (your mobile and internet usage fees charged
                        by your provider may apply). All you have to do: register, sign-up, and watch
                        for the news to arrive!
                    </p>
                </Media.Body>
            </Media> */}
                    </div>
                <Media>

                    <Media.Body>
                        {/* <Media.Heading>Professional Trader</Media.Heading> */}
                        <p>
                            <Media.Left>
                                <h2 className='label-wrapper'>
                                    <Label bsStyle="primary">TRADERS</Label>
                                </h2>
                            </Media.Left>
                            Ingest algorithmically ingest structured breaking market news via Websockets coming to you with minimal latency. All news is in easy to parse JSON format enriched with additional reference and proprietary data such as trading signals.
                        </p>
                    </Media.Body>
                </Media>

                <Media>
                    <Media.Body>
                        {/* <Media.Heading>Investor</Media.Heading> */}
                        <p>
                            <Media.Left>
                                <h2 className='label-wrapper'>
                                    <Label bsStyle="success">INVESTORS</Label>
                                </h2>
                            </Media.Left>
                            Minimize risk by getting SEC and PR data in immediately actionable format via your email with the links to the original source by setting up your portfolio and type of news you’re interested in.
                        </p>
                    </Media.Body>
                </Media>

                <Media>

                    <Media.Body>
                        {/* <Media.Heading>Research Analyst</Media.Heading> */}
                        <p>
                            <Media.Left>
                                <h2 className='label-wrapper'>
                                    <Label bsStyle="info">RESEARCH</Label>
                                </h2>
                            </Media.Left>
                            Backtest trading strategy, data mine patterns, or discover historical correlations in new on equities by running SQL queries against several years of news data with fields fully parsed. Ingest SEC and PR data in JSON format into your Data Lake to cross-reference against other sources.
                        </p>
                    </Media.Body>
                </Media>
                </div>
            </div>
    );
    }
}