/**
 * Created by alexanderkulyk on 25/5/20.
 **/
import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import "./FlashButton.css";


export default class FlashButton extends Component {
    onClick = e => {
        e.preventDefault();
        const { onChange, name, checked } = this.props;
        const event = {
            target: {
                name,
                checked: !checked
            }
        };
        onChange(event);
    };

    render() {
        const { checked, className='', disabled=false, isLoading=false, text='', loadingText='', block=false, type='' } = this.props;
        return (
            <Button
                disabled={disabled || isLoading}
                className={`${className} alerts-button ${
                    checked ? 'alerts-button-checked' : 'alerts-button-unchecked'
                }`}
                block={block}
                type={type}
                onClick={this.onClick}>
                {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
                {!isLoading ? text : loadingText}
            </Button>
        )
    }
}