import React, { Component } from "react";
import {
     Media, PageHeader
} from "react-bootstrap";
import "./UseCases.css";

export default class QA extends Component {
    render() {
        return (
            <div className="page-container">
                <div className="grey-block">
                    <PageHeader className="centered-title">
                        Q & A
                    </PageHeader>
                    <div className="flex-container">
                            <Media>
                                <Media.Left>
                                <img width={64} height={64} id="imgRounded" src="images/qa.png" alt="thumbnail" />
                                </Media.Left>
                                <Media.Body>
                                <Media.Heading>Is FlashAlert really free?</Media.Heading>
                                    <p>FlashAlert basic service is free for first 30 days. Our Standard service is $9.99 a month but will charge more for additional advanced features.
                                    </p>
                                </Media.Body>
                            </Media>

                                <Media>
                                    <Media.Left>
                                        <img width={64} height={64} id="imgRounded" src="images/qa.png" alt="thumbnail" />
                                    </Media.Left>
                                    <Media.Body>
                                        <Media.Heading>How can you make your basic service free and also this fast? Who is covering the costs?</Media.Heading>
                                        <p>FlashAlert is built using the latest "low latency" cloud technology. We have no legacy
                                            infrastructure to maintain. All of our operations are completely automated. We have
                                            very little overhead.
                                        </p>
                                    </Media.Body>
                                </Media>

                                <Media>
                                    <Media.Left>
                                        <img width={64} height={64} id="imgRounded" src="images/qa.png" alt="thumbnail" />
                                    </Media.Left>
                                    <Media.Body>
                                        <Media.Heading>Do I need a mobile app?</Media.Heading>
                                        <p>Our service is "app-less". There is nothing to install: the time sensitive data directly to our
                                            clients. The news is a basic commodity. What matters is the ease of access,
                                            its delivery speed, and its accuracy. We like to keep things simple.
                                        </p>
                                    </Media.Body>
                                </Media>

                                <Media>
                                    <Media.Left>
                                        <img width={64} height={64} id="imgRounded" src="images/qa.png" alt="thumbnail" />
                                    </Media.Left>
                                    <Media.Body>
                                        <Media.Heading>How are you different from traditional financial offerings?</Media.Heading>
                                        <p>First and foremost, our service is very affordable while many of the product
                                            features we offer might cost hundreds of dollars a month charged by other professional
                                            news services.
                                            We minimize our cost as we are getting the data directly from its source.
                                            We are striving to excel in the areas
                                            where larger traditional companies typically lag behind because of their
                                            slow change pattern. To enhance product quality, we are working on adding
                                            additional Artificial Intelligence features, including
                                            Machine Learning and NLP (Natural Language Processing) to further improve
                                            classification of the incoming news.
                                        </p>
                                    </Media.Body>
                                </Media>


                    </div>

                </div>
            </div>
    );
    }
}