/**
 * Created by alexanderkulyk on 3/26/19.
 */
import { pureQuestionPopupState } from './state';

export const setQuestionPopup = (state, { status, message='', callbacks }) => (
    { ...state, status, message, callbacks }
);

export const showQuestionPopup = (state, { message, callbacks }) => setQuestionPopup(
    state, { status: true, message, callbacks }
);

export const hideQuestionPopupReducer = state => {
    return {
        ...pureQuestionPopupState,
        callbacks: { ...pureQuestionPopupState.callbacks },
    };
};