/**
 * Created by alexanderkulyk on 3/15/19.
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Alerts from './Alerts';
import {
    getSubscriptions,
    handleChecked,
    handleStopAllSubscriptions,
    handleChange,
    getSymbolsByTicker,
    setAlertsLoading,
    handleUpdate,
    showPopupMessage,
    handleChangeAlertsState,
    showPaymentPopup,
    setUserProps,
    showQuestionPopup,
    cancelStripeSubscription,
    getUser,
    updateStreamsState,
    handleUpdateStream,
    handleAlertsStateUpdate,
    handleStripePayment
} from '../../store/actions';
import { alertsSelector } from '../../selectors/alerts';
import { userSelector } from '../../selectors/user';


class AlertsContainer extends PureComponent {
    render() {
        return(<Alerts { ...this.props }/>);
    }
}

const mapDispatchToProps = {
    getSubscriptions,
    handleChecked,
    handleStopAllSubscriptions,
    handleChange,
    getSymbolsByTicker,
    setAlertsLoading,
    handleUpdate,
    showPopupMessage,
    handleChangeAlertsState,
    showPaymentPopup,
    setUserProps,
    showQuestionPopup,
    cancelStripeSubscription,
    getUser,
    updateStreamsState,
    handleUpdateStream,
    handleAlertsStateUpdate,
    handleStripePayment
};

const mapStateToProps = state => ({ alerts: alertsSelector(state), user: userSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);