import React, { Component } from "react";
import "./ResetPassword.css";
import { Auth } from 'aws-amplify';
import LoaderButton from "../../../components/LoaderButton";
import { emailCheckRe } from '../../../utils/user';
import Input from '../../Form/Input';

export default class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userEmail : "",
            show: false,
            alert: "",
            userEmailUpdate: "",
            userPin: "",
            userNewPassword: "",
            userNewPasswordConfirmation: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    validateFirstEmail() {
        if (!this.state.userEmail) return null;
        return this.state.userEmail.match(emailCheckRe) ? "success" : "error";
    }

    validateFirstForm() {
        return this.validateFirstEmail() === "success" ? true : false;
    }

    validateUpdateEmail() {
        if (!this.state.userEmailUpdate) return null;
        return this.state.userEmailUpdate.match(emailCheckRe) ? "success" : "error";
    }

    validatePin() {
        if (!this.state.userPin) return null;
        return this.state.userPin.match(/^\d{6}$/) ? "success" : "error";
    }

    validateUserNewPasswordPassword() {
        if (!this.state.userNewPassword) return null;
        return this.state.userNewPassword.length >= 6 ? "success" : "error"
    }

    validateUserNewPasswordConfirmation() {
        if (!this.state.userNewPasswordConfirmation) return null;
        return this.state.userNewPasswordConfirmation === this.state.userNewPassword ? "success" : "error";
    }

    validateSecondForm() {
        const stateValidation = [
            this.validatePin(), this.validateUpdateEmail(),
            this.validateUserNewPasswordPassword(), this.validateUserNewPasswordConfirmation()
        ];
        return !stateValidation.includes('error') && !stateValidation.includes(null);
    }

    handleClick = async event => {
        event.preventDefault();
        const { showPopupMessage } = this.props;

        Auth.forgotPassword(this.state.userEmail)
            .then(data => {
                showPopupMessage(
                    'Please wait for email pin code'
                );
                this.setState({ show: true, userEmailUpdate: this.state.userEmail });
            })
            .catch(err => {
                if (err.code === 'InvalidParameterException') {
                    showPopupMessage(
                        'Error: ' + err.message + '. Please contact us for support.'
                    );
                    document.querySelector('[href="#contact-form"]').click();
                } else if (err.code === 'LimitExceededException') {
                    showPopupMessage(
                        err.message
                    );
                } else {
                    showPopupMessage(
                        'Email not found'
                    );
                }
            });
     };

    handleUpdate = async event => {
        event.preventDefault();
        const { showPopupMessage, switchSignTarget } = this.props;

        Auth.forgotPasswordSubmit(this.state.userEmailUpdate, this.state.userPin, this.state.userNewPassword)
            .then(data => {
                showPopupMessage(
                    'Your password has been reset'
                );
                switchSignTarget('Sign In');
            })
            .catch(err => {
                showPopupMessage(
                    'Incorrect pin'
                );
                this.setState({ userPin: "error", show: false });

            });
    };

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value });
    }

    switchToLoginForm = e => {
        e.preventDefault();
        const { switchSignTarget } = this.props;
        switchSignTarget('Sign In');
    };

    getResetPasswordFormFirstStep = () =>
        <form>
            <h1>Reset Password</h1>
            <Input
                name="userEmail"
                type="email"
                label="Enter your email"
                validationState={this.validateFirstEmail()}
                onChange={this.handleChange}
                bsSize="large"
                className='ResetPassword1'
                value={this.state.userEmail}
            />
            <LoaderButton
                block
                disabled={!this.validateFirstForm()}
                type="submit"
                text="Reset Password"
                loadingText="Processing..."
                onClick={this.handleClick}
                className="sign-form-fa-submit fa-button-with-top-margin"
            />
            <div className="signin-reset-password-text-block">
                <a className="underform-link" href="#" onClick={this.switchToLoginForm}>Already have an account?</a>
            </div>
        </form>;

    getResetPasswordFormSecondStep = () =>
        <form>
            <h1>Reset Password</h1>
            <Input
                name="userEmailUpdate"
                type="email"
                label="User email"
                validationState={this.validateUpdateEmail()}
                onChange={this.handleChange}
                bsSize="large"
                className='ResetPassword1'
                value={this.state.userEmail}
            />
            <Input
                name="userPin"
                type="text"
                label="Pin"
                validationState={this.validatePin()}
                onChange={this.handleChange}
                bsSize="large"
                className='ResetPassword1'
                value={this.state.userPin}
            />
            <Input
                name="userNewPassword"
                type="password"
                label="New Password"
                validationState={this.validateUserNewPasswordPassword()}
                onChange={this.handleChange}
                bsSize="large"
                className='ResetPassword1'
                value={this.state.userNewPassword}
            />
            <Input
                name="userNewPasswordConfirmation"
                type="password"
                label="Confirm Password"
                validationState={this.validateUserNewPasswordConfirmation()}
                onChange={this.handleChange}
                bsSize="large"
                className='ResetPassword1'
                value={this.state.userNewPasswordConfirmation}
            />
            <LoaderButton
                block
                disabled={!this.validateSecondForm()}
                type="submit"
                text="Update User Profile"
                loadingText="Processing..."
                onClick={this.handleUpdate}
                className="sign-form-fa-submit fa-button-with-top-margin"
            />
        </form>;

    getForm = () => {
        const { show } = this.state;
        return show ? this.getResetPasswordFormSecondStep() : this.getResetPasswordFormFirstStep();
    };

    render() {
        return this.getForm();
    }
}