/**
 * Created by alexanderkulyk on 4/4/19.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPopupMessage, showQuestionPopup, clearUser, getUser, clearUserId, handleUserAttrs } from '../../store/actions';
import { userSelector } from '../../selectors/user';
import Profile from './Profile';

class ProfileContainer extends Component {
    render() {
        return (
            <Profile { ...this.props } />
        )
    }
}

const mapDispatchToProps = {
    showPopupMessage,
    showQuestionPopup,
    clearUser,
    getUser,
    clearUserId,
    handleUserAttrs
};

const mapStateToProps = state => ({ user: userSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);