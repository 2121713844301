import React,{ Component } from "react";
import "./Terms.css";

export default class Accessibility extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page-container">
                <div className="grey-block">
                    <h1 className="page-title title-with-bottom-padding">Accessibility</h1>
                    FlashAlert is committed to ensuring that this web site is accessible to everyone.
                    If you have any questions or suggestions regarding the accessibility of this site,
                    please contact us. FlashAlert webpages use structured semantic markup and include
                    a consistent set of navigation links. This site is using Cascading Style Sheets (CSS) for visual layout.
                </div>
            </div>
        );
    }
}