/**
 * Created by alexanderkulyk on 3/29/19.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userSelector } from '../../selectors/user';
import { headerSelector } from '../../selectors/header';
import { showPopupMessage, getUser, switchSignTarget, handleUserAttrs, setUserProp, handleAlertsStateUpdate } from '../../store/actions';
import Home from './Home';
import HomeWithIFrameLink from './HomeWithIFrameLink';

class HomeContainer extends Component {

    render() {
        const { shortLinkTo } = this.props;
        return(
            shortLinkTo ?
                <HomeWithIFrameLink { ...this.props} />
                : <Home { ...this.props } />
        );
    }
}

const mapDispatchToProps = {
    showPopupMessage,
    getUser,
    switchSignTarget,
    handleUserAttrs,
    setUserProp,
    handleAlertsStateUpdate
};

const mapStateToProps = state => ({ user: userSelector(state), header: headerSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);