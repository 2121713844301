/**
 * Created by alexanderkulyk on 6/5/19.
 */

import { createSelector } from 'reselect';

const paymentPopupStateSelector = state => state.paymentPopup;

export const paymentPopupSelector = createSelector(
    paymentPopupStateSelector,
    paymentPopup => paymentPopup
);