/**
 * Created by alexanderkulyk on 3/28/19.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPopupMessage, switchSignTarget, setUserProp } from '../../store/actions';
import { userSelector } from '../../selectors/user';
import Footer from './Footer';

class FooterContainer extends Component {

    render() {
        return(
            <Footer { ...this.props } />
        );
    }
}

const mapDispatchToProps = {
    showPopupMessage,
    switchSignTarget,
    setUserProp
};

const mapStateToProps = state => ({ user: userSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);