/**
 * Created by alexanderkulyk on 4/3/19.
 **/
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './UnselectAlertsButton.css';

export default class UnselectAlertsButton extends Component {
    onClick = e => {
        e.preventDefault();
        const { onChange, name, checked } = this.props;
        const event = {
            target: {
                name,
                checked: !checked
            }
        };
        onChange(event);
    };

    render() {
        const { checked, className='' } = this.props;
        return (
            <Button
                className={`${className} unselect-alerts-button ${
                    checked ? 'unselect-alerts-button-checked' : 'unselect-alerts-button-unchecked'
                }`}
                onClick={this.onClick}>
                {checked ? 'Restore Selection' : 'Reset Selection'}
            </Button>
        )
    }
}