/**
 * Created by alexanderkulyk on 3/26/19.
 */
import { createAction } from 'redux-actions';

import {
    debouncedAction
} from '../../../utils/common';

export const SET_PAYMENT_POPUP = 'paymentPopup:set';

export const SHOW_PAYMENT_POPUP = 'paymentPopup:show';
export const HIDE_PAYMENT_POPUP = 'paymentPopup:hide';

export const HANDLE_STRIPE_PAYMENT = 'paymentPopup:handle-payment';

export const handleStripePayment = createAction(
    HANDLE_STRIPE_PAYMENT, payload => payload
);

export const showPaymentPopup = createAction(
    SHOW_PAYMENT_POPUP, (message, cost, meta) => ({message, cost, meta})
);

export const hidePaymentPopup = createAction(HIDE_PAYMENT_POPUP);

export const setPaymentPopup = createAction(
    SET_PAYMENT_POPUP, (status, {message, cost}) => ({ status, message, cost })
);