import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import "./Profile.css";
import {Auth} from "aws-amplify";
import uuidv1 from 'uuid/v1';
import { withAlert } from 'react-alert';
import Input from '../Form/Input';
import { userLogout } from '../../utils/user';
import { isFeatureActive } from '../../utils/common';

class Profile extends Component {

    constructor(props) {
        super(props);

        const { email, given_name, family_name, address, phone_number } = props.user;

        this.state = {
            email,
            given_name,
            family_name,
            address,
            phone_number,
            isDoneUpdating: false,
            isLoading: false
        };

        this.deleteProfile = this.deleteProfile.bind(this);
    }

    async handleUpdate() {
        const { email, given_name, family_name, phone_number, address} = this.state;
        const { getUser, handleUserAttrs } = this.props;
        if(!Auth.currentUserInfo()) return;
        let user = await Auth.currentAuthenticatedUser();
        let result = await Auth.updateUserAttributes(user, {
            'email': email,
            'given_name': given_name,
            'family_name': family_name,
            'phone_number': phone_number,
            'address': address
        });
        console.log('test uesr', this.props);

        if (result === 'SUCCESS') {
            handleUserAttrs({
                email, 
                phone: phone_number, 
                first_name: given_name,
                last_name: family_name,
                zip: address
            });
            localStorage.removeItem('user');
            getUser();
            this.setState({ isDoneUpdating: true });
        }
        return result;
    };

    handleSubmit = async event => {
        event.preventDefault();
        const { showPopupMessage } = this.props;
        this.setState({ isLoading: true });
        showPopupMessage(
            'Profile successfully updated'
        );
        try {
            await this.handleUpdate();
        } catch(e) {
        }
    };


    validatePhoneNumber() {
        const { phone_number } = this.state;
        if (!phone_number) {
            return null;
        }
        if (isFeatureActive('PHONE_NUMBER_PROD_VALIDATION')) {
            return phone_number.match(/^\+\d{11}$/) ? "success" : "error";
        } else {
            return phone_number.match(/^\+\d{11,13}$/) ? "success" : "error";
        }
    }

    validateZip(){
        const { address } = this.state;
        if (!address) {
            return null;
        }
        return address.match(/^\w{5,10}$/) ? "success" : "error";
    }

    validateEmail(){
        const { email } = this.state;
        if (!email) {
            return null;
        }
        return email.match(/^.+@.+\..+$/) ? "success" : "error";
    }

    async deleteProfile() {
        const { showQuestionPopup, showPopupMessage, history, clearUser, clearUserId } = this.props;
        if(!Auth.currentUserInfo()) return;

        showQuestionPopup(
            'Do you want to delete your profile and all your data?',
            {
                'Yes': async () => {
                    const user = await Auth.currentAuthenticatedUser();
                    const result = await Auth.updateUserAttributes(user, {
                        'email': uuidv1() + '@' + uuidv1() + '.com',
                        'given_name': 'DELETED',
                        'family_name': 'DELETED',
                        'phone_number': "+11111111111",
                        'address': 'DELETED'
                    });

                    if (result === 'SUCCESS') {
                        await userLogout(history, clearUser, clearUserId);
                        showPopupMessage(
                            'User profile has been deleted'
                        );
                    }
                    else {
                        console.log(result);
                        showPopupMessage(
                            'User profile error'
                        );
                    }
                },
                'Cancel': () => {}
            }
        );
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { given_name, family_name, email, phone_number, address } = this.state;
        return (
            <div className="page-container">
                <div className="page-grey-block">
                    <div className="page-title-block">
                        <h1 className="page-title">Edit Profile</h1>
                    </div>
                    <form onSubmit={this.handleSubmit} className="page-form page-form-fullwidth profile-form">
                     <Table responsive striped bordered condensed hover className="profileTable">

                            <tbody>
                                <tr>
                                    <td>First Name</td>
                                    <td>
                                        <Input
                                            name="given_name"
                                            type="text"
                                            label="First Name"
                                            value={given_name}
                                            onChange={this.onChange}
                                            bsSize="large"
                                            autoFocus={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last Name</td>
                                    <td><Input
                                            name="family_name"
                                            type="text"
                                            label="Last Name"
                                            value={family_name}
                                            onChange={this.onChange}
                                            bsSize="large"
                                    /></td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td><Input
                                            name="email"
                                            type="email"
                                            label="Email"
                                            value={email}
                                            onChange={this.onChange}
                                            bsSize="large"
                                            validationState={this.validateEmail()}
                                            disabled="true"
                                    /></td>
                                    {/*<td>{email}</td>*/}
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td><Input
                                            name="phone_number"
                                            type="tel"
                                            label="Phone Number"
                                            value={phone_number}
                                            onChange={this.onChange}
                                            bsSize="large"
                                            validationState={this.validatePhoneNumber()}
                                            disabled="true"
                                    /></td>
                                </tr>
                                <tr>
                                    <td>Zip</td>
                                    <td><Input
                                            name="address"
                                            type="text"
                                            label="Zip"
                                            value={address}
                                            onChange={this.onChange}
                                            bsSize="large"
                                            validationState={this.validateZip()}
                                    /></td>
                                </tr>
                                <tr><td></td><td>
                                    {/*<Button*/}
                                        {/*eventKey={2}*/}
                                        {/*title="Delete User Profile"*/}
                                        {/*className="cancel-button"*/}
                                        {/*onClick={this.deleteProfile}>*/}
                                        {/*Delete Profile*/}
                                    {/*</Button>*/}
                                    <Button
                                        eventKey={2}
                                        title="Delete User Profile"
                                        className="fa-submit-button"
                                        onClick={this.handleSubmit}>
                                       Update Profile
                                    </Button>
                                </td></tr>
                            </tbody>

                    </Table>
                    </form>
                    </div>
                </div>
        );
    }
}

export default withAlert(Profile);