/**
 * Created by alexanderkulyk on 3/11/19.
 */

import { combineReducers } from 'redux';

import alerts from './modules/alerts';
import user from './modules/user';
import popup from './modules/popup';
import header from './modules/header';
import questionPopup from './modules/questionPopup';
import paymentPopup from './modules/paymentPopup';

export default combineReducers({
    user,
    alerts,
    popup,
    header,
    questionPopup,
    paymentPopup
});
