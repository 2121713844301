/**
 * Created by alexanderkulyk on 3/20/19.
 */
import { baseUrl, stripeUrl } from '../config';
import { fetchToken } from './user';
import axios from 'axios';

export const addAuthTokenToRequest = async () => {
    const currentSession = await fetchToken();
    const token = currentSession.idToken.jwtToken;
    axios.defaults.headers.common['Authorization'] = token;
    return token;
};

export const fetchSubscriptions = email => axios.post(baseUrl + "/v1/select-subscription", { "email": email });

export const fetchSymbols = () => axios.get(baseUrl + '/v1/fetch-symbols');

export const sendUpdateAlertsRequest = data => axios.post(baseUrl + "/v1/update-subscription", data);

export const handleUserRequest = data => axios.post(baseUrl + "/v1/handle-user", data);
export const checkUserDuplicatesRequest = data => axios.post(baseUrl + '/v1/handle-user?command=check_duplicates', data);

export const fetchSymbolsByTicker = (value, symbolType) => axios.get(
    `${baseUrl}/v1/fetch-symbols?search=${value}&type=${symbolType}`
);

export const requestToStripe = data => axios.post(stripeUrl + '/v1/stripe', data);

export const cancelStripeSubscriptionRequest = data => axios.post(stripeUrl + '/v1/stripe/cancel', data);