/**
 * Created by alexanderkulyk on 4/8/19.
 */
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./Home.css";
import { getCookie, setCookie } from '../../utils/common';
import { plans } from '../../utils/alerts';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Tooltip from '../Tooltip';

export default class HomeWithIFrameLink extends Component {

    constructor(props) {
        super(props);
        this.welcomeContainer = null;
        this.contentContainer = null;
        this.state = {
            isWelcomeBlockOpen: true
        }
    }

    onClick = e => {
        e.preventDefault();
        window.location.replace('/');
    };

    onClose = e => {
      this.setState({
          isWelcomeBlockOpen: false
      });
      window.removeEventListener('scroll', this.listenToScroll)
      this.contentContainer.style.marginTop = 'inherit';
      setTimeout(() => setCookie('showWelcomeContainer', true, 1), 1000);
    };

    redirectTo = e => {
        e.preventDefault();
        const { shortLinkTo } = this.props;
        const { url } = shortLinkTo;
        window.open(url, '_blank');
    };

    updateSubject = e => {
        const { setUserProp } = this.props;
        setUserProp('subject', 'low latency feed')
    }

    componentDidMount () {
        if (window.addEventListener) {
            window.addEventListener('scroll', this.listenToScroll)
        }
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }
    
    listenToScroll = event => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > this.welcomeContainer.offsetTop) {
            this.welcomeContainer.style.position = 'fixed';
            this.welcomeContainer.style.zIndex = 1060;
            if ((document.body.offsetWidth || document.documentElement.offsetWidth) > 1200) {
                this.welcomeContainer.style.width = '88%';
            }
            this.contentContainer.style.marginTop = `${this.welcomeContainer.offsetHeight}px`;
        } else {
            this.welcomeContainer.style.position = 'inherit';
            this.welcomeContainer.style.zIndex = 'inherit';
            this.welcomeContainer.style.width = '100%';
            this.contentContainer.style.marginTop = 'inherit';
        }
      }

    render() {
        const { isWelcomeBlockOpen } = this.state;
        const { user, shortLinkTo } = this.props;
        const { s3, url } = shortLinkTo;
        const baseS3URL = 'https://s3.amazonaws.com/flashalert/';
        let preparedS3URL = s3.startsWith(baseS3URL) ? s3 : baseS3URL + s3;
        preparedS3URL = preparedS3URL
            .replace(/\?/g, '%3F')
            .replace(/=/g, '%3D')
            .replace(/ü/g, '%25C3%25BC')
            .replace(/ä/g, '%25C3%25A4')
            .replace(/ç/g, '%25C3%25A7')
            .replace(/é/g, '%25C3%25A9')
        const { isAuthenticated } = user;
        const isShowWelcomeContainer = getCookie('showWelcomeContainer');
        const isShow = !isAuthenticated && !isShowWelcomeContainer;
        return (
            <div className={`home-container with-welcome-container ${!isWelcomeBlockOpen && 'closed-welcome-container'}`}>   
                     <div className='welcome-container'  ref={ref => { this.welcomeContainer = ref}}>
                        <div className="popup-close-button" style={{fontSize: '40px', float: 'none', padding: 'initial'}} onClick={this.onClose}>×</div>
                        { isShow && <div className='welcome-block'>
                            {(document.body.offsetWidth || document.documentElement.offsetWidth) > 1152 && <h2>
                                FlashAlert - low-latency financial news service
                        </h2>}
                            <div className='fa-inline-button-block'>
                                <h4>
                                    To receive alerts via email without 15 sec. delay
                                </h4>
                                <Button
                                        block
                                        bsSize="large"
                                        type="submit"
                                        className="fa-button second link-to-signup"
                                        onClick={this.onClick}
                                    >
                                        Sign Up
                                </Button>
                            </div>
                            
                            {(document.body.offsetWidth || document.documentElement.offsetWidth) > 1152 && <ul className='feature-ul'>
                            <li><h4>You'll get alerts from SEC, PR releases, Seeking Alpha and Benzinga articles, Upgrades/Downgrades and more!</h4></li>
                            <li><h4>Basic service with {plans.free.symbolsAmount} symbols and one SMS is free for first 30 days. Premium service is only $9.99 a month.</h4></li>
                            <li><h4>You can try our Streaming Structured News API via WebSockets which will automate your trading
                     
                                {/* If you'd like to discuss our low latency feed, please <AnchorLink onClick={this.updateSubject} href='#contact-form'>email us</AnchorLink> */}
                            </h4></li>
                            </ul>}
                        </div>}
                        {/* <div className={!isShow ? 'welcome-block-ads-full': 'welcome-block-ads'}>
                            <ins className="adsbygoogle"
                                style={{display: 'block'}}
                                data-ad-client="ca-pub-8914880925442580"
                                data-ad-slot="1741337485"
                                data-ad-format="auto"
                                target="_blank"
                                data-full-width-responsive="true"></ins>
                        </div> */}
                    </div>
                    {/* { !isShow && 
                        <div className={'welcome-block-ads-full'}>
                        <ins className="adsbygoogle"
                            style={{display: 'block'}}
                            data-ad-client="ca-pub-8914880925442580"
                            data-ad-slot="1741337485"
                            data-ad-format="auto"
                            target="_blank"
                            data-full-width-responsive="true"></ins>
                    </div>
                    } */}
                <Button
                    block
                    bsSize="large"
                    type="submit"
                    className="fa-button second link-to-source"
                    onClick={this.redirectTo}
                >
                    Go To Site
                </Button>
                <div onClick={this.redirectTo} style={{ cursor: 'pointer' }}>
                <a href={url} target="_blank" style={{ display: 'block', position: 'relative', zIndex: 1000, width: (document.body.offsetWidth || document.documentElement.offsetWidth) > 500 ? '100%' : '100vw' }} onClick={this.redirectTo} ref={ref => { this.contentContainer = ref}}>
                    <iframe width='100%' style={{ height: '200vh', background: '#fff' }} src={preparedS3URL} onClick={this.redirectTo} allowfullscreen></iframe>
                    {/*<iframe width='100%' style={{ height: '100vh' }} src={'https://s3.amazonaws.com/flashalert/test.html'} onClick={this.redirectTo}></iframe>*/}
                    {/*<div id="iframeBlocker"></div>*/}
                </a>
                {/* <div className='welcome-block-ads-full-bottom'>
                    <ins className="adsbygoogle"
                        style={{display: 'block'}}
                        data-ad-client="ca-pub-8914880925442580"
                        data-ad-slot="4133121552"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                </div> */}

                </div>
            </div>
        );
    }
}
