/**
 * Created by alexanderkulyk on 3/16/19.
 */

import { put, call, takeEvery, select } from 'redux-saga/effects';

import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    CANCEL_STRIPE_SUBSCRIPTION,
    HANDLE_USER_ATTRS
} from './actions';

import {
    HANDLE_UPDATE
} from '../alerts/actions';

import {
    CLEAR_USER_ID
} from '../alerts/actions';

import { fetchUser } from '../../../utils/user';
import { addAuthTokenToRequest, cancelStripeSubscriptionRequest, handleUserRequest } from '../../../utils/api';
import { userSelector } from '../../../selectors/user';

export function* watchGetUser() {
    yield takeEvery(GET_USER, getUser);
}

export function* watchHandleUserAttrs() {
    yield takeEvery(HANDLE_USER_ATTRS, handleUserAttrs)
}

export function* watchcancelStripeSubscription() {
    yield takeEvery(CANCEL_STRIPE_SUBSCRIPTION, cancelStripeSubscriptionHandler);
}


export function* handleUserAttrs(action) {
   const data = action.payload;
   try {
    const response = yield call(handleUserRequest, data);
    } catch(e) {
        console.log('test e', e);
    }
}

export function* cancelStripeSubscriptionHandler(action) {
    const type = action.payload;
    const user = yield select(userSelector);
    const data = {
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name
    };
    if (type) {
        data['type'] = type;
    }
    
    try {
        const response = yield call(cancelStripeSubscriptionRequest, data);
    } catch(e) {
        console.log('test e', e);
    }
    yield put({
        type: HANDLE_UPDATE
    })
}

export function* getUser() {
    try {
        let data = {};
        const localUser = localStorage.getItem('user');
        yield put({
            type: CLEAR_USER_ID
        });
        if (localUser) {
            data = { ...JSON.parse(localUser)};
        } else {
            const token = yield call(addAuthTokenToRequest);
            const CognitoUser = yield call(fetchUser);
            const { email, given_name, family_name, address, phone_number } = CognitoUser.attributes;

            data = {
                token, email, given_name, family_name, address, phone_number
            };

            localStorage.setItem('user', JSON.stringify(data));
        }

        yield put({
            type: GET_USER_SUCCESS,
            payload: data
        });

    } catch (error) {
        localStorage.removeItem('user');
        yield put({
            type: GET_USER_FAIL
        });
    }
}
