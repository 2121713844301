/**
 * Created by alexanderkulyk on 3/11/19.
 */
import { createAction } from 'redux-actions';

export const GET_USER = 'user:get';
export const GET_USER_TOKEN = 'user:token:get';
export const GET_USER_SUCCESS = 'user:get:success';
export const GET_USER_FAIL = 'user:get:fail';
export const CLEAR_USER = 'user:set:clear';
// export const UPDATE_USER = 'user:set';
export const SET_USER_PROP = 'user:set:prop';
export const SET_USER_PROPS = 'user:set:props';
export const HANDLE_USER_ATTRS = 'user:handle:attrs'

export const CANCEL_STRIPE_SUBSCRIPTION = 'user:cancel:stripe:subscription';

export const cancelStripeSubscription = createAction(CANCEL_STRIPE_SUBSCRIPTION, type => type);

export const setUserProp = createAction(SET_USER_PROP, (prop, value) => ({ prop, value }));
export const setUserProps = createAction(SET_USER_PROPS, props => props);
export const getUser = createAction(GET_USER);
export const getUserToken = createAction(GET_USER_TOKEN);
export const clearUser = createAction(CLEAR_USER);

export const handleUserAttrs = createAction(HANDLE_USER_ATTRS, attrs => attrs);
// export const updateUser = createAction()

