/**
 * Created by alexanderkulyk on 3/29/19.
 */
import React, { Component } from "react";
import "./Tooltip.css";
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

export default class Tooltip extends Component {
    render() {
        const { title, message, spanText, customClass='' } = this.props;
        return (
            <RCTooltip
                onVisibleChange={this.handleVisibility}
                placement="bottom"
                overlay={
                    <div className={`tooltip-body ${customClass}`}>
                        <div className="tooltip-title">{title}</div>
                        <div className="tooltip-message">{message}</div>
                    </div>}
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
                <span>{spanText}</span>
            </RCTooltip>
        );
    }
}