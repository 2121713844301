import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';
import Routes from './Routes';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';

import Footer from './containers/Footer/FooterContainer';
import Header from './containers/Header';
import MessagePopup from './containers/MessagePopup';
import QuestionPopup from './containers/QuestionPopup';
import PaymentPopup from './containers/PaymentPopup';
import CookieForm from './containers/CookieForm';

import { connect } from 'react-redux';
import { userSelector } from './selectors/user';
import { getUser, setUserProp } from './store/actions';

class App extends Component {
    constructor(props) {
        super(props);

        const queryParams = this.getQueryParams(window.location.search);

        const { isAlexaAccountLinking, linkingQueryValidationSet } = this.checkingOnAlexaAccountLinking(queryParams);
        const { isShortLinkHandling, shortLinkingValidationSet } = this.checkingOnShortLinking(queryParams);

        this.state = {
            isAlexaAccountLinking,
            isShortLinkHandling,
            linkingQueryValidationSet,
            shortLinkingValidationSet
        };
    }

    checkingOnAlexaAccountLinking = queryParams => {
        // check if user going from Alexa for skill authorize
        let isAlexaAccountLinking = true;

        // alexa query params for cognito auth
        const linkingQueryValidationSet = {
            response_type: queryParams.response_type,
            client_id: queryParams.client_id,
            redirect_uri: queryParams.redirect_uri,
            state: queryParams.state
        };

        // all auth cognito params required if it's not present so user going not from alexa skill
        for (let param in linkingQueryValidationSet) {
            if (!linkingQueryValidationSet[param]) {
                isAlexaAccountLinking = false;
                break;
            }
        }

        return {
            isAlexaAccountLinking,
            linkingQueryValidationSet
        }

    };

    resetShortLinking = () => {
        this.setState({
            resetShortLinking: true
        });
    };

    checkingOnShortLinking = queryParams => {
        let isShortLinkHandling = true;

        const shortLinkingValidationSet = {
            symbol: queryParams.symbol,
            source: queryParams.source,
            referer: queryParams.referer,
            url: queryParams.url,
            s3: queryParams.s3
        };

        for (let param in shortLinkingValidationSet) {
            if (!shortLinkingValidationSet[param]) {
                isShortLinkHandling = false;
                break;
            }
        }

        return {
            isShortLinkHandling,
            shortLinkingValidationSet
        }

    };

    getQueryParams = rawQueryParams => {
        let params = {};
        // rawQueryParams.substr(1) - remove "?" sign, then split by "&" to find all key=value pairs
        rawQueryParams.substr(1).split("&").forEach(pair => {
            if (pair === "") return;
            // split pair to two parts - key and value
            const parts = pair.split("=");
            const key = parts.shift();
            const value = parts.join('=');
            // add key and value to the object params
            params[key] = value && decodeURIComponent(value.replace(/\+/g, " "));
        });
        return params;
    };

    componentDidMount() {
        const { getUser } = this.props;
        getUser();
    }

    render() {
        const { history, user, setUserProp } = this.props;
        const { isAuthenticated, isLoaded } = user;
        const childProps = {
            history,
            isAuthenticated
        };
        const {
            isAlexaAccountLinking,
            linkingQueryValidationSet,
            isShortLinkHandling,
            shortLinkingValidationSet,
            resetShortLinking
        } = this.state;
        const { response_type, client_id, redirect_uri, state } = linkingQueryValidationSet;

        if (isAlexaAccountLinking) {
            childProps['cognitoAuthURL'] = `https://flashalert-me.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}`;
        }

        if (isShortLinkHandling && !resetShortLinking) {
            childProps['resetShortLinking'] = this.resetShortLinking;
            childProps['shortLinkTo'] = { url: shortLinkingValidationSet.url, s3: shortLinkingValidationSet.s3 }
        }

        return (
            <div className="App container">
                {
                    isLoaded &&
                        <Grid fluid={true}>
                            <MessagePopup />
                            <QuestionPopup />
                            <PaymentPopup />
                            <Header {...childProps} setUserProp={setUserProp}/>
                            <Row>
                                <Routes childProps={childProps}/>
                            </Row>
                            <Row>
                                <Footer />
                            </Row>
                        </Grid>
                }
                {isLoaded && !childProps['shortLinkTo'] && <CookieForm />}
            </div>
        );
    }
}

const mapDispatchToProps = {
    getUser,
    setUserProp
};

const mapStateToProps = state => ({ user: userSelector(state) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
