import React, { Component } from "react";
import {
    ListGroup, ListGroupItem, Media, PageHeader
} from "react-bootstrap";
import { DataSetsProduct } from '../config';
import "./Features.css";

export default class Products extends Component {
    render() {
        return (
            <div className="page-container">
                <div className="grey-block page-media products-media">
                    <PageHeader className="centered-title">
                        Products
                    </PageHeader>
                    <Media>
                        <Media.Body>
                            <p>
                                FlashAlert is your personal low-latency service for latest SEC filings,
                                PR News releases, upgrades and downgrades covering about 13,000 publicly traded U.S. companies.
                                If your company trades on NASDAQ, NYSE, AMEX, OTC or Pink Sheets – we got you covered
                                around the clock!
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                Sources: SEC, Business wire, PR Newswire, Globe Newswire, Accesswire, and others with thousands news articles coming daily.
                            </p>
                        </Media.Body>
                    </Media>
                    <div className="flex-container">
                    <Media className='products-flex-media'>
                        <Media.Body className='media-body-top'>
                        <Media.Heading>Email Alerts</Media.Heading>
                            <p>
                                Receive email alerts about any stocks in your portfolio from Press Releases, SEC filings, and other sources.
                            </p>
                            <p>&nbsp;</p>
                            <p>Set up a portfolio of stocks you’d like to follow.</p>
                            <p>&nbsp;</p>
                            <p>Delivery speed is only limited by your email delivery as we query data every 2-3 seconds.</p>
                            <p>&nbsp;</p>
                            <p>Prices start at $9.99 a month with 30 days free trial.</p>
                             
                        </Media.Body>
                        <Media.Body className='media-body-bottom'>
                                <Media.Heading>What you need to do:</Media.Heading>
                                <ul className="products-list">
                                    <li><p><a href='/alerts' target='_blank'>Create</a> a user account;</p></li>
                                    <li><p>Select stocks you’re interested in;</p></li>
                                    <li><p>Select what type of news you’d like to receive.</p></li>
                                </ul>
                            </Media.Body>
                    </Media>
                    
                    <Media className='products-flex-media'>
                        <Media.Body className='media-body-top'>
                            <Media.Heading>Streaming News Feed</Media.Heading>
                                <p>
                                    Receive <a href='/data/Flash Alert - product guide - Streaming Structured News.pdf' target='_blank'>low-latency news</a> in an enriched JSON format,
                                    fully parsed, sorted by stock, keywords, and fully classified by news type. 
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>We scrape
                                    data directly from the sources with only 2-3 second latency, well ahead of Bloomberg,
                                    Refinitiv, or Capital IQ.</p>
                                    <p>&nbsp;</p>
                                    <p>You will not get your ingestible news so fast from any other
                                    source, Priced at $495 a month with 7 days free trial.
                                    </p>
                        </Media.Body>
                        <Media.Body className='media-body-bottom'>
                                    <Media.Heading>What you need to do:</Media.Heading>
                                    <ul className="products-list">
                                        <li><p><a href='/alerts' target='_blank'>Create</a> a user account;</p></li>
                                        <li><p>Review Python or Node.js samples to be set up for streaming (or use your own library);</p></li>
                                        <li><p>Start receiving streaming news.</p></li>
                                    </ul>
                            
                            </Media.Body>
                    </Media>
                    

                    <Media className='products-flex-media'>
                        <Media.Body className='media-body-top'>
                            <Media.Heading>Data Sets (Coming soon)</Media.Heading>      
                            <p>
                                Receive multi-year data sets in JSON or CSV format of PR and SEC releases, fully
                                parsed, enriched with prices and reference data, and classified by source and form
                                type. </p>
                                <p>&nbsp;</p>
                                <p>Some of these data is not commercially available from other sources at any price.
                                The data is provided via AWS Data Exchange.
                                {/* <a href={DataSetsProduct} target='_blank'>Data Exchange</a>. */}
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                Priced at $4,950 for one year. Free
                                trial with limited data is available.
                            </p>
                        </Media.Body>
                        <Media.Body className='media-body-bottom'>
                        <Media.Heading>What you need to do:</Media.Heading>
                            {/* <p className="products-list-header">
                                What you need to do:
                            </p> */}
                            <ul className="products-list">
                                {/* <li><p><a href={DataSetsProduct} target='_blank'>Login</a> into your AWS or create new account;</p></li> */}
                                <li><p>Login into your AWS or create new account;</p></li>
                                <li><p>Follow subscription directions on AWS;</p></li>
                                <li><p>Download data sets.</p></li>
                            </ul>
                            
                        </Media.Body>
                    </Media>
                    </div>
                </div>
            </div>
        );
    }
}