/**
 * Created by alexanderkulyk on 3/26/19.
 */

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./QuestionPopup.css";

export default class QuestionPopup extends Component {
    onClick = e => {
        const { hideQuestionPopup } = this.props;
        hideQuestionPopup(e.target.name || e.target.getAttribute('name'));
    };

    render() {
        const { status, message, callbacks,  className_container=''} = this.props.questionPopup;
        const [yesLabel, noLabel] = Object.keys(callbacks);
        return(
            <div className={(status ? "popup-container popup-active" : "popup-container") + ' ' + className_container}>
                <div className="popup-close-button" type="button" name={noLabel} onClick={this.onClick}>x</div>
                <div className="popup-body">
                    <div className="popup-message question-popup-message" dangerouslySetInnerHTML={{ __html: message}} />
                    <div className="popup-buttons-block">
                        <Button className='answer-yes-button' onClick={this.onClick} name={yesLabel}>{yesLabel}</Button>
                        <Button className='answer-no-button' onClick={this.onClick} name={noLabel}>{noLabel}</Button>
                    </div>
                </div>
            </div>
        )
    }
}