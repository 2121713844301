import React, { Component } from 'react';
import './Alerts.css';
import './toggleButton.css';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import LoaderButton from '../../components/LoaderButton';
import FlashButton from '../../components/FlashButton';
import { FormGroup } from 'react-bootstrap';
import Toggle from 'react-toggle'
import MaterialUICheckbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import "@material/react-checkbox/dist/checkbox.css";
import UnselectAlertsButton from '../../components/UnselectAlertsButton';
import { P_STREAM_SCHEME, B_STREAM_SCHEME, ITEMS_SCHEME, premiumFeatures, plans, isAllExclusion, prepareAlerts, compareByFirstSymbol } from '../../utils/alerts';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { getCookie, eraseCookie } from '../../utils/common';
import { DataSetsProduct, DataSetsSampleProduct } from '../../config';

const classes = {
    root: {
        color: '#018786',
        '&$checked': {
            color: '#018786',
        },
    },
    checked: {},
};

const isMobile = () => window.innerWidth <= 800;

const Checkbox = props => {
    return (
        props.tooltip ? <Tooltip title={props.tooltip} placement="top" classes={{root: 'fa-ui-checkbox-tooltip', tooltip: 'fa-ui-checkbox-tooltip', popper: 'fa-ui-checkbox-tooltip'}}>
            <div className={`checkbox-container ${props.disabled ? 'disabled' : ''}`}>
                
                    <MaterialUICheckbox { ...props } classes={{
                        root: 'fa-ui-checkbox',
                        colorSecondary: 'fa-ui-checkbox-color'
                    }} />
                
                <label className="checkbox-label">{props.children}</label>
            </div>
        </Tooltip> : 
        <div className={`checkbox-container ${props.disabled ? 'disabled' : ''}`}>

                <MaterialUICheckbox { ...props } classes={{
                    root: 'fa-ui-checkbox',
                    colorSecondary: 'fa-ui-checkbox-color'
                }} />
            
            <label className="checkbox-label">{props.children}</label>
        </div>
    )
}

const StreamCheckBoxItem = props => {
    const { name, value, onChange, label, className, disabled, tooltip } = props;
    return (
        <div className={`checkbox-block-container ${disabled ? 'disabled' : ''}`} key={name}>
            <div
                className={className}
            >{label}
            </div>
            <div className="via-checkbox-block">
                <Checkbox
                    name={name}
                    checked={value}
                    onChange={onChange}
                    disabled={disabled}
                    tooltip={tooltip}
                    inline="true"></Checkbox>
            </div>
        </div>
    )

}


const CheckboxItem = props => {
    const { attr, onChange, label, className, infoNode, onlyEmail, phoneDisabled, phoneTooltip } = props;
    const attrKeys = Object.keys(attr);
    let is_email, email_name, is_phone, phone_name;
    for (let key of attrKeys) {
        if (key.includes('_email')) {
            is_email = attr[key];
            email_name = key;
        } else {
            is_phone = attr[key];
            phone_name = key;
        }
    }
    return (
        <div className="checkbox-block-container">
            <div
                className={className}
            >{label}
                {infoNode && infoNode}
            </div>
            <div className="via-checkbox-block">
                <Checkbox
                    key={email_name}
                    name={email_name}
                    checked={is_email}
                    onChange={onChange}
                    inline="true"></Checkbox>
                {/* {!onlyEmail ? <Checkbox
                    key={phone_name}
                    name={phone_name}
                    checked={is_phone}
                    onChange={onChange}
                    disabled={phoneDisabled}
                    tooltip={phoneTooltip}
                    inline="true">SMS</Checkbox> :
                    <div className="empty-check-div"></div>
                } */}
            </div>
        </div>
    );
};

const MultiValueLabel = props => {
    return (
        <components.MultiValueLabel {...props}>
            {props.data.value}
        </components.MultiValueLabel>
    );
};

export default class Alerts extends Component {

    constructor(props) {
        super(props);
        const { history } = this.props;
        let service;
        if (history.location.pathname.includes('alerts')) {
            service = 'Alerts';
        } else if (history.location.pathname.includes('streaming_news')) {
            service = 'Streaming';
        } else if (history.location.pathname.includes('data_sets')) {
            service = 'Data Sets';
        }
        
        this.state = {
            service, 
        }
    }
    
    updateSubject = e => {
        const { setUserProps } = this.props;
        const { service } = this.state;
        setUserProps({subject: service.includes('Streaming') ? 'Premium WebSockets' : 'Basic WebSockets'});
    }


    handleCheckedStreams = e => {
        const { updateStreamsState } = this.props;
        updateStreamsState(e.target.name, e.target.checked);
    }

    async componentDidMount() {
        const { getSubscriptions, getUser } = this.props;
        getSubscriptions();
        getUser();
    }

    handleSubscribeStreams = event => {
        const { 
            user, cancelStripeSubscription, showQuestionPopup,
            setAlertsLoading, handleUpdate, showPaymentPopup, handleUpdateStream
        } = this.props;
        const { service } = this.state;

        const currentStream = 'premium';

        const targetText = event.target.innerText;

        const isPaidPremiumStream = user.premiumQueueType === 'premium';
        const isFreePremiumStream = user.premiumQueueType === 'premium_free';
        const isFreePremiumStreamExpaired = user.premiumFreeTrialDaysLeft <= 0;
        let command_body;
        if (targetText === 'Unsubscribe') {
            if (isPaidPremiumStream || isFreePremiumStream) {
                showQuestionPopup(
                    `<h3>You are about to cancel Streaming Structured News subscription</h3>
                        </br>
                       `,
                    {
                        'Proceed': () => {
                            setAlertsLoading(true);
                            cancelStripeSubscription(currentStream);
                            handleUpdate();
                        },
                        'Exit': () => {}
                    }
                )
            } 
            // else if (isFreePremiumStream) {
            //     command_body = {
            //         'action': 'unsubscribe',
            //         'subaction': ''
            //     }
            //     showQuestionPopup(
            //         `<h3>You are about to cancel Streaming Structured News free subscription</h3>
            //             </br>
            //            `,
            //         {
            //             'Proceed': () => {
            //                 setAlertsLoading(true);
            //                 handleUpdateStream(command_body);
            //                 setAlertsLoading(true);
            //                 handleUpdate();
            //             },
            //             'Exit': () => {}
            //         }
            //     )
            // }

        } else {
            showPaymentPopup(
                // `${currentStream[0].toUpperCase() + currentStream.slice(1)} Real-Time Streaming`,
                'Streaming Structured News',
                service.includes('Streaming') ? 495 : 45.99,
                {
                    type: currentStream
                }
            );
            // if (!isPaidPremiumStream && !isFreePremiumStream) {
            //     command_body = {
            //         'action': 'subscribe',
            //         'subaction': user.premiumFreeTrialStarted ? '' : 'new'
            //     }
            //     setAlertsLoading(true);
            //     handleUpdateStream(command_body);
            //     setAlertsLoading(true);
            //     handleUpdate();
            // } else {
                
            // }
        }
        
    }

    handleUnsubscribe = event => {
        const {
            user,
            showQuestionPopup,
            handleUpdate,
            setAlertsLoading,
            cancelStripeSubscription,
            alerts,
            handleAlertsStateUpdate,
            setUserProps
        } = this.props;
        const yesButtonName = user.plan.type === 'free' ? 'Unsubscribe' : 'Switch';
        showQuestionPopup(
            user.plan.type !== 'free' ? 
            `<h3>You are about to switch to free subscription</h3>
                </br>
                <ul style="margin-bottom:30px;text-align:left;">
                    <li><p>the number of symbols will be limited to ${plans.free.symbolsAmount}</p></li>
                    <li><span>you will not be able to edit your choices, but can unsubscribe any time</span></li>
                </ul>` :  
            `<h3>You are about to unsubscribe from all channel</h3> 
                </br>
                <ul style="margin-bottom:30px;text-align:left;">
                    <li><p>you will no longer receive any email</p></li>
                    
                    <li><span>you can login and resubscribe at a later date</span></li>
                </ul>`,
            {
                [yesButtonName]: () => {
                    setAlertsLoading(true);
                    if (user.plan.type !== 'free') {
                        // const phones = Object.keys(alerts).filter(
                        //     item => item.includes('phone') && !Object.keys(isAllExclusion).filter(
                        //         exc => item.includes(exc)
                        //     ).length && alerts[item] && !premiumFeatures.includes(item)
                        // );
                        const stocks = [];
                        const allChecked = Object.keys(alerts).filter(key => (key.includes('is_') || key.includes('item') || key.startsWith('all_')) && alerts[key] === true);
                        const alertsOnRemove = {};
                        // for (let phoneSrc of Object.keys(isAllExclusion)) {
                        //     alertsOnRemove[phoneSrc + '_phone'] = false;
                        // }
                        for (let checked of allChecked) {
                            alertsOnRemove[checked] = false;
                        }
                        // while (phones.length > plans.free.smsAmount) {
                        //     const phone = phones.pop();
                        //     alertsOnRemove[phone] = false;
                        // }
                        // const stocks = [...alerts.stocks];
                        // while (stocks.length > plans.free.symbolsAmount) {
                        //     stocks.pop();
                        // }
                        alertsOnRemove.stocks = stocks;

                        for (let feature of premiumFeatures) {
                            alertsOnRemove[feature] = false;
                        }
                        cancelStripeSubscription();
                        handleAlertsStateUpdate(alertsOnRemove);
                        setUserProps({
                            isGoingFree: false,
                            isGoingPay: !user.freePeriod,
                            plan: {type: 'free', additionalSymbols: 0, nextPlan: {type: '', additionalSymbols: 0}}
                        });
                        

                    } else {
                        const stocks = [];
                        const allChecked = Object.keys(alerts).filter(key => (key.includes('is_') || key.includes('item') || key.startsWith('all_')) && alerts[key] === true);
                        const alertsOnRemove = {};
                        for (let checked of allChecked) {
                            alertsOnRemove[checked] = false;
                        }
                        alertsOnRemove.stocks = stocks;
                        handleAlertsStateUpdate(alertsOnRemove);
                        
                        setUserProps({
                            isGoingFree: false,
                            isGoingPay: !user.freePeriod,
                            plan: {type: 'free', additionalSymbols: 0, nextPlan: {type: '', additionalSymbols: 0}}
                        });
                        handleUpdate();
                    }
                },
                'Cancel': () => {}
            }

        )
    }

    handleSubmit = event => {
        const {
            setAlertsLoading, handleUpdate, user, showPaymentPopup, setUserProps,
            showQuestionPopup, handleStopAllSubscriptions, cancelStripeSubscription,
            handleUpdateStream, handleStripePayment, alerts
        } = this.props;
        const { service } = this.state;
        event.preventDefault();
        if (service.includes('Stream')) {
            return handleUpdateStream(service);
        }
        let isUserFreeExpires = !user.freePeriod && (user.plan.type == 'free' && !user.plan.nextPlan.type);
        if (isUserFreeExpires) {
            setUserProps({plan: {...user.plan, nextPlan: {type: 'basic', additionalSymbols: 0}}});
            user.plan.nextPlan.type = 'basic';
        }
        const payAmount = ((plans[user.plan.nextPlan.type] || plans[user.plan.type]).cost + ((user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols) / plans['additionalSymbols']['amount']) * plans['additionalSymbols']['cost']) / 100;
        const paySymbolsAmount = plans[user.plan.nextPlan.type || user.plan.type].symbolsAmount + user.plan.nextPlan.additionalSymbols + user.plan.additionalSymbols;
        isUserFreeExpires = !user.freePeriod && (user.plan.type == 'free' && !user.plan.nextPlan.type);

        if (user.isGoingPay) {
            const preparedAlerts = prepareAlerts(alerts, user);
            if (preparedAlerts.message) {
                return handleUpdate()
            }
            showPaymentPopup(
                `Paid subscription will allow you up to ${paySymbolsAmount} symbols.`,
                payAmount
            );
        } else if (user.plan.nextPlan.type === 'free' && user.freePeriod) {
            const preparedAlerts = prepareAlerts(alerts, user);
            if (preparedAlerts.message) {
                return handleUpdate()
            }
            showQuestionPopup(
                "You are switching to a free subscription and won't be charged for next month unused parts of the " +
                "current monthly fee won't be refunded.",
                {
                    'Ok': () => {
                        setAlertsLoading(true);
                        handleUpdate();
                        setUserProps({
                            isGoingFree: false, plan: {type: 'free', additionalSymbols: 0, nextPlan: {type: '', additionalSymbols: 0}}
                        });
                        cancelStripeSubscription();
                    },
                    'Cancel': () => {
                        setUserProps({
                            isGoingFree: false, plan: {type: user.plan.type, additionalSymbols: user.plan.additionalSymbols, nextPlan: {type: user.plan.nextPlan.type, additionalSymbols: user.plan.nextPlan.additionalSymbols}}
                        });
                        handleStopAllSubscriptions();
                    }
                }

            );
        } else if (user.plan.nextPlan.type && (plans[user.plan.nextPlan.type].order < plans[user.plan.type].order || user.plan.nextPlan.additionalSymbols)) {
            const preparedAlerts = prepareAlerts(alerts, user);
            if (preparedAlerts.message) {
                return handleUpdate()
            }
            showQuestionPopup(
                `You are switching to $${payAmount} monthly payment subscription`,
                {
                    'Ok': () => {
                        const paymentPlan = this.props.user.plan.nextPlan.type;

                        const stripePayload = {
                            token: null,
                            email: this.props.user.email,
                            phone: this.props.user.phone_number,
                            firstname: this.props.user.given_name,
                            lastname: this.props.user.family_name,
                            zip: this.props.user.address,
                            paymentPlan
                        };
                        stripePayload.additionalSymbols = this.props.user.plan.additionalSymbols + this.props.user.plan.nextPlan.additionalSymbols;
                        handleStripePayment(stripePayload);
                        setAlertsLoading(true);
                        setUserProps({
                            isGoingFree: false, plan: {type: user.plan.nextPlan.type, additionalSymbols: user.plan.additionalSymbols, nextPlan: {type: '', additionalSymbols: 0}}
                        });
                    },
                    'Cancel': () => {
                        setUserProps({
                            isGoingFree: false, plan: {type: user.plan.type, additionalSymbols: user.plan.additionalSymbols, nextPlan: {type: '', additionalSymbols: 0}}
                        });
                        handleStopAllSubscriptions();
                    }
                }

            );
        } else {
            setAlertsLoading(true);
            handleUpdate();
        }

    };

    validateForm = () => {
        const {
            stocks, isDelete, isResetAllSubscriptions,
            item_1_03_email, item_1_03_phone, item_2_02_email, item_2_02_phone, item_2_04_email, item_2_04_phone,
            item_2_06_email, item_2_06_phone, item_4_02_email, item_4_02_phone, all_13_email, all_13_phone
        } = this.props.alerts;
        const sources = Object.keys(this.props.alerts).filter(
            src => src.startsWith('is_') && src !== 'is_dnd' && this.props.alerts[src]
        );

        const isItems = (item_1_03_email || item_1_03_phone)
            || (item_2_02_email || item_2_02_phone)
            || (item_2_04_email || item_2_04_phone)
            || (item_2_06_email || item_2_06_phone)
            || (item_4_02_email || item_4_02_phone)
            || (all_13_email || all_13_phone);
        return  ((stocks.length && sources.length) || isItems || isDelete || isResetAllSubscriptions) > 0;
    };

    handleChange = value => {
        const { handleChange, showPopupMessage, alerts } = this.props;
        const { stocks, symbolType } = alerts;
        const preparedStocks = value;
        handleChange(preparedStocks);
    };

    handleChangeSymbolType = e => {
        const { handleChangeAlertsState } = this.props;
        e.preventDefault();
        handleChangeAlertsState(
            'symbolType',
            e.target.name || e.target.parentNode.name || e.target.parentNode.parentNode.name
        );
    };

    handleChangeService = e => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        const { history } = this.props;
        let href;
        if (e.target.textContent.includes('Alerts')) {
            href = '/alerts';
        } else if (e.target.textContent.includes('Streaming Structured News')) {
            href = '/streaming_news';
        } else if (e.target.textContent.includes('Data Sets')) {
            href = '/data_sets';
        };
        history.push(href);

        
        // let textContext = e.target.textContent;
        // if (e.target && e.target.textContent == 'Alerts') {
        //     textContext = 'Subscriptions'
        // }
        this.setState({
            "service": e.target.textContent
        })
    }

    render() {
        const { service } = this.state;
        const { alerts, user, handleChecked, history } = this.props;
        const smsDisabled = user.phone_number && !user.phone_number.startsWith('+1');
        const smsTooltip = smsDisabled ? 'Available only for US and Canada' : null;
        const {
            id, isClosed, isDisabled, isLoading, isCreateAlert, isResetAllSubscriptions, symbolType, stocks,

            is_all_sec_email, is_sec_10_email, 
            is_sec_4_email, is_sec_8_email, is_sec_ot_email,
            is_sec_13_email,
            is_all_sa_email, is_sa_tr_email, is_sa_ls_email, 
            is_sa_cr_email, is_all_pr_email,  is_pr_bw_email, 
            is_pr_aw_email,
            is_pr_glob_email, is_pr_nw_email, is_all_rt_email,
            is_all_twtr_email,
            is_pr_earn_email,

            is_all_cr_email, is_cr_cd_email, is_cr_ct_email,
            is_cr_nb_email, is_cr_bc_email,
        } = alerts;
        const afterSignUpActionCookies = getCookie('afterSignUpAction');
        const filteredStocks = (stocks && stocks.sort(compareByFirstSymbol) || []);

        const isCrypto = symbolType === 'crypto';
        const isETF = symbolType === 'etf';
        const isTweets = symbolType === 'tweet';
        const isEmptyOptions = isTweets || isCrypto;
        const { P_STREAM, B_STREAM, freePeriod, premiumQueueType, premiumFreeTrialDaysLeft, premiumFreeTrialStarted } = user;
        const STREAM = P_STREAM || B_STREAM;

        const { handleStopAllSubscriptions, getSymbolsByTicker } = this.props;
        const payAmount = ((plans[user.plan.nextPlan.type] || plans[user.plan.type]).cost + ((user.plan.additionalSymbols + user.plan.nextPlan.additionalSymbols) / plans['additionalSymbols']['amount']) * plans['additionalSymbols']['cost']) / 100;

        const phoneAmount = Object.keys(alerts).filter(
            item => item.includes('phone') && !Object.keys(isAllExclusion).filter(
                exc => item.includes(exc)
            ).length && alerts[item]
        ).length;

        const subscriptionDetails = `${stocks.length} symbols`; // and ${phoneAmount} SMS channels`;

        const premiumStreamAPIUrl = 'https://brre7onmqh.execute-api.us-east-1.amazonaws.com/v1/';
        const additionalTextAlerts = user.paymentStatus == 'FAIL' ? " - Last payment failed" : freePeriod ? ` - ${freePeriod} days left` : ' expired';
        const additionalTextAlertsButton = user.paymentStatus == 'FAIL' ? " - Last payment failed" : freePeriod ? ` - ${freePeriod} days left` : ' subscription expired';
        
        const isPaidPremiumStream = user.premiumQueueType === 'premium';
        const isFreePremiumStream = user.premiumQueueType === 'premium_free';
        const isPaidBasicStream = user.basicQueueType === 'basic';
        
        const additionalTextPremiumStreamAlerts = isPaidPremiumStream ? ' (real-time, $495.00 a month)': ' (free, 30 sec delay)';
        const additionalTextBasicStreamAlerts = isPaidBasicStream ? ' (real-time, $45.99 a month)' : ' (free, 30 sec delay)';

        // let preiumStreamDetailsText = '';
        // if (premiumFreeTrialStarted && !premiumFreeTrialDaysLeft && premiumQueueType != 'premium') {
        //     preiumStreamDetailsText = '(495.00$, free subscription expired)'
        // } else if (!premiumFreeTrialStarted) {
        //     preiumStreamDetailsText = `(495.00$, free subscription ${premiumFreeTrialDaysLeft} days)`;
        // } else if (premiumFreeTrialDaysLeft && premiumQueueType != 'premium') {
        //     preiumStreamDetailsText = `(495.00$, free subscription ${premiumFreeTrialDaysLeft} days remaining)`
        // } else if (premiumQueueType == 'premium') {
        //     preiumStreamDetailsText = "Subscribed (495.00$)";
        // } 
        let preiumStreamDetailsText = '';
        if (premiumFreeTrialStarted && !premiumFreeTrialDaysLeft && premiumQueueType != 'premium') {
            preiumStreamDetailsText = 'Expired Subscription to Streaming Structured News ($495.00 a month)'
        } else if (!premiumFreeTrialStarted) {
            preiumStreamDetailsText = `Subscribe to Streaming Structured News ($495.00, free subscription ${premiumFreeTrialDaysLeft} days)`;
        } else if (premiumFreeTrialDaysLeft && premiumQueueType != 'premium') {
            preiumStreamDetailsText =  `Free Streaming Structured News (free subscription ${premiumFreeTrialDaysLeft} days left, $495.00 a month after)`;
        } else if (premiumQueueType == 'premium') {
            preiumStreamDetailsText = "Paid Streaming Structured News ($495.00 a month)";
        } 
            
        const switchButtonTextPremiumStreamAlerts = isPaidPremiumStream ? 'Subscribed (495.00$)': `Subscribe`;

        // if (afterSignUpActionCookies) {
        //     eraseCookie('afterSignUpAction');
        //     this.handleChangeService({'target': {'textContent': afterSignUpActionCookies}});
        //     if (afterSignUpActionCookies === 'Data Sets') {
        //         window.open('', '_blank');
        //     }
        // }

        return (
                <div className="alerts-container">
                        <form onSubmit={this.handleSubmit}>
                            <div className="grey-block">
                                <div className="alerts-grey-block-top-container">
                                    <div className="alerts-top-left-block">
                                        <h1>
                                            {
                                                service.includes('Data Sets') ? 'Data Sets' : !id ? `Update Alerts` : service.includes('Stream') ? preiumStreamDetailsText :
                                                    (user.plan.nextPlan.type === 'free' || (user.plan.type === 'free' && !user.plan.nextPlan.type)) ? (stocks.length ? `Update Alerts (Free Subscription${additionalTextAlerts})` : `Subscribe to Alerts (Free Subscription${additionalTextAlerts})`) :
                                                    user.plan.nextPlan.type !== 'free' ? `Update Alerts ($${payAmount} a month)` :
                                                    user.plan.type === 'free' ?
                                                        `Update Alerts (Free subscription${additionalTextAlerts})` :
                                                        `Update Alerts ($${payAmount} a month subscription)`
                                            }
                                        </h1>
                                        <h1 className='alerts-subs-details'>
                                        {freePeriod && !service.includes('Stream') && !service.includes('Data Sets') && <p>30-day free trial, starting at $9.99 a month afterwards.</p>}
                                            {id && !service.includes('Stream') && !service.includes('Data Sets') && subscriptionDetails}
                                            
                                            {service.includes('Stream') && <p>7-day free trial, $495 a month afterwards.</p>}
                                            {/* {service.includes('Data Sets') && <p>Please visit Amazon link for a <a href={DataSetsSampleProduct} target="_blank">free trial</a> and  <a href={DataSetsProduct} target="_blank">subscription</a>.</p>} */}
                                            {service.includes('Data Sets') && <p>free trial and subscription are coming soon.</p>}
                                        </h1>
                                    </div>
                                    <div className="alerts-top-right-block">
                                        {/* {!service.includes('Stream') && <FormGroup controlId="dnd">
                                            <div className="sms-info">
                                                <h3>Day Hours Only</h3>
                                                <span><i />Get SMS from 9 AM to 9 PM New York Time</span>
                                            </div>
                                            <Toggle
                                                checked={id ? is_dnd : false}
                                                onChange={handleChecked}
                                                name="is_dnd"
                                            />
                                        </FormGroup>} */}
                                        {!service.includes('Stream') && !service.includes('Data Sets') && <FlashButton
                                                block
                                                name='unsubscribe'
                                                disabled={!stocks.length && !Object.keys(alerts).filter(key => key.includes('item') && alerts[key]).length}
                                                className="unsubscribe-button"
                                                isLoading={isLoading || !id}
                                                text={
                                                    user.plan.type === 'free' ? "Unsubscribe" : "Switch to Free"
                                                }
                                                loadingText={!isLoading ? "Loading..." : "Updating..."}
                                                onChange={this.handleUnsubscribe}
                                            />}
                                        {/* {service.includes('Stream') && <FlashButton
                                                block
                                                name='unsubscribe-stream'
                                                className="unsubscribe-button"
                                                text={additionalSwitchButtonTextStreamAlerts}
                                                loadingText={!isLoading ? "Loading..." : "Updating..."}
                                                onChange={this.handleSubscribeStreams}
                                            />} */}
                                    </div>
                                </div>

                                <div className="alerts-grey-block-bottom-container">
                                    {STREAM && <div className="symbols-title-container">
                                        <h4>Services</h4>
                                        
                                        <div className="symbols-checkboxes-container">
                                            <ButtonGroup name="service" orientation={isMobile() ? "vertical" : "horizontal"} color="primary" aria-label="primary button group" className='service-button-group'>
                                                <Button onClick={this.handleChangeService} className={this.state.service.includes('Alerts') ? 'active-button' : '' }>
                                                Alerts
                                                </Button>
                                                
                                                <Button disabled={!P_STREAM} onClick={this.handleChangeService} className={this.state.service.includes('Streaming') ? 'active-button' : '' }>
                                                    {user.premiumQueueType === 'premium' ? "Streaming Structure News" : 'Streaming Structured News'}
                                                </Button>
                                                {B_STREAM && <Button disabled={!B_STREAM} onClick={this.handleChangeService} className={this.state.service.includes('Data Sets') ? 'active-button' : '' }>
                                                    {user.basicQueueType === 'basic' ? "Data Sets" : 'Data Sets'}
                                                </Button>}
                                            </ButtonGroup>
                                        </div>
                                    </div>}
                                    
                                    {(service.includes('Streaming') || service.includes('Data Sets')) &&
                                        <div className='subs-info-block'>
                                            {
                                                service.includes('Streaming') && 
                                                <>
                                                    <p>
                                                        <a href="/data/Flash Alert - product guide - Streaming Structured News.pdf" target ='_blank'>
                                                            Streaming Structured News
                                                        </a> will provide very low latency feeds from all major PR sources, SEC and other sources in structured JSON format via Websocket stream in <a href='/data/example.py' target='_blank'>Python</a> or <a href='/data/example.js' target='_blank'>Node.js</a>.&nbsp;
                                                    </p>

                                                    <div className="grey-block">
                                                        <div className="alerts-button-container button-container-reverse">
                                                            <LoaderButton
                                                                block
                                                                disabled={isPaidPremiumStream || isFreePremiumStream}
                                                                className="alerts-submit-button button-reverse"
                                                                isLoading={isLoading || !id}
                                                                onClick={this.handleSubscribeStreams}
                                                                text={
                                                                    switchButtonTextPremiumStreamAlerts
                                                                }
                                                                loadingText={!isLoading ? "Loading..." : "Updating..."}/>
                                                                <LoaderButton
                                                                    block
                                                                    className={`unselect-alerts-button unselect-alerts-button-unchecked button-reverse`}
                                                                    text='Unsubscribe'
                                                                    disabled={!isPaidPremiumStream && !isFreePremiumStream}
                                                                    onClick={this.handleSubscribeStreams}
                                                                    loadingText={!isLoading ? "Loading..." : "Updating..."}
                                                                />
                                                        </div>
                                                    </div>

                                                    <p>
                                                        We cover the following PR sources:
                                                    </p>
                                                   
                                                    
                                                    <ul>
                                                        <li><a href="https://www.prnewswire.com/" target="_blank">PR Newswire</a></li>
                                                        <li><a href="https://www.businesswire.com/" target="_blank">Businesswire A Berkshire Hathaway Company</a></li>
                                                        <li><a href="https://globenewswire.com/" target="_blank">GlobeNewswire by notified</a></li>
                                                        <li><a href="https://www.accesswire.com/" target="_blank">Accesswire</a></li>
                                                    </ul>
                                                    <p>
                                                        Samples:
                                                        <ul>
                                                            <li>
                                                                <a href="/data/PR.json" target="_blank">JSON</a>
                                                                &nbsp; (
                                                                <a href="https://www.businesswire.com/news/home/20240207556055/en/American-States-Water-Company-Announces-Regular-Common-Dividends" target="_blank">Actual news</a>
                                                                )
                                                            </li>
                                                            <li>
                                                                <a href="/data/PR_earnings.json" target="_blank">JSON Earnings</a>
                                                                &nbsp;(
                                                                    <a href="https://www.globenewswire.com/news-release/2024/02/22/2833288/0/en/Teekay-Tankers-Ltd-Reports-Fourth-Quarter-and-Annual-2023-Results-and-Declares-Dividend.html" target="_blank">Actual news</a>
                                                                )
                                                            </li>
                                                        </ul>
                                                        
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        The following SEC forms are fully parsed:
                                                        <ul>
                                                            <li>
                                                                <a href="/data/3.json" target="_blank">3, 3/A, 4, 4/A, 5, 5/A forms</a>
                                                                &nbsp; (
                                                                <a href="https://www.sec.gov/Archives/edgar/data/1626971/000161071724000049/0001610717-24-000049-index.htm" target="_blank">Actual form</a>
                                                                )
                                                            </li>
                                                            <li>
                                                                <a href="/data/8-K.json" target="_blank">8-K, 8-K/A, 6-K, 6-K/A forms</a>
                                                                &nbsp;(
                                                                    <a href="https://www.sec.gov/Archives/edgar/data/1040130/000104013024000004/pets-20240207.htm" target="_blank">Actual form</a>
                                                                )
                                                            </li>
                                                            <li>
                                                                <a href="/data/10-K.json" target="_blank">10-K, 10-Q, 20-F</a>
                                                                &nbsp;(
                                                                    <a href="https://www.sec.gov/Archives/edgar/data/10456/000162828024003932/0001628280-24-003932-index.htm" target="_blank">Actual form</a>
                                                                )
                                                            </li>
                                                            <li>
                                                                <a href="/data/13F-HR.json" target="_blank">13F-HR, 13F-HR/A, 13F-NT, 13F-NT/A forms</a>
                                                                &nbsp;(
                                                                    <a href="https://www.sec.gov/Archives/edgar/data/1519676/000151967624000002/xslForm13F_X02/infotable.xml" target="_blank">Actual form</a>
                                                                )
                                                            </li>
                                                            <li>
                                                                <a href="/data/13G.json" target="_blank">SC 13D, SC 13G, SC 13D/A, SC 13G/A forms</a>
                                                                &nbsp;(
                                                                    <a href="https://www.sec.gov/Archives/edgar/data/930667/000117266124000548/ewl-123123.htm" target="_blank">Actual form</a>
                                                                )
                                                            </li>
                                                            <li>
                                                                <a href="/data/S-3.json" target="_blank">S-1, F-1, S-3, F-3, S-1/A, S-3/A, F-1/A, F-3/A forms</a>
                                                                &nbsp;(
                                                                    <a href="https://www.sec.gov/Archives/edgar/data/765880/000110465924012371/tm245193-1_s3asr.htm" target="_blank">Actual form</a>
                                                                )
                                                            </li>
                                                        </ul>
                                                    </p>
                                                    <p>&nbsp;</p>
                                                </>
                                            }
                                            {
                                                service.includes('Data Sets') && 
                                                <p>
                                                    Data Sets of PR and SEC news are available in JSON and CSV formats. 
                                                    {/* Please visit our <a href={DataSetsProduct} target ='_blank'>AWS Exchange page</a> to view and subscribe.&nbsp; */}
                                                    {/* <a href="" target ='_blank'>
                                                         Flash Alert Data Sets</a>  are available via AWS Data Exchange.&nbsp; */}
                                                </p>
                                                
                                            }

                                        {/* <a href={service.includes('Streaming') ? "/data/Flash Alert - product guide - FlashAlert Premium Stream.pdf" : "/data/Flash Alert - product guide - FlashAlert Basic Stream.pdf"} target='_blank'>Flash Alert {service.includes('Streaming') ? 'Streaming News' : 'Data Sets'}</a> is provided via WebSockets in JSON format{service.includes('(30 sec delay)') ? ' with 30 second delay': ''}.&nbsp;
                                                {service.includes('(30 sec delay)') && <AnchorLink onClick={this.updateSubject} href='#contact-form'>Email us</AnchorLink>} {service.includes('(30 sec delay)') ? 'for technical support or with any questions.' : ''} */}
                                        <p>
                                            {/* {service.includes('Streaming') && <><AnchorLink onClick={this.updateSubject} href='#contact-form'>Email us</AnchorLink> for subscription pricing and details.</>} */}
                                            {service.includes('Streaming') && "The subscription requires a credit card but can be cancelled for first 7 days without the charge."}
                                            {/* {user.basicQueueType !== 'basic' && service.includes('Basic') ? <>The price for low-latency Basic Stream is $45.99 a month for <a target="_blank" href="/terms#non-prodessional-subscriber">non-professional</a> subscribers or for <a target="_blank" href="/terms#evaluation-purposes">evaluation purposes</a> and limited to one concurrent IP address. If you are professional subscriber, please <AnchorLink onClick={this.updateSubject} href='#contact-form'>contact us</AnchorLink> for enterprise pricing.</>: ''}
                                            {user.basicQueueType === 'basic' && service.includes('Basic') ? <>Basic Stream <a target="_blank" href="/terms#non-prodessional-subscriber">non-professional</a> single IP is $45.99 a month.</> : ''}
                                            {user.premiumQueueType !== 'premium' && service.includes('Streaming') ? <>The price for low-latency Premium Stream is $95.99 a month for <a target="_blank" href="/terms#non-prodessional-subscriber">non-professional</a> subscribers or for <a target="_blank" href="/terms#evaluation-purposes">evaluation purposes</a> and limited to one concurrent IP address. If you are professional subscriber, please <AnchorLink onClick={this.updateSubject} href='#contact-form'>contact us</AnchorLink> for enterprise pricing.</>: ''}
                                            {user.premiumQueueType === 'premium' && service.includes('Streaming') ? <>Premium Stream <a target="_blank" href="/terms#non-prodessional-subscriber">non-professional</a> single IP is $95.99 a month.</> : ''}  */}
                                        </p>

                                        {/* <p>Running it is easy: our end point is <code>{service.includes('Streaming') ? premiumStreamAPIUrl : baseStreamAPIUrl}</code>
                                        You only need to pass header with the email you registered. You can use JavaScript
                                    <code>"wscat -c {service.includes('Streaming') ? premiumStreamAPIUrl : baseStreamAPIUrl} -H User:{user.email}"</code> 
                                        or download a <a href={service.includes('Streaming') ? '/data/connector_premium.py': '/data/connector_basic.py'} download>Python script</a>. {service.includes('(30 sec delay)') ? "You may need to wait up to a minute before you see first data as it's delayed 30 seconds." : ''}</p> */}
                                    </div>}
                                    {/* {!service.includes('Stream') && !service.includes('Data Sets') && <div className="symbols-title-container">
                                        <h4>Symbols</h4>
                                        <div className="symbols-checkboxes-container">
                                            <ButtonGroup name="symbolType" orientation={isMobile() ? "vertical" : "horizontal"} color="primary" aria-label="primary button group" className='service-button-group'>
                                                <Button name='stock' onClick={this.handleChangeSymbolType} className={symbolType === 'stock' ? 'active-button' : '' }>
                                                    Stocks
                                                </Button>
                                                <Button name='etf' onClick={this.handleChangeSymbolType} className={symbolType === 'etf' ? 'active-button' : '' }>
                                                    ETFs
                                                </Button>
                                                <Button name='crypto' onClick={this.handleChangeSymbolType} className={symbolType === 'crypto' ? 'active-button' : '' }>
                                                    Crypto
                                                </Button>
                                                <Button name='otc' onClick={this.handleChangeSymbolType} className={symbolType === 'otc' ? 'active-button' : '' }>
                                                    OTC
                                                </Button>
                                                <Button name='tweet' onClick={this.handleChangeSymbolType} className={symbolType === 'tweet' ? 'active-button' : '' }>
                                                    <p>Tweets <sup>Beta</sup></p>
                                                </Button>
                                            </ButtonGroup>

                                        </div>
                                    </div>} */}
                                    {!service.includes('Stream') && !service.includes('Data Sets') && <AsyncSelect
                                         id="tickerSelect"
                                         closeMenuOnSelect={isClosed}
                                         components={{MultiValueLabel}}
                                         onChange={this.handleChange}
                                         isMulti
                                         getOptionValue ={(option)=>option.value}
                                         loadOptions={getSymbolsByTicker}
                                         placeholder="Start typing stocks in your portfolio, i.e. AMZN, NVDA, MSFT"
                                         isDisabled={isDisabled}
                                         value={id && filteredStocks}
                                         noOptionsMessage={val => val.inputValue ? 'No matches' : 'Start typing'}
                                         styles={{
                                             control: styles => ({
                                                 ...styles,
                                                 height: '100%',
                                                 minHeight: 45,
                                                 boxShadow: '0 0 4px 0 rgba(0,0,0,0.13)'
                                             }),
                                             dropdownIndicator: styles => ({
                                                 display: 'none'
                                             }),
                                             indicatorSeparator: styles => ({
                                                 display: 'none'
                                             }),
                                             clearIndicator: styles => ({
                                                 ...styles,
                                                 padding: 16,
                                             }),
                                             multiValue: styles => ({
                                                 ...styles,
                                                 backgroundColor: '#FF8A00',
                                                 color: '#fff'
                                             }),
                                             multiValueLabel: styles => ({
                                                 ...styles,
                                                 color: '#fff',
                                                 minWidth: 25,
                                                 textAlign: 'center',
                                                 paddingRight: 5
                                             }),
                                             multiValueRemove: styles => ({
                                                 ...styles,
                                                 borderLeft: '0.5px solid rgba(0,0,0,0.11)'
                                             })
                                         }}
                                    />}
                                </div>
                            </div>
                            {id ?
                            <div>
                            {!service.includes('Stream') && !service.includes('Data Sets') ? <div>
                                <div className="alerts-middle-container">
                                    <h3 className="container-title">Select the alert sources</h3>
                                    { isTweets &&
                                    <div className="checkbox-block alerts-block-without-body">
                                    <FormGroup controlId="is_all_twtr">
                                        <div className="alerts-block-header">
                                            <div className="alerts-block-header-checkbox">
                                                <h3>Tweets</h3>
                                                <div className="via-checkbox-block">
                                                    <Checkbox
                                                        name="is_all_twtr_email"
                                                        checked={is_all_twtr_email}
                                                        onChange={handleChecked}
                                                        inline="true"></Checkbox>
                                                    {/* <Checkbox
                                                        name="is_all_twtr_phone"
                                                        checked={is_all_twtr_phone}
                                                        onChange={handleChecked}
                                                        disabled={smsDisabled}
                                                        tooltip={smsTooltip}
                                                        inline="true">SMS</Checkbox> */}
                                                </div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                    </div>
                                    }
                                    { isCrypto &&
                                        <div className="checkbox-block">
                                        <FormGroup controlId="CR">
                                            <div className="alerts-block-header">
                                                <div className="alerts-block-header-checkbox">
                                                    <h3>Crypto</h3>
                                                    <div className="via-checkbox-block">
                                                        <Checkbox
                                                            name="is_all_cr_email"
                                                            checked={is_all_cr_email}
                                                            onChange={handleChecked}
                                                            inline="true"></Checkbox>
                                                        {/* <Checkbox
                                                            name="is_all_cr_phone"
                                                            checked={is_all_cr_phone}
                                                            onChange={handleChecked}
                                                            disabled={smsDisabled}
                                                            tooltip={smsTooltip}
                                                            inline="true">SMS</Checkbox> */}
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="alerts-block-body">
                                                <CheckboxItem
                                                    attr={{ is_cr_cd_email }}
                                                    label="Coindesk"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    attr={{ is_cr_ct_email }}
                                                    label="Cointelegraph"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    attr={{ is_cr_nb_email }}
                                                    label="Newsbtc"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    attr={{ is_cr_bc_email }}
                                                    label="Bitcoinist"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                            </div>
                                        </FormGroup>
                                        </div>}
                                    {!isEmptyOptions &&
                                        <div className="checkbox-block">
                                        <FormGroup controlId="SEC">
                                            <div className="alerts-block-header">
                                                <div className="alerts-block-header-checkbox">
                                                    <h3>SEC</h3>
                                                    <div className="via-checkbox-block">
                                                        <Checkbox
                                                            name="is_all_sec_email"
                                                            checked={is_all_sec_email}
                                                            onChange={handleChecked}
                                                            inline="true"></Checkbox>
                                                        {/* <Checkbox
                                                            name="is_all_sec_phone"
                                                            checked={is_all_sec_phone}
                                                            onChange={handleChecked}
                                                            disabled={smsDisabled}
                                                            tooltip={smsTooltip}
                                                            inline="true">SMS</Checkbox> */}
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="alerts-block-body">
                                                <CheckboxItem
                                                    attr={{ is_sec_10_email }}
                                                    label="10K/10Q/2OF"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    attr={{ is_sec_4_email }}
                                                    label="Form3,4,5"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    attr={{ is_sec_8_email }}
                                                    label="8K/6K"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    className='checkbox-premium-label'
                                                    attr={{ is_sec_13_email }}
                                                    label="13D/13G/13F"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    infoNode={
                                                        <span className="item-warn">
                                                                <i />Premium Only 
                                                            </span>
                                                    }
                                                    onChange={handleChecked} />
                                                <CheckboxItem
                                                    attr={{ is_sec_ot_email }}
                                                    label="All other filings"
                                                    phoneDisabled={smsDisabled}
                                                    phoneTooltip={smsTooltip}
                                                    onChange={handleChecked} />
                                            </div>
                                        </FormGroup>

                                            <FormGroup controlId="seekingAlpha">
                                                <div className="alerts-block-header">
                                                    <div className="alerts-block-header-checkbox">
                                                        <h3>SeekingAlpha</h3>
                                                        <div className="via-checkbox-block">
                                                            <Checkbox
                                                                name="is_all_sa_email"
                                                                checked={is_all_sa_email}
                                                                onChange={handleChecked}
                                                                inline="true"></Checkbox>
                                                            {/* <Checkbox
                                                                name="is_all_sa_phone"
                                                                checked={is_all_sa_phone}
                                                                onChange={handleChecked}
                                                                disabled={smsDisabled}
                                                                tooltip={smsTooltip}
                                                                inline="true">SMS</Checkbox> */}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                                <div className="alerts-block-body">
                                                    <CheckboxItem
                                                        attr={{ is_sa_tr_email }}
                                                        label="Transcripts"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                    <CheckboxItem
                                                        attr={{ is_sa_ls_email }}
                                                        label="Long articles"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                    <CheckboxItem
                                                        attr={{ is_sa_cr_email }}
                                                        label="Current news"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                </div>
                                            </FormGroup>

                                            <FormGroup controlId="prReleases">
                                                <div className="alerts-block-header">
                                                    <div className="alerts-block-header-checkbox">
                                                        <h3>PR Releases</h3>
                                                        <div className="via-checkbox-block">
                                                            <Checkbox
                                                                name="is_all_pr_email"
                                                                checked={is_all_pr_email}
                                                                onChange={handleChecked}
                                                                inline="true"></Checkbox>
                                                            {/* <Checkbox
                                                                name="is_all_pr_phone"
                                                                checked={is_all_pr_phone}
                                                                onChange={handleChecked}
                                                                disabled={smsDisabled}
                                                                tooltip={smsTooltip}
                                                                inline="true">SMS</Checkbox> */}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                                <div className="alerts-block-body">
                                                    <CheckboxItem
                                                        attr={{ is_pr_bw_email }}
                                                        label="Business Wire"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                    <CheckboxItem
                                                        attr={{ is_pr_glob_email }}
                                                        label="Globe Newswire"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                    <CheckboxItem
                                                        attr={{ is_pr_nw_email }}
                                                        label="PR Newswire"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                    <CheckboxItem
                                                        attr={{ is_pr_aw_email }}
                                                        label="Access Wire"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        onChange={handleChecked} />
                                                    <CheckboxItem
                                                        className='checkbox-premium-label'
                                                        attr={{ is_pr_earn_email }}
                                                        label="Breaking Earnings"
                                                        phoneDisabled={smsDisabled}
                                                        phoneTooltip={smsTooltip}
                                                        infoNode={
                                                            <span className="item-warn">
                                                                 <i />Premium Only 
                                                             </span>
                                                        }
                                                        onChange={handleChecked} />
                                                </div>
                                            </FormGroup>
                                    </div>
                                    }
                                    { !isEmptyOptions &&
                                    <div className="checkbox-block alerts-block-without-body">
                                        <FormGroup controlId="upgrades">
                                            <div className="alerts-block-header">
                                                <div className="alerts-block-header-checkbox">
                                                    <h3 className="UD-title">Upgrades/ Downgrades</h3>
                                                    <div className="via-checkbox-block">
                                                        <Checkbox
                                                            name="is_all_rt_email"
                                                            checked={is_all_rt_email}
                                                            onChange={handleChecked}
                                                            inline="true"></Checkbox>
                                                        {/* <Checkbox
                                                            name="is_all_rt_phone"
                                                            checked={is_all_rt_phone}
                                                            onChange={handleChecked}
                                                            disabled={smsDisabled}
                                                            tooltip={smsTooltip}
                                                            inline="true">SMS</Checkbox> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </div>}
                                </div>
                                <div className="grey-block">
                                    <div className="alerts-button-container">
                                        <UnselectAlertsButton
                                            name="isResetAllSubscriptions"
                                            checked={isResetAllSubscriptions}
                                            onChange={handleStopAllSubscriptions}
                                        />
                                        <LoaderButton
                                            block
                                            disabled={!this.validateForm()}
                                            type="submit"
                                            className="alerts-submit-button"
                                            isLoading={isLoading || !id}
                                            text={
                                                ((user.plan.type === 'free' && !user.plan.nextPlan.type) || user.plan.nextPlan.type === 'free') ? `Update Alerts (Free${additionalTextAlertsButton})` :
                                                (user.plan.type !== 'free' || user.plan.nextPlan.type !== 'free') ? `Update Alerts ($${payAmount} a month)` :
                                                    isCreateAlert && !isResetAllSubscriptions ? "Create" : "Update"
                                            }
                                            loadingText={
                                                isCreateAlert && !isResetAllSubscriptions
                                                    ? "Creating..." : "Updating..."
                                            }
                                        />
                                    </div>
                                    {user.plan.type !== 'free' && <span className="item-warn item-warn-big" style={{ textAlign: 'center'}}>
                                             <i />If you want to switch to free, click at the top right corner "Switch to Free"
                                         </span>}
                                 </div>
                            </div> : 
                            <div>
                                
                                <div className="alerts-middle-container">
                                            
                                    
                                {/* <div className="alerts-title-block">
                                    <h3 className="container-title">Select the stream sources</h3>
                                    <Checkbox
                                        name={service.includes('Streaming') ? 'ALL:P_STREAM': 'ALL:B_STREAM'}
                                        checked={streams[service.includes('Streaming') ? 'ALL:P_STREAM': 'ALL:B_STREAM'] || false}
                                        onChange={this.handleCheckedStreams}
                                        inline="true"></Checkbox>
                                        </div>
                                    <div className="checkbox-block">
                                    {(service.includes('Streaming') ? P_STREAM_SCHEME : B_STREAM_SCHEME).map(scheme => {
                                            return (<FormGroup controlId={scheme.name} key={scheme.name}>
                                                <div className="alerts-block-header">
                                                    <div className="alerts-block-header-checkbox">
                                                        <h3>{scheme.label}</h3>
                                                        <div className="via-checkbox-block">
                                                            <Checkbox
                                                                name={scheme.name}
                                                                checked={streams[scheme.name] || false}
                                                                onChange={this.handleCheckedStreams}
                                                                inline="true"></Checkbox>
                                                        </div>
                                                    </div>
                                                    { scheme.included && <hr /> }
                                                </div>
                                                {scheme.included && <div className="alerts-block-body">
                                                    {scheme.included.map(schemeIncluded => 
                                                    <StreamCheckBoxItem
                                                        key={schemeIncluded.name}
                                                        name={schemeIncluded.name}
                                                        value={streams[schemeIncluded.name] || false}
                                                        label={schemeIncluded.label}
                                                        onlyEmail
                                                        onChange={this.handleCheckedStreams}
                                                        disabled={schemeIncluded.disabled} />
                                                        )
                                                    }
                                                </div>}
                                            </FormGroup>)
                                    })}
                                    </div> */}
                                </div>
                                
                                {/* {service.includes('Streaming') && <div className="grey-block">
                                    <h3 className="container-title">
                                         Select impactful events from form 8-K
                                    </h3>
                                    <FormGroup className="items-container">
                                            <div className="alerts-block-header">
                                                <div className="alerts-block-header-checkbox">
                                                    <h3>8-K Items</h3>
                                                    <div className="via-checkbox-block">
                                                        <Checkbox
                                                            name='ALL:ITEMS:P_STREAM'
                                                            checked={streams['ALL:ITEMS:P_STREAM'] || false}
                                                            onChange={this.handleCheckedStreams}
                                                            inline="true"></Checkbox>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="alerts-block-body">
                                            {Object.keys(ITEMS_SCHEME).map(item => (
                                                <StreamCheckBoxItem
                                                    key={item}
                                                    name={ITEMS_SCHEME[item] + ':P_STREAM'}
                                                    value={streams[ITEMS_SCHEME[item] + ':P_STREAM'] || false}
                                                    label={item}
                                                    onlyEmail
                                                    onChange={this.handleCheckedStreams}
                                                    disabled={false} />
                                                )
                                            )}
                                            </div>
                                     </FormGroup>
                                 </div>} */}
                                    {/* <div className="alerts-button-container">
                                    <LoaderButton
                                            block
                                            type="submit"
                                            className="alerts-submit-button"
                                            isLoading={isLoading}
                                            text={`Update ${additionalButtonTextStreamAlerts}`}
                                            loadingText="Updating..."
                                        />
                                        </div> */}

                            </div>}
                            </div> 

                                : <div className="preloadContent">
                                    <div className="preloadContent"></div>
                                    <LoaderButton
                                        block
                                        disabled={true}
                                        type="submit"
                                        isLoading={true}
                                        text="Loading data..."
                                        loadingText="Loading data..."
                                    />
                                </div>}

                        </form>
                </div>
        );
    }
}