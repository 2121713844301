/**
 * Created by alexanderkulyk on 3/26/19.
 */

import initialState from './state';

import {
    SET_QUESTION_POPUP,
    SHOW_QUESTION_POPUP,
    HIDE_QUESTION_POPUP_REDUCER
} from './actions';

import {
    setQuestionPopup,
    showQuestionPopup,
    hideQuestionPopupReducer
} from './reducers';


export default function reducer(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case SET_QUESTION_POPUP:
            return setQuestionPopup(state, payload);
        case SHOW_QUESTION_POPUP:
            return showQuestionPopup(state, payload);
        case HIDE_QUESTION_POPUP_REDUCER:
            return hideQuestionPopupReducer(state);
        default:
            return state;
    }
}