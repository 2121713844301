/**
 * Created by alexanderkulyk on 3/21/19.
 */
import React, { Component } from 'react';

import Row from 'react-bootstrap/lib/Row';

import NavBar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import IdleTimer from 'react-idle-timer'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Tooltip from '../Tooltip';
import { plans } from '../../utils/alerts';

import './Header.css';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
    }

    updateSubject = e => {
        const { setUserProp } = this.props;
        setUserProp('subject', 'low latency feed')
    }

    render() {
        const { isAuthenticated } = this.props.user;
        const { signTarget } = this.props.header;
        const { cognitoAuthURL, handleLogout, handleSignSwitch, shortLinkTo } = this.props;

        return(
            <Row>
                    <NavBar className="header" collapseOnSelect>
                        { isAuthenticated && <IdleTimer
                            ref={ref => { this.idleTimer = ref }}
                            element={document}
                            onIdle={handleLogout}
                            timeout={1000 * 60 * 30} />}
                        <NavBar.Header className={`nav-header ${shortLinkTo ? 'nav-fa-block' : ''}`}>
                            <NavBar.Brand>
                                <a href="/"><img alt="Flashalert logo" width={42} height={40} src="/images/logo.png" />
                                {shortLinkTo ? ((document.body.offsetWidth || document.documentElement.offsetWidth) > 850 && 'Flash Alert') : 'Flash Alert'}
                                </a>
                            </NavBar.Brand>
                            {shortLinkTo && (document.body.offsetWidth || document.documentElement.offsetWidth) < 850 && 
                            <Tooltip
                            customClass='shorter-tooltip'
                            title=''
                            message={<div className='header-tooltip-block'>
                                <p>You'll get alerts from SEC, PR releases, Seeking Alpha, Upgrades/Downgrades and more!</p>
                                <p>Basic service with {plans.free.symbolsAmount} symbols is free. Premium service is only $9.99 a month.</p>
                                <p>
                                    If you'd like to discuss our low latency feed, please <AnchorLink onClick={this.updateSubject} href='#contact-form'>email us</AnchorLink>
                                </p>
                                </div>}
                            spanText={<h2 className='header-text'>
                                FlashAlert - low-latency financial news service
                        </h2>}
                        />  }
                            <NavBar.Toggle />
                        </NavBar.Header>
                        <NavBar.Collapse>
                            <Nav className="nav-links">
                                <NavItem eventKey={1} title="Products" href="/products">Products</NavItem>
                                <NavItem eventKey={2} title="Use Cases" href="/use-cases">Use Cases</NavItem>
                                {/* <NavItem eventKey={3} title="Q&A" href="/questions-and-answers">Q&A</NavItem> */}
                                <NavItem eventKey={3} title="Contact">
                                    <AnchorLink href='#contact-form'>Contact</AnchorLink>
                                </NavItem>
                            </Nav>
                            {
                                !cognitoAuthURL &&
                            <Nav pullRight className="right-nav-container">
                                <Nav className="social-nav-container">
                                <NavItem
                                    // eventKey={4}
                                    // title="Twitter"
                                    // href="https://twitter.com/FlashAlert_me"
                                    // target="_blank"
                                    // className="twitter-nav"
                                >
                                    {/* <img width={32} height={26} src="/images/twitter.png" alt="thumbnail" /> */}
                                </NavItem>
                                <NavItem
                                    // eventKey={5}
                                    // title="StockTwits"
                                    // href="https://stocktwits.com/fla"
                                    // target="_blank"
                                    // className="stocktwits-nav"
                                    // src="/images/sms.png"
                                >
                                    {/* <img width={32} height={32} src="/images/stocktwits.png" alt="thumbnail" /> */}
                                </NavItem>
                                </Nav>
                                {!isAuthenticated && <Nav className="sign-nav-container">
                                
                                    <NavItem
                                        eventKey={6}
                                        title="Sign In"
                                        href="#signup-form"
                                        onClick={handleSignSwitch}
                                        className={signTarget === 'Sign In' && 'active-sign'}
                                    ><AnchorLink href='#signup-form' title="Sign In">Sign In</AnchorLink>
                                    </NavItem>
                                    
                                    <NavItem
                                        eventKey={7}
                                        title="Sign Up"
                                        href="#signup-form"
                                        onClick={handleSignSwitch}
                                        className={signTarget === 'Sign Up' && 'active-sign'}
                                    >
                                        <AnchorLink href='#signup-form' title="Sign Up">Sign up</AnchorLink>
                                        {/*Sign up*/}
                                    </NavItem>
                                </Nav>
                                }
                                {isAuthenticated && <Nav className="">
                                    <NavItem eventKey={1} title="Profile" href="/profile">Profile</NavItem>
                                    <NavItem eventKey={2} title="Alerts" href="/alerts">Alerts</NavItem>
                                    {
                                        !cognitoAuthURL &&
                                        <NavItem eventKey={3} title="Logout" onClick={handleLogout}>
                                            Log out
                                        </NavItem> }

                                </Nav>
                                }

                            </Nav>
                            }
                        </NavBar.Collapse>
                    </NavBar>
            </Row>
        );
    }
}