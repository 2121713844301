import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./Contact.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { emailCheckRe } from '../../../utils/user';
import { isFeatureActive } from '../../../utils/common';
import Input from '../../Form/Input';

import { contactUsBaseUrl } from '../../../config';

export default class Contact extends Component {

    constructor(props) {
        super(props);
        const { user } = props;
        const { email, given_name, subject } = user;

        this.state = {
            subject,
            name: given_name,
            email,
            message: ""
        };

        this.recaptchaRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async event => {
        event.preventDefault();
        const { name, email, message, subject } = this.state;
        const { showPopupMessage, setUserProp } = this.props;

        let finalSubject = `Contact Us: `;

        if (name) {
            finalSubject += `${name} `;
        }
        if (subject) {
            finalSubject += `${subject}`;
        }
        if (!name && !subject) {
            finalSubject += 'Message from FlashAlert user';
        }

        const data = {
            from: email,
            subject: finalSubject,
            message
        };
        try {
            if (isFeatureActive('CAPTCHA')) this.recaptchaRef.current.execute();
            await axios.post(contactUsBaseUrl + "/v1/contact-us", data)
                .then(res => {
                    this.setState({message: '', subject: ''});
                    showPopupMessage(
                        'Thank you for contacting FlashAlert. We will reply to your message as soon as we can.'
                    );
                    setUserProp('subject', '')
                })
                .catch((error) => {
                    console.log("Error: " + error);
                });
        } catch(e) {
            showPopupMessage(
                'Invalid captcha.'
            );
        }
    };

    validateEmail(){
        if (!this.state.email) {
            return null;
        }
        return this.state.email.match(emailCheckRe) ? "success" : "error";
    }

    validateMessage(){
        if (!this.state.message) {
            return null;
        }
        return (this.state.message.length > 0 && this.state.message.length <= 800) ? "success" : "error";
    }

    validateSubject() {
        const { subject } = this.state;
        if (!subject) {
            return null;
        }
        return (subject.length > 0 && subject.length <= 200) ? "success" : "error";
    }

    validateForm() {
        const { email, subject, message } = this.state;
        return email.match(emailCheckRe) && subject && message.length > 0;
    };

    handleChange(e) {
        if (e.target.name === 'message' && e.target.value.length > 800) return;
        this.setState({[e.target.name]: e.target.value });
    }


    onClick = e => {
        const { switchSignTarget } = this.props;
        switchSignTarget('Sign Up');
    }

    componentDidUpdate(prevProps, prevState) {
        const { setUserProp } = this.props;
        if (this.props.user.subject) {
            setUserProp('subject', '')
            this.setState({
                subject: this.props.user.subject
            });
        }
    }

    render() {
        const { user } = this.props;
        const { isAuthenticated } = user;
        const { name, email, subject, message } = this.state;
        return (
                <div className="contact-us-container">
                    <form
                        id="contact-form"
                        className={isAuthenticated ? "contact-form-without-text" : ""}
                        onSubmit={this.onSubmit}>
                        <div className="form-fa-two-input-block">
                            <Input
                                name="name"
                                label="First Name"
                                value={name}
                                placeholder="Enter your name"
                                onChange={this.handleChange}
                            />
                            <Input
                                name="email"
                                type="email"
                                label="Email*"
                                value={email}
                                placeholder="Enter email"
                                validationState={this.validateEmail()}
                                onChange={this.handleChange}
                            />
                        </div>
                        <Input
                            name="subject"
                            type="text"
                            label="Subject*"
                            value={subject}
                            placeholder="Enter subject"
                            onChange={this.handleChange}
                            validationState={this.validateSubject()}
                        />
                        <Input
                            name="message"
                            type="text"
                            label="Message*"
                            validationState={this.validateMessage()}
                            value={message}
                            placeholder="Enter message"
                            onChange={this.handleChange}
                            componentClass="textarea"
                            rows="7"
                            cols="80"
                        />
                        <div id="headerContact">
                            {isFeatureActive('CAPTCHA') &&
                                <ReCAPTCHA
                                    sitekey="6LeKuqAUAAAAANcJs_sZ6UJpKPZ8iASjJilZHPyZ"
                                    size="invisible"
                                    ref={this.recaptchaRef}/>
                            }
                        </div>
                        <Button
                            type="submit"
                            className="form-fa-submit"
                            disabled={!this.validateForm()}>
                            Contact Us
                        </Button>
                    </form>
                    {!isAuthenticated &&
                        <div className="footer-text">
                            <h3>
                                {/* Our basic service is free for first 30 days while our standard service is only $9.99 a month. */}
                                We source all data from original sources and deliver it to you with very low latency,
                                much faster than most paid services.
                            </h3>
                            {/* <h3>
                                We reserve a right to increase subscription prices as we provide service below our costs
                                but for early adopters, we will not start charging for features which used to be free, so <AnchorLink href='#signup-form' onClick={this.onClick}>
                                sign up</AnchorLink> today!
                            </h3> */}
                        </div>
                    }
                </div>
        );
    }

}