/**
 * Created by alexanderkulyk on 3/21/19.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearUser, switchSignTarget, clearUserId } from '../../store/actions';
import { userSelector } from '../../selectors/user';
import { headerSelector } from '../../selectors/header';
import { userLogout } from '../../utils/user';

import Header from './Header';

class HeaderContainer extends Component {
    handleLogout = async event => {
        const { history, clearUserId, clearUser } = this.props;
        await userLogout(history, clearUser, clearUserId );
    };

    handleSignSwitch = event => {
      event.preventDefault();
      const { history, switchSignTarget, resetShortLinking } = this.props;
        switchSignTarget(event.target.title);
      if (window.location.pathname !== '/' || resetShortLinking) {
          if (resetShortLinking) {
              resetShortLinking();
          }
          history.push("/");
      }
    };

    render() {
        return(
            <Header { ...this.props } handleLogout={this.handleLogout} handleSignSwitch={this.handleSignSwitch} />
        );
    }
}

const mapDispatchToProps = {
    clearUser,
    switchSignTarget,
    clearUserId
};

const mapStateToProps = state => ({ user: userSelector(state), header: headerSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);