/**
 * Created by alexanderkulyk on 3/11/19.
 */

import initialState from './state';

import {
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    CLEAR_USER,
    SET_USER_PROP,
    SET_USER_PROPS
} from './actions';

import {
    getUserSuccess,
    getUserFail,
    clearUser,
    setUserProp,
    setUserProps
} from './reducers';


export default function reducer(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case GET_USER_SUCCESS:
            return getUserSuccess(state, payload);
        case GET_USER_FAIL:
            return getUserFail(state);
        case CLEAR_USER:
            return clearUser(state);
        case SET_USER_PROP:
            return setUserProp(state, payload);
        case SET_USER_PROPS:
            return setUserProps(state, payload);
        default:
            return state;
    }
}