/**
 * Created by alexanderkulyk on 4/4/19.
 */

import { createSelector } from 'reselect';

const questionPopupStateSelector = state => state.questionPopup;

export const questionPopupSelector = createSelector(
    questionPopupStateSelector,
    questionPopup => questionPopup
);