/**
 * Created by alexanderkulyk on 3/27/19.
 */

import { createSelector } from 'reselect';

const headerStateSelector = state => state.header;

export const headerSelector = createSelector(
    headerStateSelector,
    header => header
);