/**
 * Created by alexanderkulyk on 3/11/19.
 */
import { all, fork } from 'redux-saga/effects';
import { watchGetSubscription, watchUpdateSubscriptions, watchgetSymbolsByTicker,
    watchHandleChecked, watchHandleChange, watchHandleStopAllSubscriptions, watchUpdateSubscriptionStream } from './modules/alerts/sagas';
import { watchGetUser, watchcancelStripeSubscription, watchHandleUserAttrs } from './modules/user/sagas';
import { watcHideQuestionPopup } from './modules/questionPopup/sagas';
import { watchStripePayment } from './modules/paymentPopup/sagas';


export default function* mainSaga(dispatch) {
    yield all([
        fork(watchGetSubscription),
        fork(watchUpdateSubscriptions),
        fork(watchgetSymbolsByTicker),
        fork(watchGetUser),
        fork(watchHandleChecked, dispatch),
        fork(watchHandleChange, dispatch),
        fork(watcHideQuestionPopup),
        fork(watchHandleStopAllSubscriptions),
        fork(watchStripePayment),
        fork(watchcancelStripeSubscription),
        fork(watchHandleUserAttrs),
        fork(watchUpdateSubscriptionStream)
    ]);
}

