/**
 * Created by alexanderkulyk on 6/6/19.
 */
import React, {Component} from 'react';
import {
    // CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import Input from '../Form/Input';
import { emailCheckRe } from '../../utils/user';
import LoaderButton from '../../components/LoaderButton';

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Open Sans, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

class _CardForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: props.errorMessage,
            name: props.user.given_name + ' ' + props.user.family_name,
            email: props.user.email,
            phone_number: props.user.phone_number,
            isLoading: props.isLoading,
            zip: props.user.address,
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.errorMessage !== this.state.errorMessage) {
            this.setState({ errorMessage: this.props.errorMessage });
        }

        if (this.props.isLoading !== this.state.isLoading) {
            this.setState({
                isLoading: this.props.isLoading
            });
        }

        if (this.props.user.email !== this.state.email) {
            this.setState({
                name: this.props.user.given_name + ' ' + this.props.user.family_name,
                email: this.props.user.email,
                phone_number: this.props.user.phone_number,
                zip: this.props.user.address
            })
        }
    }

    handleChangeCardElement = state => {
        const { error, elementType, complete } = state;
        if (error) {
            this.setState({ errorMessage: error.message });
        }

        this.setState(({
            [elementType]: complete
        }));
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value });
    }

    validateEmail = () => {
        if (!this.state.email) {
            return null;
        }
        return this.state.email.match(emailCheckRe) ? "success" : "error";
    }

    handleSubmit = (evt) => {
        this.setState({
            isLoading: true
        });
        evt.preventDefault();
        if (this.props.stripe) {
            setTimeout(() => {
                this.props.handleStateChange('isLoading', false);
            }, 6000);
            this.props.stripe.createToken().then(this.props.handleResult).catch(e => console.log('e', e));
        }
    };

    validateForm = () => {
        const { name, phone_number, cardNumber, cardExpiry, cardCvc, zip } = this.state;
        const { confirm } = this.props;
        const result = this.validateEmail() && name && phone_number && cardNumber && cardExpiry && cardCvc && zip;
        if (result && !confirm) {
            const paymentPopupElement = document.getElementById('payment-popup');
            paymentPopupElement.scrollTop = 0;
            const confirmCheckboxElement = paymentPopupElement.querySelector('input[type=checkbox]');
            confirmCheckboxElement.focus();
        }

        return result && confirm;
    }


    render() {
        const { cost } = this.props;
        const { name, email, phone_number, isLoading, zip } = this.state;
        return (
            <div className="stripe-card">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <label>
                        Card details*
                        <Input
                            name="name"
                            label="Name*"
                            value={name}
                            placeholder="Enter your name"
                            onChange={this.handleChange}
                        />
                        <div className="form-fa-two-input-block">
                            <Input
                                name="email"
                                type="email"
                                label="Email*"
                                value={email}
                                placeholder="Enter email"
                                validationState={this.validateEmail()}
                                onChange={this.handleChange}
                            />
                            <Input
                                name="phone_number"
                                type="tel"
                                label="Phone*"
                                value={phone_number}
                                onChange={this.handleChange}
                                bsSize="large"
                            />
                        </div>
                        <div className="split-form">
                            <label>
                                <label className="control-label">
                                    Card number*
                                </label>
                                <CardNumberElement
                                    ref={node => this.CardNumberElement = node}
                                    {...createOptions()}
                                    onChange={this.handleChangeCardElement}
                                />
                            </label>
                        </div>
                        <div className="split-form">
                            <label>
                                <label className="control-label">
                                    {window.innerWidth < 500 ? 'Exp. date*' : 'Expiration date*'}
                                </label>
                                <CardExpiryElement
                                    ref={node => this.CardExpiryElement = node}
                                    {...createOptions()}
                                    onChange={this.handleChangeCardElement}
                                />
                            </label>
                            <label>
                                <label className="control-label">
                                    CVC*
                                </label>
                                <CardCVCElement
                                    ref={node => this.CardCVCElement = node}
                                    {...createOptions()}
                                    onChange={this.handleChangeCardElement}
                                />
                            </label>
                            <Input
                                name="zip"
                                type="zip"
                                label="Postal code*"
                                value={zip}
                                onChange={this.handleChange}
                                placeholder="94115"
                            />
                        </div>
                    </label>
                    <div className="error" role="alert">
                        {this.state.errorMessage}
                    </div>
                    <LoaderButton
                        block
                        disabled={!this.validateForm()}
                        type="submit"
                        className="alerts-submit-button"
                        isLoading={isLoading}
                        text={`Pay $${cost} monthly`}
                        loadingText="Processing..."
                    />
                </form>
            </div>
        );
    }
}

const CardForm = injectStripe(_CardForm);

export default class StripeCard extends Component {
    render() {
        return (
            <StripeProvider apiKey={this.props.stripePublicKey}>
                <Elements>
                    <CardForm
                        cost={this.props.cost}
                        handleResult={this.props.handleResult}
                        errorMessage={this.props.errorMessage}
                        confirm={this.props.confirm}
                        user={this.props.user}
                        isLoading={this.props.isLoading}
                        handleStateChange={this.props.handleStateChange}
                    />
                </Elements>
            </StripeProvider>
        );
    }
}