/**
 * Created by alexanderkulyk on 3/11/19.
 */
import { createAction } from 'redux-actions';

import {
    debouncedAction
} from '../../../utils/common';

export const GET_SUBSCRIPTIONS = 'alerts:get:subscriptions';
export const GET_SYMBOLS_BY_TICKER = 'alerts:get:symbols-by-ticker';

export const GET_SUBSCRIPTIONS_SUCCESS = 'alerts:get:subscriptions:success';
// export const GET_SYMBOLS_SUCCESS = 'alerts:get:symbols:success';

export const HANDLE_CHECKED = 'alerts:handle-checked';
export const HANDLE_CHECKED_REDUCER = 'alerts:handle-checked:reducer';
export const SAVE_EVENT = 'alerts:save-event';
export const HANDLE_STOP_ALL_SUBSCRIPTIONS = 'alerts:handle-stop-all-subscriptions';
export const HANDLE_STOP_ALL_SUBSCRIPTIONS_REDUCER = 'alerts:handle-stop-all-subscriptions:reducer';
export const HANDLE_CHANGE = 'alerts:handle-change';
export const HANDLE_CHANGE_REDUCER = 'alerts:handle-change:reducer';
export const HANDLE_CHANGE_ALERTS_STATE = 'alerts:handle-change:alerts-state';
export const HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT = 'alerts:handle-change:alerts-state:by-object';

export const HANDLE_UPDATE = 'alerts:handle-update';
export const HANDLE_UPDATE_SUCCESS = 'alerts:handle-update:success';

export const SET_ALERTS_LOADING = 'alerts:set:loading';

export const CLEAR_USER_ID = 'alerts:clear:user-id';

export const UPDATE_STREAM_STATE = 'alerts:stream:update:state';

export const HANDLE_UPDATE_STREAM = 'alerts:stream:update';

export const ALERTS_STATE_UPDATE = 'alerts:state:update'

export const handleChangeAlertsState = createAction(HANDLE_CHANGE_ALERTS_STATE, (name, value) => ({ name, value }));
export const handleChangeAlertsStateByObject = createAction(HANDLE_CHANGE_ALERTS_STATE_BY_OBJECT, payload => ({ ...payload }));

export const setAlertsLoading = createAction(SET_ALERTS_LOADING, value => value);

export const getSubscriptions = createAction(GET_SUBSCRIPTIONS);

export const getSymbolsByTicker = debouncedAction(
    GET_SYMBOLS_BY_TICKER, 'getSymbolsByTicker', (inputValue, callback) => ({ inputValue, callback })
);

export const saveEvent = createAction(SAVE_EVENT, event => event);

export const handleChecked = createAction(HANDLE_CHECKED, event => event);
export const handleCheckedReducer = createAction(HANDLE_CHECKED_REDUCER, event => event);
export const handleStopAllSubscriptions = createAction(HANDLE_STOP_ALL_SUBSCRIPTIONS, event => event);
export const handleChange = createAction(HANDLE_CHANGE, value => value);
export const handleChangeReducer = createAction(HANDLE_CHANGE_REDUCER, event => event);
export const handleUpdate = createAction(HANDLE_UPDATE);

export const clearUserId = createAction(CLEAR_USER_ID);

export const updateStreamsState = createAction(UPDATE_STREAM_STATE, (name, value) => ({ name, value }));
export const handleUpdateStream = createAction(HANDLE_UPDATE_STREAM, service => service);

export const handleAlertsStateUpdate = createAction(ALERTS_STATE_UPDATE, obj => obj);