/**
 * Created by alexanderkulyk on 3/26/19.
 */

export const setPopupMessage = (state, { status, message='' }) => ({ ...state, status, message });

export const showPopupMessage = (state, message) => setPopupMessage(state, { status: true, message });

export const hidePopupMessage = state => setPopupMessage(state, { status: false });

export const debouncedHidePopupMessage = state => setPopupMessage(state, { status: false });