import React from 'react';
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import SecureRoute from "./components/SecureRoute";
import Home from "./containers/Home/HomeContainer"; // Public View
import Products from "./containers/Products"; // Public View
import Profile from "./containers/Profile/ProfileContainer";  // Profile View
import Alerts from "./containers/Alerts"; // Profile View
import News from "./containers/News"; // Public View
import Terms from "./containers/Terms"; // Public View
import Privacy from "./containers/Privacy"; // Public View
import About from "./containers/About"; // Public View
import Accessibility from "./containers/Accessibility";
import NotFound from "./containers/NotFound"; // Error Handling
import UseCases from "./containers/UseCases";
import QA from "./containers/QA"; // Q&A

export default ({ childProps }) =>
    <Switch>
        <SecureRoute path="/profile" exact component={Profile} props={childProps} />
        <SecureRoute path="/alerts" exact component={Alerts} props={childProps} />
        <SecureRoute path="/data_sets" exact component={Alerts} props={childProps} />
        <SecureRoute path="/streaming_news" exact component={Alerts} props={childProps} />
        <AppliedRoute path="/" exact component={Home} props={childProps} />
        <AppliedRoute path="/products" exact component={Products} props={childProps} />
        <AppliedRoute path="/use-cases" exact component={UseCases} props={childProps} />
        <AppliedRoute path="/questions-and-answers" exact component={QA} props={childProps} />
        <AppliedRoute path="/news" exact component={News} props={childProps} />
        <AppliedRoute path="/terms" exact component={Terms} props={childProps} />
        <AppliedRoute path="/privacy" exact component={Privacy} props={childProps} />
        <AppliedRoute path="/accessibility" exact component={Accessibility} props={childProps} />
        <AppliedRoute path="/about" exact component={About} props={childProps} />
        <AppliedRoute component={NotFound} />
    </Switch>;