/**
 * Created by alexanderkulyk on 3/16/19.
 */

import { put, call, takeEvery } from 'redux-saga/effects';

import {
    HANDLE_STRIPE_PAYMENT, HIDE_PAYMENT_POPUP
} from './actions';

import {
    HANDLE_UPDATE
} from '../alerts/actions';

import {
    SHOW_POPUP
} from '../popup/actions';

import {
    SET_USER_PROPS
} from '../user/actions';

import {
    requestToStripe
} from '../../../utils/api';

export function* watchStripePayment() {
    yield takeEvery(HANDLE_STRIPE_PAYMENT, handleStripePayment);
}

export function* handleStripePayment(action) {
    const { payload } = action;
    try {
        const stripeResponse = yield call(requestToStripe, payload);

        if (stripeResponse.status === 200) {
            if (!payload.type) {
                yield put({
                    type: SET_USER_PROPS,
                    payload: {
                        plan: {type: payload.paymentPlan, additionalSymbols: payload.additionalSymbols, nextPlan: {type: '', additionalSymbols: 0}},
                        isGoingPay: false,
                        paymentType: payload.paymentPlan
                    }
                });
            }
            

            yield put({
                type: HANDLE_UPDATE
            });

            yield put({
                type: HIDE_PAYMENT_POPUP
            });
        }
    } catch (e) {
        console.log('test e', e);
        yield put ({
            type: SHOW_POPUP,
            payload: 'Your credit card could not be processed!'
        });
    }

}
