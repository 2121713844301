/**
 * Created by alexanderkulyk on 3/21/19.
 */

import { Auth } from "aws-amplify";

export function fetchToken() {
    return Auth.currentSession();
}

export function fetchUser() {
    return Auth.currentAuthenticatedUser();
}

export const emailCheckRe = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const userLogout = async (history, clearUser, clearUserId) => {
    await Auth.signOut();
    clearUser();
    clearUserId();
    localStorage.removeItem('user');
    history.push("/");
};