/**
 * Created by alexanderkulyk on 3/26/19.
 */

import { createSelector } from 'reselect';

const popupStateSelector = state => state.popup;

export const popupSelector = createSelector(
    popupStateSelector,
    popup => popup
);