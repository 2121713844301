/**
 * Created by alexanderkulyk on 3/11/19.
 */

export const emptyUser = {
    token: '',
    email: '',
    given_name: '',
    family_name: '',
    address: '',
    phone_number: '',
    isAuthenticated: false,
    isGoingPay: false,
    isGoingFree: false,
    basicQueueType: '',
    premiumQueueType: '',
    freePeriod: false,
    premiumFreeTrialDaysLeft: '',
    premiumFreeTrialStarted: '',
    plan: {
        type: 'free',
        additionalSymbols: 0,
        nextPlan: {
            type: '',
            additionalSymbols: 0
        }
    },
    isSMSLimit: true,
    isSymbolLimit: true,
    P_STREAM: false,
    B_STREAM: false,
    subject: ''
};

export default {
    ...emptyUser,
    isLoaded: false
}