/**
 * Created by alexanderkulyk on 3/26/19.
 */

import initialState from './state';

import {
    SET_POPUP_MESSAGE,
    SHOW_POPUP,
    HIDE_POPUP,
    DEBOUNCED_HIDE_POPUP
} from './actions';

import {
    setPopupMessage,
    showPopupMessage,
    hidePopupMessage,
    debouncedHidePopupMessage
} from './reducers';


export default function reducer(state = initialState, action) {
    const { payload } = action;
    switch (action.type) {
        case SET_POPUP_MESSAGE:
            return setPopupMessage(state, payload);
        case SHOW_POPUP:
            return showPopupMessage(state, payload);
        case HIDE_POPUP:
            return hidePopupMessage(state);
        case DEBOUNCED_HIDE_POPUP:
            return debouncedHidePopupMessage(state);
        default:
            return state;
    }
}