/**
 * Created by alexanderkulyk on 6/20/19.
 */
import React, { Component } from 'react';
import { getCookie, setCookie } from '../../utils/common';
import './CookieForm.css';

export default class CookieForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closed: false
        }
    }

    onClose = e => {
        setCookie('cookie_confirm', true, 5500); // > 15 years
        this.setState({
            closed: true
        });
    }

    render() {
        const isCookieConfirmed = getCookie('cookie_confirm') || this.state.closed;
        return (
            <div>
            { !isCookieConfirmed &&
                <div className='home-container with-welcome-container cookie-form-container'>
                    <div className="popup-close-button" onClick={this.onClose}>x</div>
                    <div className='cookie-container'>
                        <div className='cookie-block'>
                            <h4>
                                By using this website you agree to use of cookies. Read FlashAlerts's <a target="_blank" href="https://flashalert.me/privacy">Privacy Policy</a>
                            </h4>
                        </div>
                    </div>
                </div>
            }
            </div>
        )
    }
}