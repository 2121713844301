/**
 * Created by alexanderkulyk on 3/16/19.
 */

import { put, select, takeEvery } from 'redux-saga/effects';

import {
    HIDE_QUESTION_POPUP, HIDE_QUESTION_POPUP_REDUCER
} from './actions';

import {
    questionPopupSelector
} from '../../../selectors/questionPopup';

export function* watcHideQuestionPopup() {
    yield takeEvery(HIDE_QUESTION_POPUP, hideQuestionPopup);
}

export function* hideQuestionPopup(action) {
    const answer = action.payload;
    const { callbacks } = yield select(questionPopupSelector);
    yield callbacks[answer]();
    yield put({
        type: HIDE_QUESTION_POPUP_REDUCER
    });

}
