/**
 * Created by alexanderkulyk on 3/21/19.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser, showPopupMessage, switchSignTarget } from '../../../store/actions';
import { userSelector } from '../../../selectors/user';
import Login from './Login';

class LoginContainer extends Component {
    componentDidUpdate(prevProps) {
        const { user, history } = this.props;
        if (user.isAuthenticated) {
            history.push("/alerts")
        }
    }

    switchSignTargetToResetPassword = e => {
        e.preventDefault();
        const { switchSignTarget } = this.props;
        switchSignTarget('Reset Password');
    };

    render() {
        return (
            <Login { ...this.props }  switchSignTargetToResetPassword={this.switchSignTargetToResetPassword} />
        )
    }
}

const mapDispatchToProps = {
    getUser,
    showPopupMessage,
    switchSignTarget
};

const mapStateToProps = state => ({ user: userSelector(state) });

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);