/**
 * Created by alexanderkulyk on 3/26/19.
 */

export const pureQuestionPopupState = {
    status: false,
    message: '',
    yesLabel: 'Yes',
    noLabel: 'Cancel',
    callbacks: {
        'yes': () => {},
        'no': () => {}
    }
};

export default {
    ...pureQuestionPopupState,
    callbacks: { ...pureQuestionPopupState.callbacks }
}